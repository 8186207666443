import React, { useEffect } from 'react';
export default function ChiusuraIstituto(props) {
    return (
        <div style={{ padding: window.innerWidth > 700 ? 20 : '8px 0', height: '100%' }}>
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: 30,
                    boxShadow: '3px 1px 13px #0000005c',
                    height: '100%',
                    padding: window.innerWidth > 700 ? 20 : 12
                }}
            >
                <h3 style={{ color: 'red', fontSize: window.innerWidth < 700 && 19 }}>
                    CHIUSURE ATTIVITA’ ANNO SCOLASTICO 2023/2024{' '}
                </h3>
                <p>
                    Di seguito sono indicati i giorni in cui il centro rimarrà chiuso, eventuali e ulteriori chiusure
                    saranno comunicate dal Centro stesso.
                </p>
                <b>N.B. il 28 29 dicembre e 3 4 5 gennaio è possibile fare lezione solo su prenotazione.</b>
                <ul style={{ marginTop: 12 }} className={'dlg-spacing-12'}>
                    <li>1 Novembre 2023</li>
                    <li>8 – 9 Dicembre 2023</li>
                    <li>23 – 24 – 25 – 26 – 27 Dicembre 2023</li>
                    <li>30 – 31 Dicembre 2023</li>
                    <li>1 – 2 Gennaio 2024</li>
                    <li>6 – 7 Gennaio 2024</li>
                    <li>29 – 30 – Marzo 2024</li>
                    <li>1 Aprile 2024</li>
                    <li>25 Aprile 2024</li>
                    <li>1 Maggio 2024</li>
                    <li>2 Giugno 2024</li>
                </ul>
                <b>
                    Per qualsiasi dubbio si ricorda di controllare sempre la programmazione settimanale tramite il
                    portale “Eureka”.
                </b>
            </div>
        </div>
    );
}
