import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { CFormRange } from '@coreui/react';
const isSafari = navigator.userAgent.indexOf('Safari');
export default function CalendarZoom({ state, setState, handleChange }) {
    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion

    if (window.innerWidth < 769) return <div />;
    return (
        <div
            style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                gap: 16,
                minWidth: 'max-content',
                width: 'auto'
            }}
            onClick={ev => ev.stopPropagation()}
        >
            <i
                className={'fa fa-search-minus md-visible'}
                onClick={event => {
                    handleChange(event, 100);
                }}
            />
            <Button
                style={{ minWidth: 'max-content', color: 'white' }}
                className={'md-hidden'}
                onClick={event => {
                    handleChange(event, 100);
                }}
            >
                Reset zoom
            </Button>
            <CFormRange
                steps={3}
                value={state.zoom ?? 100}
                min={90}
                max={600}
                className="md-hidden"
                onChange={handleChange}
            />
            <span>{state.zoom || 100}%</span>
            <i
                className={'fa fa-search-plus'}
                onClick={event => {
                    let newZoom = state.zoom + 10;
                    if (newZoom > 600) {
                        newZoom = 600;
                    }
                    handleChange(event, newZoom);
                }}
            />
        </div>
    );
}
