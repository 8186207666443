import * as XLSX from 'xlsx';
import moment from 'moment';
import axios from 'axios';
import { addAuthToken, apiconfig } from '../shared/apiconfig';
import { pathDownload } from '../shared/utility';
import { downloadPDF, viewdData } from '../common/services';
export function diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
}
export const downloadExcel = (data, name, date) => {
    const workbook = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet([]);
    var ws2 = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [['Creato il ' + moment(new Date()).format('DD/MM/YYYY HH:mm')]], { origin: 0 });
    XLSX.utils.sheet_add_aoa(ws2, [['Creato il ' + moment(new Date()).format('DD/MM/YYYY HH:mm')]], { origin: 0 });
    XLSX.utils.sheet_add_aoa(ws, [['Periodo: ' + date]], { origin: 1 });
    XLSX.utils.sheet_add_aoa(ws2, [['Periodo: ' + date]], { origin: 1 });
    XLSX.utils.sheet_add_json(ws, data.docenti, { origin: 3 });
    XLSX.utils.sheet_add_json(ws2, data.studenti, { origin: 3 });
    XLSX.utils.book_append_sheet(workbook, ws, 'Docenti');
    XLSX.utils.book_append_sheet(workbook, ws2, 'Studenti');
    XLSX.writeFile(workbook, name + '.xlsx');
};
export const formatStatistichePerExcel = (filterTable, dateStart, dateEnd) => {
    let docenti = filterTable?.docenti?.map(e => ({
        ID: e.id_docente,
        'Nome completo': e.cognome + ' ' + e.nome,
        'Ore lezione': e.counter,
        'Compenso orario': e.compenso_orario + '€',
        'Costo totale': e.costo + '€'
    }));
    let studenti = filterTable?.studenti?.map(e => ({
        ID: e.id_studente,
        'Nome completo': e.cognome + ' ' + e.nome,
        'Ore lezione': e.counter,
        'Costo orario': e.costo_ora_lezione + '€',
        'Costo settimanale': e.costo_settimanale + '€',
        'Costo totale': e.costo + '€'
    }));
    let date = moment(dateStart).format('YYYY-MM-DD') + ' => ' + moment(dateEnd).format('YYYY-MM-DD');
    return { studenti, docenti, date };
};
export const URL_ALLEGATI = {
    BASE: 'anagrAllegati/',
    UTENTE: 'allegatoutente/',
    PLANNING: 'allegatoplanning/',
    PLANNING_STUD: 'allegatoplanningstud/',
    SME: 'sme/'
};
/*
@param {string|number} id: id dell'allegato
@param {string} nome: nome dell'allegato
@param {URL_ALLEGATI} url: url dell'allegato
*/

export function downloadAllegatoMaster(id, nome, url) {
    addAuthToken();
    return new Promise((resolve, reject) => {
        axios.get(apiconfig().baseURL + url + id, { responseType: 'blob' }).then(response => {
            const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|pdf)$/i;
            if (pattern.test(nome)) {
                resolve(viewdData(response.data, nome));
            } else {
                downloadPDF(response.data, nome);
                resolve(false);
            }
        });
    });
}
export const scaricaSME = (id, nome, setState) => {
    setState({ modaleSpinner: { attivo: true } });
    downloadAllegatoMaster(id, nome, URL_ALLEGATI.SME)
        .then(data => {})
        .finally(() => {
            setState({ modaleSpinner: { attivo: false } });
        });
};
export function downloadAllegato(id, name) {
    addAuthToken();
    return new Promise((resolve, reject) => {
        axios.get(apiconfig().baseURL + 'anagrAllegati/' + id, { responseType: 'blob' }).then(response => {
            const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|pdf)$/i;
            if (pattern.test(name)) {
                resolve(viewdData(response.data, name));
            } else {
                downloadPDF(response.data, name);
                resolve(false);
            }
        });
    });
}
export function downloadAllegatoUtente(id, name) {
    addAuthToken();
    return new Promise((resolve, reject) => {
        axios.get(apiconfig().baseURL + 'allegatoutente/' + id, { responseType: 'blob' }).then(response => {
            const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|pdf)$/i;
            if (pattern.test(name)) {
                resolve(viewdData(response.data, name));
            } else {
                downloadPDF(response.data, name);
                resolve(false);
            }
        });
    });
}
export function downloadAllegatoPlanning(id, name) {
    addAuthToken();
    return new Promise((resolve, reject) => {
        axios.get(apiconfig().baseURL + 'allegatoplanning/' + id, { responseType: 'blob' }).then(response => {
            const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|pdf)$/i;

            if (pattern.test(name)) {
                resolve(viewdData(response.data, name));
            } else {
                downloadPDF(response.data, name);
                resolve(false);
            }
        });
    });
}
export function downloadAllegatoPlanningStud(id, name) {
    addAuthToken();
    return new Promise((resolve, reject) => {
        axios.get(apiconfig().baseURL + 'allegatoplanningstud/' + id, { responseType: 'blob' }).then(response => {
            const pattern = /\.(jpg|jpeg|png|gif|bmp|tiff|pdf)$/i;
            if (pattern.test(name)) {
                resolve(viewdData(response.data, name));
            } else {
                downloadPDF(response.data, name);
                resolve(false);
            }
        });
    });
}

export function handleDownloadAllegato(setState, data, name) {
    if (data)
        setState({
            modaleVisualizzaAllegato: data,
            modaleVisualizzaAllegatoIsPdf: name.includes('.pdf'),
            modaleVisualizzaAllegatoName: name
        });
}
