import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Progress } from 'reactstrap';
import Uploady, {
    useItemProgressListener,
    useItemErrorListener,
    useItemStartListener,
    useItemFinishListener
} from '@rpldy/uploady';
import { asUploadButton } from '@rpldy/upload-button';
import UploadDropZone from '@rpldy/upload-drop-zone';
import { apiconfig } from '../shared/apiconfig';
import styled from 'styled-components';

const StyledDropZone = styled(UploadDropZone)`
    &.drag-over {
        background-color: rgba(114, 255, 59, 0.6);
    }
`;

const UploadStatus = props => {
    const [progress, setProgress] = useState(0);
    const [err, setErr] = useState(null);
    const [fine, setFine] = useState(null);
    useEffect(() => {
        if (props.callbackAttivo && props.attivomodale) props.callbackAttivo();
    }, [props.attivomodale]);

    const progressData = useItemProgressListener();

    if (progressData && progressData.completed > progress) {
        setProgress(() => progressData.completed);
    }
    const errData = useItemErrorListener(item => {
        setErr(() => item);
        props.suCompletamento();
    });
    const endData = useItemFinishListener(item => {
        setFine(() => item);
        props.suCompletamento(item);
    });
    const startData = useItemStartListener(item => {
        setErr(() => null);
        setFine(() => null);
    });
    useEffect(() => {
        if (props.setLastUpload && (err || fine)) props.setLastUpload({ err, fine });
    }, [err, fine]);
    return (
        (err && (
            <div style={{ color: 'red', display: 'none' }}>
                {err?.uploadResponse?.data || 'ERRORE NEL CARICAMENTO DEL FILE'}
            </div>
        )) ||
        (fine && (
            <div
                id={props.isMappa ? 'dlg-file-success-message-map' : 'dlg-file-success-message'}
                style={{ color: 'green' }}
            >
                File {fine.file.name} caricato con successo
            </div>
        )) ||
        (progressData && (
            <div>
                <Progress value={progress} />
            </div>
        ))
    );
};
const DivUploadButton = asUploadButton(props => {
    return (
        <div {...props}>
            <Button className="mr-1" size="sm" color="primary">
                <span className="ml-1">... oppure sceglierlo cliccando qui</span>
            </Button>
        </div>
    );
});
const DivChangeFileButton = asUploadButton(props => {
    return (
        <div {...props}>
            <Button id={'change-file-dlg-button'} className="mr-1" size="sm" color="primary">
                <span className="ml-1">Modifica file</span>
            </Button>
        </div>
    );
});
export default class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completato: false,
            key: 'upload'
        };
    }
    render() {
        return (
            <>
                <div key={this.state.key + (this.props.index || 0)}>
                    <Uploady
                        fileFilter={file => {
                            if (document.getElementById('dlg-file-success-message')?.style.display === 'none') {
                                document.getElementById('dlg-file-success-message').style.display = 'block';
                            }

                            if (this.props.mappa) {
                                if (file.name.includes('.sme')) return true;
                                else {
                                    this.props.errorSme();
                                    return false;
                                }
                            } else if (this.props.tipoAllegato == 1) {
                                if (file.type.startsWith('image/')) return true;
                                else {
                                    this.props.errorImage();
                                    return false;
                                }
                            } else return true;
                        }}
                        multiple={false}
                        maxGroupSize={1}
                        sendWithFormData={true}
                        params={this.props.params}
                        method="POST"
                        destination={{
                            url: apiconfig().baseURL + this.props.path,
                            headers: { Authorization: 'Bearer ' + localStorage.token }
                        }}
                    >
                        <div
                            style={{
                                border: '2px red dashed',
                                marginBottom: '10px',
                                fontStyle: 'italic'
                            }}
                        >
                            <StyledDropZone onDragOverClassName="drag-over" grouped={false} maxGroupSize={1}>
                                <div style={{ padding: '10px' }}>
                                    {this.props.mappa ? 'Trascina un file SME' : 'Trascinare qui un file... '}
                                </div>
                            </StyledDropZone>
                        </div>
                        {this.props.fileAllegatoTemp && this.props.tipoAllegato == 1 ? (
                            <DivChangeFileButton />
                        ) : (
                            <DivUploadButton />
                        )}
                        <br />

                        <UploadStatus
                            fileAllegatoTemp={this.props.fileAllegatoTemp}
                            attivomodale={this.props.attivo}
                            callbackAttivo={this.props.callbackAttivo}
                            isMappa={this.props.mappa}
                            lastErr={this.state.err}
                            setLastUpload={data => {
                                this.setState(data);
                            }}
                            suCompletamento={data => {
                                setTimeout(() => {
                                    if (this.state.err) this.setState({ key: Date.now() });
                                }, 500);
                                this.props.suCompletamento(data);
                            }}
                        />
                    </Uploady>
                </div>
                {this.state.err && (
                    <div style={{ color: 'red' }}>
                        {this.state.err?.uploadResponse?.data || 'ERRORE NEL CARICAMENTO DEL FILE'}
                    </div>
                )}
                <br />
            </>
        );
    }
}
