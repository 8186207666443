import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Row, Spinner } from 'reactstrap';
import axios from 'axios';
import { apiconfig } from '../../../shared/apiconfig';
import { downloadPDF } from '../../../common/services';
const BR = 8;
export default function Section4(props) {
    let [file, setFile] = useState(null);
    let user = localStorage.getItem('user');
    if (user) user = JSON.parse(user);
    const [loadingState, setLoadingState] = useState({ uploading: false, generating: false, downloading: false });
    return (
        <div className={'position-relative'}>
            <h5 className={'title-doc-send'}>
                Scarica il modulo compilato, firmalo e ricaricalo sulla piattaforma, riceverà una comunicazione
                all'accettazione
            </h5>
            &nbsp;
            <div className={'d-flex justify-content-start'}>
                <label
                    style={{ borderRadius: BR, marginRight: !props.file ? 12 : 0 }}
                    onClick={() => {
                        let params = '';
                        if (props.userId) params += '?id_studente=' + props.userId;
                        if (!loadingState.generating) {
                            setLoadingState(previousState => ({ ...previousState, generating: true }));
                            axios
                                .get(apiconfig().baseURL + 'wizard/download' + params, { responseType: 'blob' })
                                .then(res => {
                                    setLoadingState(previousState => ({ ...previousState, generating: false }));
                                    downloadPDF(res.data);
                                });
                        }
                    }}
                    className={'d-flex align-items-center cursor-pointer primary-dlg-button w-auto py-1 px-2'}
                >
                    {!loadingState?.generating ? (
                        <i className={'fa fa-download'} style={{ fontSize: 18, marginRight: 12 }} />
                    ) : (
                        <Spinner
                            style={{ textAlign: 'center', marginRight: 12, marginLeft: 2 }}
                            size={'sm'}
                            color="white"
                        />
                    )}

                    <h6 className={'m-0'}>Genera documento non firmato</h6>
                </label>
                {props.file && (
                    <label
                        onClick={() => {
                            let params = '';
                            let user = '';
                            if (localStorage.user) user = JSON.parse(localStorage.user);
                            if (props.userId) params += '?id_studente=' + props.userId;
                            else if (user) params += '?id_studente=' + user.id;
                            if (!loadingState.downloading) {
                                setLoadingState(previousState => ({ ...previousState, downloading: true }));
                                axios
                                    .get(apiconfig().baseURL + 'studente/scaricaModuloIscrizione' + params, {
                                        responseType: 'blob'
                                    })
                                    .then(res => {
                                        setLoadingState(previousState => ({ ...previousState, downloading: false }));
                                        downloadPDF(res.data, 'Modulo_Compilato.pdf');
                                    });
                            }
                        }}
                        style={{ borderRadius: BR }}
                        className={'d-flex align-items-center cursor-pointer primary-dlg-button w-auto py-1 px-2 mx-3'}
                    >
                        {!loadingState?.downloading ? (
                            <i className={'fa fa-download'} style={{ fontSize: 18, marginRight: 12 }} />
                        ) : (
                            <Spinner
                                style={{ textAlign: 'center', marginRight: 12, marginLeft: 2 }}
                                size={'sm'}
                                color="white"
                            />
                        )}
                        <h6 className={'m-0'}>Scarica documento inviato</h6>
                    </label>
                )}
                <Form
                    id={'form-stud'}
                    onSubmitCapture={ev => {
                        ev.preventDefault();
                        ev.persist();
                        let formData = new FormData(ev.target);
                        if (!loadingState.uploading) {
                            setLoadingState(previousState => ({ ...previousState, uploading: true }));
                            axios
                                .post(apiconfig().baseURL + 'wizard/upload', formData, {
                                    headers: { 'Content-Type': 'multipart/form-data' }
                                })
                                .then(res => {
                                    setLoadingState(previousState => ({ ...previousState, uploading: false }));
                                    if (res.data.errors) {
                                        props.setAvviso({
                                            titolo: 'Errore',
                                            messaggio: res.data.errors,
                                            attivo: true
                                        });
                                        return;
                                    }
                                    props.setAvviso({
                                        titolo: 'Successo',
                                        messaggio: 'Documento inviato con successo',
                                        attivo: true
                                    });
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                })
                                .catch(err => {
                                    setLoadingState(previousState => ({ ...previousState, uploading: false }));
                                    props.setAvviso({
                                        titolo: 'Errore',
                                        messaggio: 'Errore imprevisto... riprovare più tardi',
                                        attivo: true
                                    });
                                });
                        }
                    }}
                >
                    <div className={'d-flex align-items-baseline'}>
                        <label
                            style={{ borderRadius: BR }}
                            htmlFor={'doc-stud'}
                            className={'d-flex align-items-center cursor-pointer primary-dlg-button w-auto py-2 px-2'}
                        >
                            {!loadingState?.uploading ? (
                                <i className={'fa fa-edit'} style={{ fontSize: 18, marginRight: 12 }} />
                            ) : (
                                <Spinner
                                    style={{ textAlign: 'center', marginRight: 12, marginLeft: 2 }}
                                    size={'sm'}
                                    color="white"
                                />
                            )}
                            {props.file || file ? (
                                <h6 className={'m-0'}>Modifica documento</h6>
                            ) : (
                                <h6 className={'m-0'}>Carica documento</h6>
                            )}
                            <input
                                style={{ display: 'none' }}
                                name={'file'}
                                onChange={event => setFile(event.target.files[0])}
                                type={'file'}
                                id={'doc-stud'}
                            />
                            <input
                                style={{ display: 'none' }}
                                name={'id_studente'}
                                value={props.userId || user.id}
                                id={'doc-stud'}
                            />
                        </label>
                        {file && (
                            <span className={'d-flex ml-3 align-items-baseline'}>
                                {file && file.name}
                                <i
                                    onClick={() => {
                                        document.getElementById('doc-stud').value = null;
                                        setFile(null);
                                    }}
                                    className={'fa fa-close ml-2 cursor-pointer'}
                                    style={{ color: '#F00', fontSize: 18, marginRight: 12 }}
                                />
                                <Button type={'submit'} color={'primary'} onClick={() => {}}>
                                    Invia
                                </Button>
                            </span>
                        )}
                    </div>
                </Form>
            </div>
            <h5 className={'position-absolute '} style={{ top: 0, right: 12 }}>
                Status:{' '}
                {!props.selectedUser?.abilitato ? (
                    <Badge color={props.file ? 'warning' : 'primary'}>
                        {props.file ? 'Documento inviato, In attesa di una risposta' : 'In attesa del documento'}
                    </Badge>
                ) : (
                    <Badge color={'success'}>{'Studente abilitato'}</Badge>
                )}
            </h5>
        </div>
    );
}
