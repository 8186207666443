import React, { Component } from 'react';

class TestoMultilinea extends Component {
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <React.Fragment>
          {this.props.testo != null ? 
            this.props.testo.split('\n').map((str, idx) => {
              return (
                <span key={this.props.keyMap + '_' + idx}>
                  {str}
                  <br />
                </span>
              )
            })
            : null}
        </React.Fragment>
      );
    }
}

export default TestoMultilinea;