import React, { useEffect, useState } from 'react';
import { Button, Col, DropdownItem, FormGroup, FormText, Input, InputGroup, Label, Row } from 'reactstrap';
import DateTimePicker from 'react-widgets/DatePicker';
import DropdownList from 'react-widgets/DropdownList';
import axios from 'axios';
import { addAuthToken, apiconfig } from '../../../shared/apiconfig';
import moment from 'moment';
import { calcolaCodiceFiscale } from '../../../shared/utility';
import Section3 from './Section3';
import Section4 from './Section4';
import { FormSection } from './FormSection';
import { capitalize } from '../../../common/services';

const days = ['Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];
const daysMap = { Lunedi: 1, Martedi: 2, Mercoledi: 3, Giovedi: 4, Venerdi: 5, Sabato: 6 };
const Form = FormSection;
export default function PartiDatiStudente(props) {
    const [errori, setErrori] = useState({});
    const [utente, setUtente] = useState({ orario: {} });
    const style = { border: '1px solid red !important' };
    const [dataDiNascitaAperto, setDataDiNascitaAperto] = useState(false);
    //Mi prendo i dati per eventuali compilazioni in sospeso
    useEffect(() => {
        if (props.section > 0) {
            props.setLoading(true);
            let params = '';
            if (props.userId) params += '&id_studente=' + props.userId;
            let url = apiconfig().baseURL + 'wizard/getStepData?step=4';
            if (props.section < 3) url = apiconfig().baseURL + 'wizard/getStepData?step=2&posizione=' + props.section;
            url += params;
            addAuthToken();
            axios.get(url).then(res => {
                props.setLoading(false);
                let temp = {};
                if (props.section < 3)
                    temp = {
                        ['tipo_genitore' + props.section]: res.data.data.tipo_genitore,
                        ['sesso_genitore' + props.section]: res.data.data.sesso,
                        ['nome_genitore' + props.section]: res.data.data.nome,
                        ['cognome_genitore' + props.section]: res.data.data.cognome,
                        ['mail_genitore' + props.section]: res.data.data.email,
                        ['via_genitore' + props.section]: res.data.data.address,
                        ['citta_genitore' + props.section]: res.data.data.comuneResidenza,
                        ['via_genitore' + props.section]: res.data.data.indirizzo,
                        ['data_di_nascita_genitore' + props.section]: res.data.data.data_nascita,
                        ['codice_fiscale_genitore' + props.section]: res.data.data.codice_fiscale,
                        ['luogo_di_nascita_genitore' + props.section]: res.data.data.comuneNascita,
                        ['id_comune_nascita_genitore' + props.section]: res.data.data.id_comune_nascita,
                        ['id_comune_genitore' + props.section]: res.data.data.id_comune,
                        ['cap_genitore' + props.section]: res.data.data.cap,
                        ['telefono_genitore' + props.section]: res.data.data.telefono
                    };
                else {
                    temp.giorni_orari = res.data.data.giorni_orari;
                    let data = JSON.parse(res.data.data.giorni_orari);
                    if (Object.keys(data).length > 0) temp.giorno_incontri = Object.keys(data).map(e => capitalize(e));
                    temp.dateData = data;
                    temp.n_lezioni_settimanali = res.data.data.n_lezioni_settimanali;
                    temp.autorizzazione_uscita = res.data.data.autorizzazione_uscita;
                }
                setUtente(prevState => ({ ...temp }));
            });
        }
        setErrori({});
    }, [props.section]);
    //setto i dati nel padre
    useEffect(() => {
        props.setData(utente);
    }, [utente]);

    const changeInputHandler = (ev, campo, name, regex) => {
        let utente2 = { ...utente };
        utente2[campo] = ev.target.value;
        if (ev.target.value === 'Padre') {
            utente2['sesso_genitore' + props.section] = 'M';
        }
        if (ev.target.value === 'Madre') {
            utente2['sesso_genitore' + props.section] = 'F';
        }
        if (ev.target.value === 'Tutore legale') {
            utente2['sesso_genitore' + props.section] = 'F';
        }
        setUtente(utente2);
        verificaErrore(campo, ev.target.value, name, regex);
    };

    const verificaErrore = (campo, valore, name, regex) => {
        let regError = !new RegExp(regex).test(valore);
        let error = !valore;
        setErrori(prevState => ({
            ...prevState,
            ['errore' + campo]: {
                errore: error || regError,
                descrizione: error ? 'Inserire ' + name : regError && 'Formato ' + name + ' non valido'
            }
        }));
        console.error(error);
        return error || regError;
    };
    const verificaErroreSenzaState = (campo, valore, name, regex) => {
        let regError = !new RegExp(regex).test(valore);
        let error = !valore;
        console.error(error);
        return error || regError;
    };
    const verificaErroreTutti = _ut => {
        let activeForm = Form.filter(e => e[0].section === props.section);
        let errore = false;
        let label = '';
        let _utente = _ut || utente;
        activeForm.forEach(e =>
            e.forEach(f => {
                if (!f.label) {
                    return;
                }
                if (f.section === 1) {
                    let erroretemp = verificaErrore(f.label, _utente[f.label], f.name, f.regex);
                    errore = errore || erroretemp;
                    if (erroretemp && f.name) {
                        if (label) label += ' \n';
                        else label = 'Si sono verificati i seguenti errori: \n';
                        label += '- Il campo ' + f.name + ' è obbligatiorio';
                    }
                }
                if (f.section === 2) {
                    let erroretemp = verificaErrore(f.label, _utente[f.label], f.name, f.regex);
                    errore = errore || erroretemp;
                    if (erroretemp && f.name) {
                        if (label) label += ' \n';
                        else label = 'Si sono verificati i seguenti errori: \n';
                        label += '- Il campo ' + f.name + ' è obbligatiorio';
                    }
                }
            })
        );
        // if (errore && props.section !== 2) {
        const component = (
            <p>
                {label.split('\n').map((e, i) => (
                    <p key={i}>{e}</p>
                ))}
            </p>
        );
        props.setAvviso({ attivo: errore, messaggio: component, titolo: 'Attenzione' });
        return errore;
    };
    const verificaErroreTuttiStateLess = _ut => {
        let activeForm = Form.filter(e => e[0].section === props.section);
        let errore = false;
        let label = '';
        let _utente = _ut || utente;
        activeForm.forEach(e =>
            e.forEach(f => {
                if (!f.label) {
                    return;
                }

                let erroretemp = verificaErroreSenzaState(f.label, _utente[f.label], f.name, f.regex);
                errore = errore || erroretemp;
                if (erroretemp && f.name) {
                    if (label) label += ' \n';
                    else label = 'Si sono verificati i seguenti errori: \n';
                    label += '- Il campo ' + f.name + ' è obbligatiorio';
                }
            })
        );
        return errore;
    };

    const componiCodiceFiscale = () => {
        let label = '';
        if (!utente['data_di_nascita_genitore' + props.section])
            label += '- La Data di nascita è obbligatoria per il calcolo del codice fiscale \n';
        if (!utente['luogo_di_nascita_genitore' + props.section])
            label += '- Il Luogo di nascita è obbligatorio per il calcolo del codice fiscale \n';
        if (!utente['sesso_genitore' + props.section])
            label += '- Il Sesso è obbligatorio per il calcolo del codice fiscale \n';
        if (!utente['cognome_genitore' + props.section])
            label += '- Il Cognome obbligatorio per il calcolo del codice fiscale \n';
        if (!utente['nome_genitore' + props.section])
            label += '- Il Nome è obbligatorio per il calcolo del codice fiscale \n';
        if (label) {
            const component = (
                <p>
                    {label.split('\n').map((e, i) => (
                        <p key={i}>{e}</p>
                    ))}
                </p>
            );
            props.setAvviso({ attivo: true, messaggio: component, titolo: 'Attenzione' });
            return;
        }
        let fiscal = calcolaCodiceFiscale(
            utente['data_di_nascita_genitore' + props.section],
            utente['luogo_di_nascita_genitore' + props.section].codice,
            utente['sesso_genitore' + props.section],
            utente['cognome_genitore' + props.section],
            utente['nome_genitore' + props.section]
        );
        let utente2 = { ...utente };
        utente2['codice_fiscale_genitore' + props.section] = fiscal;
        verificaErrore('codice_fiscale_genitore' + props.section, fiscal);
        setUtente(utente2);
    };
    useEffect(() => {
        if (document.getElementById('cardBodyRef'))
            document.getElementById('cardBodyRef').scrollTo({ left: 0, top: 0 });
    }, [props.section]);
    useEffect(() => {
        props.setVerifica(
            () => verificaErroreTutti(utente),
            () => verificaErroreTuttiStateLess(utente)
        );
    }, [errori, props.section, props.utente, utente]);
    function handleChangeDataDiNascita(raw, e2) {
        let date2 = moment(raw, 'DD/MM/YYYY').toDate();
        setUtente(prevState => ({
            ...prevState,
            [e2.label]: moment(date2).format('YYYY-MM-DD')
        }));
        verificaErrore(e2.label, moment(date2).format('YYYY-MM-DD'));
    }
    return (
        <div style={{ paddingBottom: window.innerWidth > 600 || props.section !== 3 ? 100 : 150 }}>
            {Form.map((e, i) => (
                <Row key={'formd' + i}>
                    {e
                        .filter(a => a.section === props.section)
                        ?.map((e2, j) => {
                            if (e2.subtitle)
                                return (
                                    <span style={{ fontSize: e2.fontSize, marginBottom: 8, width: 'max-content' }}>
                                        {e2.subtitle}
                                    </span>
                                );
                            if (e2.condition && utente[e2.condition] !== 'Tutore legale') return;
                            return (
                                <Col
                                    key={'cold' + i + j}
                                    xs={'12'}
                                    sm={'6'}
                                    md={12 / e.length + ''}
                                    style={{ width: e2.width }}
                                >
                                    {e2.type === 'city' ? (
                                        <FormGroup>
                                            <Label htmlFor="comune">{e2.name}</Label>
                                            <DropdownList
                                                filter
                                                messages={{
                                                    emptyFilter: 'Nessun risultato trovato',
                                                    emptyList: 'Inserisci almeno tre caratteri'
                                                }}
                                                busy={utente.ricercaComuneInCorso}
                                                data={utente['comuni' + e2.label]}
                                                valueField="id"
                                                className={errori['errore' + e2.label]?.errore && 'force-error'}
                                                textField="comune"
                                                renderListItem={item => {
                                                    let option = item.item;
                                                    return (
                                                        <DropdownItem key={option.id} value={option.id}>
                                                            {option.comune} ({option.prov})
                                                        </DropdownItem>
                                                    );
                                                }}
                                                style={errori && errori['errore' + e2.label]?.errore ? style : null}
                                                value={utente[e2.label] ? utente[e2.label] : null}
                                                onSearch={val => {
                                                    if (val.length === 3) {
                                                        setUtente(prevState => ({
                                                            ...prevState,
                                                            ricercaComuneInCorso: true
                                                        }));
                                                        axios
                                                            .get(apiconfig().baseURL + 'comuni?testoRicerca=' + val)
                                                            .then(res => {
                                                                setUtente(prevState => ({
                                                                    ...prevState,
                                                                    ['comuni' + e2.label]: res.data.map(e => ({
                                                                        ...e,
                                                                        comune: e.comune
                                                                    })),
                                                                    ricercaComuneInCorso: false
                                                                }));
                                                            });
                                                    }
                                                }}
                                                onChange={val => {
                                                    setUtente(prevState => ({
                                                        ...prevState,
                                                        [e2.label]: val
                                                    }));
                                                    verificaErrore(e2.label, val);
                                                }}
                                            />
                                        </FormGroup>
                                    ) : e2.type === 'date' ? (
                                        <FormGroup
                                            onBlur={event => event.preventDefault()}
                                            onChange={ev => ev.preventDefault()}
                                            placeholder={'DD/MM/YY'}
                                        >
                                            <Label htmlFor="date-input" className="mr-1">
                                                Data di nascita
                                            </Label>
                                            <DateTimePicker
                                                id={'date-input' + e2.label}
                                                className={errori['errore' + e2.label]?.errore && 'force-error'}
                                                time={false}
                                                placeholder={'DD/MM/YYYY'}
                                                style={errori && errori['errore' + e2.label]?.errore ? style : null}
                                                value={
                                                    utente[e2.label]
                                                        ? moment(utente[e2.label], 'YYYY-MM-DD').toDate()
                                                        : null
                                                }
                                                onSelect={ev => handleChangeDataDiNascita(ev, e2)}
                                                onToggle={ev => {
                                                    setDataDiNascitaAperto(ev);
                                                }}
                                                calendarProps={{}}
                                                inputProps={{
                                                    onBlur: event => {
                                                        if (!dataDiNascitaAperto)
                                                            handleChangeDataDiNascita(
                                                                moment(event.target.value, 'DD/MM/YYYY').toDate(),
                                                                e2
                                                            );
                                                    }
                                                }}
                                                format={{ raw: 'DD/MM/YYYY' }}
                                                renderInput={params => <Input {...params} onChange={console.info} />}
                                            />
                                        </FormGroup>
                                    ) : (
                                        <FormGroup style={{ width: e2.width }}>
                                            {e2.name && <Label>{e2.name}</Label>}
                                            <InputGroup>
                                                <Input
                                                    type={e2.type || 'text'}
                                                    disabled={e2.type === 'fiscal'}
                                                    className={errori['errore' + e2.label]?.errore && 'force-error'}
                                                    style={errori && errori['errore' + e2.label]?.errore ? style : null}
                                                    name={e2.label}
                                                    placeholder={e2.name}
                                                    value={utente[e2.label] || ''}
                                                    onChange={ev => changeInputHandler(ev, e2.label, e2.name, e2.regex)}
                                                >
                                                    {e2.selectOptions?.length > 0 ? <option value={''}></option> : null}
                                                    {e2.selectOptions?.length > 0
                                                        ? e2.selectOptions.map(e => (
                                                              <option value={e.value}>{e.label}</option>
                                                          ))
                                                        : null}
                                                </Input>
                                                {e2.type === 'fiscal' && (
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={componiCodiceFiscale}
                                                    >
                                                        Calcola
                                                    </Button>
                                                )}
                                            </InputGroup>
                                            {errori && errori['errore' + e2.label]?.errore ? (
                                                <FormText color="danger">
                                                    {errori['errore' + e2.label]?.descrizione}
                                                </FormText>
                                            ) : null}
                                        </FormGroup>
                                    )}
                                </Col>
                            );
                        })}
                </Row>
            ))}
            {props.section === 3 ? (
                <Section3
                    utente={utente}
                    maggiorenne={props.maggiorenne}
                    days={days}
                    userId={props.userId}
                    setUtente={setUtente}
                    daysMap={daysMap}
                    setAvviso={props.setAvviso}
                />
            ) : null}
            {props.section === 4 ? (
                <Section4
                    userId={props.userId}
                    selectedUser={props.selectedUser}
                    file={props.file}
                    setAvviso={props.setAvviso}
                />
            ) : null}
        </div>
    );
}
