import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Row, Col, Label } from 'reactstrap';
import DropdownList from 'react-widgets/DropdownList';
import axios from 'axios';
import { apiconfig } from '../shared/apiconfig';
import 'react-widgets/styles.css';


export default class SceltaComune extends Component {

    constructor(props) {
        super(props);
        this.state={
            nonCodificato: false,
            ricercaComuneInCorso: false,
            testoRicerca: '',
            comuni: [],
            idNuovoComune: null,
            nuovoCodiceComune: null,
            nomeNuovoComune: null,
            nuovaProv: null,
            nuovoComuneNonCodificato: null,
            nuovaProvNonCodificata: null,
            errore: null
        };
        this.setDatiIdNuovoComune = this.setDatiIdNuovoComune.bind(this);
        this.changeNomeNuovoComuneHandler = this.changeNomeNuovoComuneHandler.bind(this);
        this.changeNuovaProvHandler=this.changeNuovaProvHandler.bind(this);

    }

    setDatiIdNuovoComune(id, codice, nome, prov) {
        this.setState({
            idNuovoComune: id,
            nuovoCodiceComune: codice,
            nomeNuovoComune: nome,
            nuovaProv: prov,
            nuovoComuneNonCodificato: null,
            nuovaProvNonCodificata: null
        });
    }

    attivaRicercaComuni(arg) {
        this.setState({
            testoRicerca: arg,
            ricercaComuneInCorso: true
        }, this.ricercaComuni);
    }

    ricercaComuni() {
        if (this.state.testoRicerca.length === 3) {
            const params = {params: {testoRicerca: this.state.testoRicerca}};
            axios.get(apiconfig().baseURL + 'comuni',params)
                .then(response => {
                    this.setState({
                        comuni: response.data.map(x => {
                            return {
                                id: x.id,
                                codice: x.codice,
                                comune: x.comune,
                                prov: x.prov,
                                descr: x.comune + ' (' + x.prov + ')'
                            }
                        }),
                        ricercaComuneInCorso: false
                    });
                })
                .catch((err) => {
                    if (err.response && err.response.status && err.response.status == 401) {
                        this.props.conferma({
                            idNuovoComune: null,
                            nuovoCodiceComune: null,
                            nomeNuovoComune: null,
                            nuovaProv: null,
                            nuovoComuneNonCodificato: null,
                            nuovaProvNonCodificata: null,
                            errore: 1
                        });
                    } else {
                        this.setState({
                            comuni: [],
                            ricercaComuneInCorso: false
                        });
                    }
                });
        } else if (this.state.testoRicerca.length < 3) {
            this.setState({
                comuni: [],
                ricercaComuneInCorso: false
            });
        } else {
            this.setState({
                ricercaComuneInCorso: false
            });
        }
    }

    handleChangeCheckNonCodificato(arg) {
        this.setState({
            nonCodificato: arg.target.checked
        });
    }

    changeIdComuneHandler(arg) {
        if (arg) {
            const comuneCorrente = this.state.comuni.find(x => x.id===arg.id);
            if (comuneCorrente)
                this.setDatiIdNuovoComune(comuneCorrente.id, comuneCorrente.codice, comuneCorrente.comune, comuneCorrente.prov);
            else
                this.setDatiIdNuovoComune(null, null, null, null);
        } else {
            this.setDatiIdNuovoComune(null, null, null, null);
        }
    }

    changeNomeNuovoComuneHandler(arg) {
        this.setState({
            idNuovoComune: null,
            nuovoCodiceComune: null,
            nomeNuovoComune: null,
            nuovaProv: null,
            nuovoComuneNonCodificato: arg.target.value,
        });
    }

    changeNuovaProvHandler(arg) {
        this.setState({
            idNuovoComune: null,
            nuovoCodiceComune: null,
            nomeNuovoComune: null,
            nuovaProv: null,
            nuovaProvNonCodificata: arg.target.value,
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.attivo} >
                <ModalHeader>Scelta comune</ModalHeader>
                <ModalBody>
                    <Form action="" onSubmit={e => e.preventDefault()} className="form-horizontal">
                        <Row>
                            <Col xs="12" sm="12" md="12">
                                <FormGroup>
                                    <Label htmlFor='comune'>Comune:</Label>
                                    <DropdownList
                                        filter
                                        busy={this.state.ricercaComuneInCorso}
                                        disabled={this.state.nonCodificato}
                                        data={this.state.comuni}
                                        valueField='id'
                                        textField='descr'
                                        value={this.props.idNuovoComune !== 0 ? this.props.idNuovoComune : null}
                                        onSearch={this.attivaRicercaComuni.bind(this)}
                                        onChange={this.changeIdComuneHandler.bind(this)}
                                        />
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="12">
                            <FormGroup check className="checkbox">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkNonCodificato"
                                    name="checkNonCodificato"
                                    checked={this.state.nonCodificato}
                                    onChange={this.handleChangeCheckNonCodificato.bind(this)}
                                    />
                                <Label
                                    check
                                    className="form-check-label"
                                    htmlFor="checkNonCodificato">
                                    Non codificato
                                </Label>
                            </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="12">
                                <FormGroup>
                                    <Input
                                        type="text"
                                        disabled={!this.state.nonCodificato}
                                        id="txtComune"
                                        name="txtComune"
                                        value={this.state.nuovoComuneNonCodificato || '' }
                                        onChange={this.changeNomeNuovoComuneHandler.bind(this)}
                                        />
                                </FormGroup>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <FormGroup>
                                    <Label htmlFor='provincia'>Prov.:</Label>
                                    <Input
                                        type="text"
                                        disabled={!this.state.nonCodificato}
                                        id="provincia"
                                        name="provincia"
                                        value={this.state.nuovaProvNonCodificata || '' }
                                        onChange={this.changeNuovaProvHandler.bind(this)}
                                        />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => this.props.conferma({
                            idNuovoComune: !this.state.nonCodificato ? this.state.idNuovoComune : null,
                            nuovoCodiceComune: !this.state.nonCodificato ? this.state.nuovoCodiceComune : null,
                            nomeNuovoComune: !this.state.nonCodificato ? this.state.nomeNuovoComune : null,
                            nuovaProv: !this.state.nonCodificato ? this.state.nuovaProv : null,
                            nuovoComuneNonCodificato: this.state.nonCodificato ? this.state.nuovoComuneNonCodificato : null,
                            nuovaProvNonCodificata: this.state.nonCodificato ? this.state.nuovaProvNonCodificata : null
                        })}
                    >
                        OK
                    </Button>
                    {' '}
                    <Button  onClick={() => this.props.toggle()}>Annulla</Button>{' '}
                </ModalFooter>
            </Modal>
      )
    }

}
