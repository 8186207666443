// noinspection JSValidateTypes

import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Row,
    Col,
    Input,
    Label,
    Card,
    CardHeader,
    CardBody,
    ListGroup
} from 'reactstrap';
import FileUploader from './FileUploader';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../shared/apiconfig';
import FiltroAllegati from './FiltroAllegati';
import { updateObject } from '../shared/utility';
import Paginazione from '../common/Paginazione';
import { IfActive } from '../views/Planning/DlgComponents/PlanningServices';
import Avviso from './Avviso';
import { downloadAllegatoMaster, scaricaSME, URL_ALLEGATI } from '../services/commons';
import ModaleVisualizzaAllegato from '../views/Allegati/Components/ModaleVisualizzaAllegato';
import TooltipItem from './TooltipItem';
import ModaleViewFileToUpload from './ModaleViewFileToUpload';
const classError = 'border-red-important';

export default class Allegati extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipiAllegato: [],
            totaleItemsRicerca: 0,
            paginaRicerca: 1,
            itemsPerPagina: 20,
            indiciPagine: [],
            scuole: [],
            avviso: {},
            classi: [],
            currentType: null,
            scuoleStudenti: [],
            materie: [],
            idTipoAllegato: '',
            scuolaAllegato: '',
            classeAllegato: '',
            idMateriaAllegato: '',
            filtriRicerca: {
                tipiAllegati: [],
                scuole: [],
                classi: [],
                docenti: [],
                materie: [],
                descrizione: ''
            },
            listaAllegati: []
        };
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.verificaErrore = this.verificaErrore.bind(this);
    }

    componentDidMount() {
        addAuthToken();
        this.caricaElenchi();
    }

    caricaElenchi = () => {
        axios
            .get(apiconfig().baseURL + 'tipiAllegati', {})
            .then(response => {
                this.setState({
                    tipiAllegato: response.data
                });
            })
            .catch(error => {
                console.error(error);
                if (error?.response?.status && error.response.status == 401) {
                    window.location.href = '#/logout';
                }
            });
        axios
            .get(apiconfig().baseURL + 'scuole', {})
            .then(response => {
                this.setState({
                    scuole: response.data
                });
            })
            .catch(error => {
                console.error(error);
            });
        axios
            .get(apiconfig().baseURL + 'materie', {})
            .then(response => {
                this.setState({
                    materie: response.data
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    cambiaPagina = nuova => {
        this.setState(
            {
                paginaRicerca: nuova
            },
            this.aggiornaRicerca
        );
    };

    aggiornaRicerca() {
        const filtri = {
            tipiAllegati: JSON.stringify(this.state.filtriRicerca.tipiAllegati.map(el => el.id)),
            materie: JSON.stringify(this.state.filtriRicerca.materie.map(el => el.id)),
            classi: JSON.stringify(this.state.filtriRicerca.classi.map(el => el.id)),
            docenti: JSON.stringify(this.state.filtriRicerca.docenti.map(el => el.id)),
            scuole: JSON.stringify(this.state.filtriRicerca.scuole.map(el => el.id)),
            descrizione: this.state.filtriRicerca.descrizione
        };
        const params = { params: filtri };
        axios
            .get(apiconfig().baseURL + 'listaAllegati?page=' + this.state.paginaRicerca, params)
            .then(response => {
                this.setState(
                    {
                        listaAllegati: response.data.data,
                        totaleItemsRicerca: response.data.total
                    },
                    () => {
                        if (
                            this.state.totaleItemsRicerca > 0 &&
                            Math.ceil(this.state.totaleItemsRicerca / this.state.itemsPerPagina) <
                                this.state.paginaRicerca
                        ) {
                            this.setState(
                                {
                                    paginaRicerca: Math.ceil(this.state.totaleItemsRicerca / this.state.itemsPerPagina)
                                },
                                () => this.aggiornaRicerca()
                            );
                        }
                    }
                );
            })
            .catch(error => {
                console.error(error);
                if (error?.response?.status && error.response.status == 401) {
                    this.props.onInvalidToken(error, this.props.location.pathname, this.state);
                    this.props.history.push('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: error.errore
                        }
                    });
                }
            });
    }

    scaricaAllegato = (id, name) => {
        downloadAllegatoMaster(id, name, URL_ALLEGATI.BASE).then(data => {
            if (data)
                this.setState({
                    modaleVisualizzaAllegato: data,
                    modaleVisualizzaAllegatoIsPdf: name.includes('.pdf'),
                    modaleVisualizzaAllegatoName: name
                });
        });
    };

    handleChangefiltroRicercaTipiAllegati = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            tipiAllegati: arg
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };

    handleChangefiltroRicercaScuole = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            scuole: arg
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };
    handleChangefiltroRicercaDocenti = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            docenti: arg
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };

    handleChangefiltroRicercaMaterie = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            materie: arg
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };

    handleChangefiltroRicercaClassi = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            classi: arg
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };

    handleChangefiltroRicercaDescrizione = arg => {
        const newfiltriRicerca = updateObject(this.state.filtriRicerca, {
            descrizione: arg.target.value
        });
        this.setState(
            {
                filtriRicerca: newfiltriRicerca
            },
            this.aggiornaRicerca
        );
    };

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: ''
        };
        this.setState({
            avviso: modAvviso
        });
    };
    verificaErrore = ({
        withWarning,
        tipoAllegatoNuovo,
        materiaAllegatoNuovo,
        classeAllegatoNuovo,
        idScuolaAllegatoNuovo,
        descrizioneAllegatoNuovo
    }) => {
        this.setState({
            erroreTipo:
                !this.props.idTipoAllegatoNuovo &&
                !tipoAllegatoNuovo &&
                !this.props.idAllegatoModificato &&
                !this.props.selAllegatoEsistente,
            erroreMateria:
                !this.props.idMateriaAllegatoNuovo &&
                !materiaAllegatoNuovo &&
                !this.props.idAllegatoModificato &&
                !this.props.selAllegatoEsistente,
            erroreClasse:
                !this.props.classeAllegatoNuovo &&
                !classeAllegatoNuovo &&
                !this.props.idAllegatoModificato &&
                !this.props.selAllegatoEsistente,
            erroreDescrizione:
                `${tipoAllegatoNuovo || this.props.idTipoAllegatoNuovo}` === '1' &&
                !this.props.descrAllegatoNuovo &&
                !descrizioneAllegatoNuovo,
            erroreScuola:
                !this.props.idScuolaAllegatoNuovo &&
                !idScuolaAllegatoNuovo &&
                !this.props.idAllegatoModificato &&
                !this.props.selAllegatoEsistente
        });
        if (
            (!this.props.idTipoAllegatoNuovo ||
                !this.props.idMateriaAllegatoNuovo ||
                !this.props.classeAllegatoNuovo ||
                !this.props.idScuolaAllegatoNuovo ||
                (`${tipoAllegatoNuovo || this.props.idTipoAllegatoNuovo}` === '1' && !this.props.descrAllegatoNuovo)) &&
            !this.props.selAllegatoEsistente
        ) {
            this.setState({
                avviso: { attivo: withWarning, titolo: 'ATTENZIONE', messaggio: 'Compilare tutti i campi obbligatori' }
            });
            return true;
        }

        return false;
    };
    render() {
        if (
            ((this.props.selectedStud && this.props.selectedStud !== this.state.stud) ||
                (this.props.selectedStud?.classe !== this.props.classeAllegatoNuovo &&
                    this.props.selectedStud?.classe)) &&
            !this.state.setted
        ) {
            this.setState({
                stud: this.props.selectedStud,
                classeAllegatoNuovo: this.props.selectedStud.classe,
                scuolaAllegato: this.props.selectedStud.scuola,
                setted: true
            });
            this.props.handleChangeClasseAllegato({ target: { value: this.props.selectedStud.classe } });
            this.props.handleChangeScuolaAllegato({ target: { value: this.props.selectedStud.scuola } });
        }
        let user;
        if (this.props.isNotLesson && !this.state.cleaned) {
            this.setState({ stud: null, cleaned: true, classeAllegatoNuovo: null, scuolaAllegato: null });
            this.props.handleChangeClasseAllegato({ target: { value: '' } });
            this.props.handleChangeScuolaAllegato({ target: { value: '' } });
        }
        if (localStorage.user) user = JSON.parse(localStorage.user);
        return (
            <Modal isOpen={this.props.attivo} size="lg">
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <IfActive
                    active={this.props.attivo}
                    callBack={() => {
                        let studenti =
                            this.props.eventi?.find(e => e.id === this.props.idEvento)?.studenti || this.props.studenti;
                        let classi = [];
                        let scuole = [];
                        if (studenti?.length > 0) {
                            studenti.forEach(e => {
                                if (e.classe) classi.push(e.classe);
                                if (e.scuola) scuole.push(e.scuola);
                            });
                            this.setState({ classi, scuoleStudenti: scuole, setted: false, cleaned: false });
                        }
                    }}
                    onClose={() => {
                        this.props.handleChangeClasseAllegato({ target: { value: '' } });
                        this.props.handleChangeScuolaAllegato({ target: { value: '' } });
                        this.setState({
                            classeAllegatoNuovo: null,
                            scuolaAllegato: null,
                            stud: null,
                            classi: [],
                            scuoleStudenti: []
                        });
                    }}
                />
                <ModalHeader>
                    Inserimento/Modifica allegato{' '}
                    {this.props.path?.replace('arcg', 'archivio').replace('elenc', 'elenco') || ''}
                </ModalHeader>
                <ModalBody>
                    {this.props.mostraSelAllegatoEsistente ? (
                        <Row>
                            <Col xs="12" md="12" sm="12">
                                <div className="mb-3 ml-3">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'checkAllegatoEsistente' + this.props.tipoAllegato}
                                        name={'checkAllegatoEsistente' + this.props.tipoAllegato}
                                        checked={this.props.selAllegatoEsistente}
                                        onChange={this.props.handleChangeAllegatoEsistente}
                                    />
                                    <Label
                                        check
                                        className="form-check-label"
                                        htmlFor={'checkAllegatoEsistente' + this.props.tipoAllegato}
                                    >
                                        Scegli dall'archivio
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                    {!this.props.selAllegatoEsistente ? (
                        <Card>
                            <CardHeader>
                                <Label style={{ fontWeight: 'bold' }}>Inserimento nuovo documento</Label>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" md="12" sm="12">
                                        <div>
                                            <Row>
                                                <Col xs="12" md="6" sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="selectTipo">Tipo di allegato</Label>
                                                        <Input
                                                            type="select"
                                                            id="selectTipo"
                                                            className={this.state.erroreTipo ? classError : ''}
                                                            value={
                                                                this.props.idTipoAllegatoNuovo
                                                                    ? this.props.idTipoAllegatoNuovo
                                                                    : ''
                                                            }
                                                            onChange={ev => {
                                                                this.verificaErrore({
                                                                    withWarning: false,
                                                                    tipoAllegatoNuovo: ev.target.value
                                                                });
                                                                this.setState({
                                                                    fileAllegatoTemp: null,
                                                                    fileAllegatoTemp1: null
                                                                });
                                                                if (document.getElementById('dlg-file-success-message'))
                                                                    document.getElementById(
                                                                        'dlg-file-success-message'
                                                                    ).style.display = 'none';
                                                                this.setState({
                                                                    cuurentType: ev.target.value
                                                                });
                                                                this.props.handleChangeTipoAllegato(ev);
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {this.state.tipiAllegato.map(tipo => {
                                                                return (
                                                                    <option key={tipo.id} value={tipo.id}>
                                                                        {tipo.nome}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="selectMateria">Materia</Label>
                                                        <Input
                                                            type="select"
                                                            id="selectMateria"
                                                            className={this.state.erroreMateria ? classError : ''}
                                                            value={
                                                                this.props.idMateriaAllegatoNuovo
                                                                    ? this.props.idMateriaAllegatoNuovo
                                                                    : ''
                                                            }
                                                            onChange={ev => {
                                                                this.props.handleChangeMateriaAllegato(ev);
                                                                this.verificaErrore({
                                                                    withWarning: false,
                                                                    materiaAllegatoNuovo: ev.target.value
                                                                });
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {this.state.materie.map(mat => {
                                                                return (
                                                                    <option key={mat.id} value={mat.id}>
                                                                        {mat.materia}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="selectClasse">Classe</Label>
                                                        <Input
                                                            type="select"
                                                            className={this.state.erroreClasse ? classError : ''}
                                                            id="selectClasse"
                                                            value={
                                                                this.props.classeAllegatoNuovo
                                                                    ? this.props.classeAllegatoNuovo
                                                                    : ''
                                                            }
                                                            onChange={ev => {
                                                                this.verificaErrore({
                                                                    withWarning: false,
                                                                    classeAllegatoNuovo: ev.target.value
                                                                });
                                                                this.setState({ setted: true });
                                                                this.props.handleChangeClasseAllegato(ev);
                                                            }}
                                                        >
                                                            {['', '1', '2', '3', '4', '5', '6'].map(e => (
                                                                <option
                                                                    className={
                                                                        this.state.classi.includes(parseInt(e))
                                                                            ? 'dlg-highlited'
                                                                            : 'no'
                                                                    }
                                                                    value={e}
                                                                >
                                                                    {e}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="6" sm="6">
                                                    <FormGroup>
                                                        <Label htmlFor="selectScuola">Scuola</Label>
                                                        <Input
                                                            type="select"
                                                            id="selectScuola"
                                                            className={this.state.erroreScuola ? classError : ''}
                                                            value={
                                                                this.props.idScuolaAllegatoNuovo
                                                                    ? this.props.idScuolaAllegatoNuovo
                                                                    : ''
                                                            }
                                                            onChange={ev => {
                                                                this.setState({ setted: true });
                                                                this.verificaErrore({
                                                                    withWarning: false,
                                                                    idScuolaAllegatoNuovo: ev.target.value
                                                                });
                                                                this.props.handleChangeScuolaAllegato(ev);
                                                            }}
                                                        >
                                                            <option value=""></option>
                                                            {this.state.scuole.map(s => {
                                                                return (
                                                                    <option
                                                                        key={s.id}
                                                                        className={
                                                                            this.state.scuoleStudenti.includes(
                                                                                parseInt(s.id)
                                                                            )
                                                                                ? 'dlg-highlited'
                                                                                : 'no'
                                                                        }
                                                                        value={s.id}
                                                                    >
                                                                        {s.nome}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" md="12" sm="12">
                                                    <FormGroup>
                                                        <Label htmlFor="txtDescrizione">Descrizione</Label>
                                                        <Input
                                                            type="text"
                                                            id="txtDescrizione"
                                                            value={
                                                                this.props.descrAllegatoNuovo
                                                                    ? this.props.descrAllegatoNuovo
                                                                    : ''
                                                            }
                                                            className={this.state.erroreDescrizione ? classError : ''}
                                                            onChange={ev => {
                                                                this.setState({ setted: true });
                                                                this.verificaErrore({
                                                                    withWarning: false,
                                                                    descrizioneAllegatoNuovo: ev.target.value
                                                                });
                                                                this.props.handleChangeDescrAllegato(ev);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        {!this.props.idAllegatoModificato || this.props.idAllegatoModificato == 0 ? (
                                            this.props.idTipoAllegatoNuovo ? (
                                                <>
                                                    <FileUploader
                                                        attivo={this.props.attivo}
                                                        tipoAllegato={this.props.idTipoAllegatoNuovo}
                                                        index={0}
                                                        callbackAttivo={this.props.resetMap}
                                                        errorImage={() => {
                                                            this.setState({
                                                                avviso: {
                                                                    attivo: true,
                                                                    titolo: 'ATTENZIONE',
                                                                    messaggio: 'Il file deve essere un immagine'
                                                                }
                                                            });
                                                        }}
                                                        params={{
                                                            ...this.props.fileUploaderParams,
                                                            id_tipo_allegato: this.props.idTipoAllegatoNuovo
                                                        }}
                                                        path={this.props.fileUploaderPath}
                                                        fileAllegatoTemp={this.state.fileAllegatoTemp}
                                                        suCompletamento={data => {
                                                            if (data?.uploadResponse?.data) {
                                                                this.setState({
                                                                    fileAllegatoTemp1: data.uploadResponse?.data
                                                                });
                                                            }
                                                            if (
                                                                data?.uploadResponse?.data &&
                                                                this.props.idTipoAllegatoNuovo == 1
                                                            ) {
                                                                this.setState({
                                                                    fileAllegatoTemp: data?.uploadResponse?.data
                                                                });
                                                            }
                                                            //todo mettere qui il controllo
                                                            this.props.suCompletamento(data);
                                                        }}
                                                    />
                                                    {this.props.idTipoAllegatoNuovo + '' === '1' && (
                                                        <FileUploader
                                                            attivo={this.props.attivo}
                                                            callbackAttivo={this.props.resetMap}
                                                            index={1}
                                                            mappa
                                                            errorSme={() => {
                                                                this.setState({
                                                                    avviso: {
                                                                        attivo: true,
                                                                        titolo: 'ATTENZIONE',
                                                                        messaggio: 'Il file deve avere estenzione SME'
                                                                    }
                                                                });
                                                            }}
                                                            params={{
                                                                ...this.props.fileUploaderParams,
                                                                fileMappa: true
                                                            }}
                                                            path={this.props.fileUploaderPath}
                                                            suCompletamento={data => {
                                                                this.setState({
                                                                    fileSmeTemp: data?.uploadResponse?.data || null
                                                                });
                                                                this.props.suCompletamentoMap(data);
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <span
                                                    style={{
                                                        textAlign: 'center',
                                                        color: 'red',
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    Inserisci un tipo di allegato
                                                </span>
                                            )
                                        ) : (
                                            <div>Nome file: {this.props.nomeFileAllegatoNuovo}</div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    ) : (
                        <React.Fragment>
                            <Row>
                                <Col xs="12" sm="12" md="12">
                                    <FiltroAllegati
                                        filtriRicerca={this.state.filtriRicerca}
                                        handleChangefiltroRicercaTipiAllegati={
                                            this.handleChangefiltroRicercaTipiAllegati
                                        }
                                        handleChangefiltroRicercaDocenti={this.handleChangefiltroRicercaDocenti}
                                        handleChangefiltroRicercaScuole={this.handleChangefiltroRicercaScuole}
                                        handleChangefiltroRicercaClassi={this.handleChangefiltroRicercaClassi}
                                        handleChangefiltroRicercaMaterie={this.handleChangefiltroRicercaMaterie}
                                        handleChangefiltroRicercaDescrizione={this.handleChangefiltroRicercaDescrizione}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="12" sm="12">
                                    <Paginazione
                                        suCambiaPagina={this.cambiaPagina}
                                        paginaRicerca={this.state.paginaRicerca}
                                        totaleItems={this.state.totaleItemsRicerca}
                                        itemsPerPagina={this.state.itemsPerPagina}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12">
                                    {this.props.listaAllegati.map(el => el + ' ')}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12" md="12" sm="12">
                                                    <ListGroup>
                                                        {this.state.listaAllegati
                                                            ? this.state.listaAllegati.map(el => {
                                                                  return (
                                                                      <React.Fragment key={'FragAllegato' + el.id}>
                                                                          <div className="ml-3">
                                                                              <Input
                                                                                  className="form-check-input"
                                                                                  type="checkbox"
                                                                                  id={
                                                                                      'checkSelAllegatoEsistente' +
                                                                                      el.id
                                                                                  }
                                                                                  name={
                                                                                      'checkSelAllegatoEsistente' +
                                                                                      el.id
                                                                                  }
                                                                                  checked={
                                                                                      this.props.allegatiSelezionati
                                                                                          ? this.props.allegatiSelezionati.find(
                                                                                                x => x.id == el.id
                                                                                            )
                                                                                              ? true
                                                                                              : false
                                                                                          : false
                                                                                  }
                                                                                  onChange={this.props.handleChangeSelAllegatoEsistente.bind(
                                                                                      this,
                                                                                      el
                                                                                  )}
                                                                              />
                                                                              <Button
                                                                                  color="link"
                                                                                  onClick={() =>
                                                                                      this.scaricaAllegato(
                                                                                          el.id,
                                                                                          el.nome_file
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  {el.nome_file}
                                                                              </Button>
                                                                          </div>
                                                                          {el.sme && (
                                                                              <>
                                                                                  {' '}
                                                                                  <Button
                                                                                      size="sm"
                                                                                      style={{
                                                                                          boxShadow:
                                                                                              '0px 0px 2px rgba(2, 92, 204, 0.1)'
                                                                                      }}
                                                                                      color="primary"
                                                                                      className="float-right sme-button"
                                                                                      onClick={() =>
                                                                                          scaricaSME(
                                                                                              el.sme?.idFileMappa,
                                                                                              el.sme?.nome_file_mappa,
                                                                                              data => {
                                                                                                  this.setState({
                                                                                                      ...data
                                                                                                  });
                                                                                              }
                                                                                          )
                                                                                      }
                                                                                      id={'sme_' + el.id}
                                                                                  >
                                                                                      <span style={{ fontSize: 10 }}>
                                                                                          SME
                                                                                      </span>
                                                                                  </Button>
                                                                                  <TooltipItem
                                                                                      placement="top"
                                                                                      objectId={'sme_' + el.id}
                                                                                      content="Scarica file SME"
                                                                                  />
                                                                              </>
                                                                          )}
                                                                          <div
                                                                              style={{
                                                                                  marginTop: '-3px',
                                                                                  marginBottom: '3px'
                                                                              }}
                                                                          >
                                                                              <span
                                                                                  style={{ color: 'maroon' }}
                                                                                  className="mr-2"
                                                                              >
                                                                                  {el.tipo && el.tipo.nome
                                                                                      ? el.tipo.nome
                                                                                      : ''}
                                                                              </span>
                                                                              <span className="mr-2">
                                                                                  {el.classe
                                                                                      ? 'Classe: ' + el.classe
                                                                                      : ''}
                                                                              </span>
                                                                              <span className="mr-2">
                                                                                  {el.scuola && el.scuola.nome
                                                                                      ? 'Scuola: ' + el.scuola.nome
                                                                                      : ''}
                                                                              </span>
                                                                              <span className="mr-2">
                                                                                  {el.materia && el.materia.materia
                                                                                      ? 'Materia: ' + el.materia.materia
                                                                                      : ''}
                                                                              </span>
                                                                              <br />
                                                                              {el.descrizione ? el.descrizione : ''}
                                                                          </div>
                                                                      </React.Fragment>
                                                                  );
                                                              })
                                                            : null}
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </ModalBody>
                <ModaleViewFileToUpload
                    isUploading={this.state.isUploading}
                    attivo={!!this.state.fileAllegatoTemp}
                    data={this.state.fileAllegatoTemp}
                    toggle={() => this.setState({ fileAllegatoTemp: null })}
                />
                <ModaleVisualizzaAllegato
                    data={this.state.modaleVisualizzaAllegato}
                    isPdf={this.state.modaleVisualizzaAllegatoIsPdf}
                    name={this.state.modaleVisualizzaAllegatoName}
                    close={() => {
                        this.setState({ modaleVisualizzaAllegato: null });
                    }}
                />
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            let error = this.verificaErrore({ withWarning: true });
                            if (!error) {
                                //mettere qui il modal di errore
                                if (!this.state.fileAllegatoTemp1 && !this.props.selAllegatoEsistente) {
                                    this.setState({
                                        avviso: {
                                            attivo: true,
                                            titolo: 'ATTENZIONE',
                                            messaggio: 'Seleziona un allegato principale'
                                        }
                                    });
                                } else this.props.conferma(this.props.idStudente);
                            }
                        }}
                    >
                        Conferma
                    </Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>
                        Annulla
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
