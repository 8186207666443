import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Row, Col, Input, Label } from 'reactstrap';

export default class ModaleRinomina extends Component {

  render() {
    return (
      <Modal isOpen={this.props.attivo} >
        <ModalHeader>{this.props.titolo}</ModalHeader>
        <ModalBody>
          <Form action="" onSubmit={e => e.preventDefault()} className="form-horizontal">
            <Row>
              <Col xs="12" sm="12" md="12">
                <FormGroup>
                  <Label htmlFor='nome'>{this.props.messaggio}</Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={this.props.nome || ''}
                    onChange={this.props.changeNomeHandler.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.props.conferma()}>OK</Button>{' '}
          <Button color="warning" onClick={() => this.props.toggle()}>Annulla</Button>
        </ModalFooter>
      </Modal>
    )
  }

}