import React, { Component } from 'react';
import { Badge } from 'reactstrap';

export default class NotificaModifiche extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.nuovoDato ? (
                    <Badge color="success" className="ml-0 mb-1 p-2">
                        {this.props.indicazioneNuovo}
                    </Badge>
                ) : null}
                {this.props.datoModificato ? (
                    <Badge color="warning" className="ml-2 p-2">
                        {this.props.indicazioneModificato ? this.props.indicazioneModificato : 'Modifiche non salvate'}
                    </Badge>
                ) : null}
            </React.Fragment>
        );
    }
}
