import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class TooltipItem extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        if (this.props.points)
            return (
                <div
                    className={'dlg-tooltip-fade'}
                    style={{
                        position: 'absolute',
                        background: 'black',
                        color: 'white',
                        transform: 'translateX(0%)',
                        left: '100%',
                        borderRadius: 4,
                        padding: 12,
                        paddingBottom: 0,
                        width: 'max-content',
                        marginTop: 10,
                        opacity: 0
                    }}
                >
                    {this.props.content.split('\n').map(e => (
                        <>
                            <span style={{ display: 'flex', marginBottom: -5, fontSize: 15 }}>
                                {e
                                    .split(':')
                                    .map((el, i) => (i === 0 ? <b style={{ marginRight: 12 }}>{el + ''}</b> : el))}
                            </span>
                            <br />
                        </>
                    ))}
                    <div style={{ marginBottom: -25 }}></div>
                </div>
            );
        return (
            <Tooltip
                placement={this.props.placement}
                isOpen={this.state.tooltipOpen}
                style={{ textAlign: this.props.points ? 'left' : 'center', width: 500 }}
                innerClassName={this.props.points ? 'dlg-tooltip' : ''}
                target={this.props.objectId}
                fade={false}
                toggle={this.toggle}
            >
                {this.props.content}
            </Tooltip>
        );
    }
}

export default TooltipItem;
