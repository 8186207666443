import React, { useEffect } from 'react';
import withRouter from '../common/witthRouter';

function DetectChange(props) {
    // region variables
    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {
        let userData = {};
        if (localStorage.user) userData = JSON.parse(localStorage.user);
        if (
            props.callBack &&
            props.location.pathname !== '/' &&
            props.location.pathname !== '/login' &&
            props.location.pathname !== '/logout' &&
            (!userData.is_docente || props.isDocente)
        )
            props.callBack(props.location);
        else if (props.logoutCallback && props.location.pathname === '/logout') {
            props.logoutCallback(props.location);
        } else if (
            props.location.pathname === '/' ||
            props.location.pathname === '/login' ||
            props.location.pathname === '/logout'
        ) {
            if (props.authCallBack) props.authCallBack(props.location);
        }
    }, [props.location]);
    // endregion

    // region functions

    // endregion
    return <></>;
}
export default withRouter(DetectChange);
