import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ModalsDateComponents from './ModalsDateComponents';
import moment from 'moment';
import ModalSelectsComponents from './ModalSelectsComponents';
import { diff_hours } from '../../../services/commons';

export default function ModalAssignment({
    attivo,
    conferma,
    toggle,
    isDocente,
    userData,
    isAdmin,
    toggleAvviso,
    data,
    modifica,
    deleteElement,
    isStudente,
    isTutore,
    studentiTut
}) {
    // region variables
    const [state, setState] = useState({ start: new Date(), end: new Date(), duration: 1, note: '', argomenti: '' });
    const [dataSelects, setDataSelects] = useState({});
    const [interrogazione, setInterrogazione] = useState(false);
    // endregion
    let userdata = {};
    if (localStorage.user) {
        userdata = JSON.parse(localStorage.user);
    }
    // region lifeCycle
    // onStart
    useEffect(() => {
        let newState = { ...state };
        if (attivo && data?.isModify) {
            newState.duration = diff_hours(new Date(data.start), new Date(data.end));
            setInterrogazione(data.tipologia === 1);
            newState.note = data.note;
        }
        newState.start = data?.date || new Date();
        newState.argomenti = data?.argomenti || new Date();
        setState(newState);
    }, [data, data?.date]);
    useEffect(() => {
        setState({ ...state, end: moment(state.start).add(state.duration, 'hours').toDate() });
    }, [state.start, state.duration]);
    useEffect(() => {
        if (attivo && !data?.isModify) {
            setState({ start: data?.date || new Date(), end: new Date(), duration: 1, note: '', argomenti: '' });
        }
    }, [attivo]);
    // endregion
    // region functions
    const handleChangeHour = hour => {
        setState({
            ...state,
            start: moment(moment(state.start).format('YYYY-MM-DD') + ' ' + hour.target.value, 'YYYY-MM-DD HH').toDate()
        });
    };
    // endregion
    return (
        <Modal isOpen={attivo} size="lg">
            {data?.isModify ? (
                <ModalHeader>Modifica Evento</ModalHeader>
            ) : (
                <ModalHeader>Inserisci un nuovo Evento</ModalHeader>
            )}
            <ModalBody>
                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ModalsDateComponents
                            date={state.start}
                            handleChangeDataNuovoEvento={date => setState({ ...state, start: date })}
                            startOraNuovoEvento={moment(state.start).format('HH')}
                            durataNuovoEvento={state.duration}
                            endOraNuovoEvento={moment(state.end).format('HH')}
                            handleChangeDurataNuovoEvento={data => {
                                setState({ ...state, duration: data.target.value });
                                // setState({...state,duration: data})
                            }}
                            handleChangeOraInizioNuovoEvento={handleChangeHour}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ModalSelectsComponents
                            userData={userData}
                            isStudente={isStudente}
                            studentiTut={studentiTut}
                            isAdmin={isAdmin}
                            isDocente={isDocente}
                            setData={setDataSelects}
                            data={data}
                            isModify={data?.isModify}
                            attivo={attivo}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                        <FormGroup check className="checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="checkNonCodificato"
                                name="checkNonCodificato"
                                checked={interrogazione}
                                onChange={() => setInterrogazione(!interrogazione)}
                            />
                            <Label check className="form-check-label" htmlFor="checkNonCodificato">
                                Orale
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="6" xl="6" style={{ paddingLeft: 24 }}>
                        <FormGroup check className="checkbox">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id="checkNonCodificato"
                                name="checkNonCodificato"
                                checked={!interrogazione}
                                onChange={() => setInterrogazione(!interrogazione)}
                            />
                            <Label check className="form-check-label" htmlFor="checkNonCodificato">
                                Scritto
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <h5>Argomenti</h5>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="text"
                            rows={1}
                            value={state.argomenti}
                            onChange={arg => setState({ ...state, argomenti: arg.target.value })}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <h5>Note</h5>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="text"
                            rows={4}
                            value={state.note}
                            onChange={arg => setState({ ...state, note: arg.target.value })}
                        />
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color={'primary'}
                    onClick={() => {
                        let newObj = {
                            id_sede: dataSelects.id_sede || 8,
                            note: state.note,
                            data_planning: moment(state.start).format('YYYY-MM-DD'),
                            start_ora: moment(state.start).format('HH'),
                            start_min: moment(state.start).format('00'),
                            end_ora: moment(state.end).format('HH'),
                            end_min: moment(state.end).format('00'),
                            id_docente: dataSelects.docentiNuovoEvento,
                            id_studente: isStudente && !isTutore ? userdata.id : dataSelects.studentiNuovoEvento,
                            id_materia: dataSelects.materieNuovoEvento,
                            voto: dataSelects.voto,
                            argomenti: state.argomenti,
                            interrogazione: interrogazione
                        };
                        if (!state.argomenti) {
                            toggleAvviso('Attenzione', 'Devi inserire un argomento per continuare');
                            return;
                        }
                        if (!data?.isModify) conferma(newObj);
                        else {
                            modifica(newObj, data.ID);
                        }
                    }}
                >
                    {!data?.isModify ? 'Conferma' : 'Modifica'}
                </Button>
                {data?.isModify && (
                    <Button color="danger" onClick={() => deleteElement(data)}>
                        Elimina
                    </Button>
                )}
                <Button color="secondary" onClick={toggle}>
                    Annulla
                </Button>
            </ModalFooter>
        </Modal>
    );
}
