import React from 'react';
import ArchivioAllegati from './views/Allegati/ArchivioAllegati';
import PlanningAssignment from './views/Planning/PlanningAssignment';
import Pazienti from './views/Utenti/Pazienti';
import DocumentiStudenti from './views/DocumentiStudenti/ArchivioAllegati';
import DatiStudente from './views/Utenti/DatiStudente';
import EvaluationAdmin from './views/ValutazioniAdmin/EvaluationAdmin';
import Corsi from './views/Materie/Corsi';
import CompletaProfilo from './views/Utenti/CompletaProfilo';
import ChiusuraIstituto from './common/ChiusuraIstituto';

const Planning = React.lazy(() => import('./views/Planning/Planning'));
const PlanningMaster = React.lazy(() => import('./views/Planning/PlanningMaster'));
const PlanningPsicologo = React.lazy(() => import('./views/Planning/Psicologo/PlanningPsicologo'));
const Profilo = React.lazy(() => import('./views/Utenti/Profilo'));
const Sedi = React.lazy(() => import('./views/Sedi/Sedi'));
const Materie = React.lazy(() => import('./views/Materie/Materie'));
const Aule = React.lazy(() => import('./views/Aule/Aule'));
const AnagrAllegati = React.lazy(() => import('./views/AnagrAllegati/AnagrAllegati'));
const ElencoAllegati = React.lazy(() => import('./views/Allegati/ElencoAllegati'));
const Utenti = React.lazy(() => import('./views/Utenti/Utenti'));
const DatiSede = React.lazy(() => import('./views/Sedi/DatiSede'));
const DatiLezione = React.lazy(() => import('./views/Lezioni/DatiLezione'));
const DatiUtente = React.lazy(() => import('./views/Utenti/DatiUtente'));
const DatiPaziente = React.lazy(() => import('./views/Utenti/DatiPaziente'));
const pazienti = React.lazy(() => import('./views/Utenti/Pazienti'));
const Notification = React.lazy(() => import('./views/Notifiche/Notification'));
const SchedaStudenti = React.lazy(() => import('./views/Studenti/SchedaStudenti'));
const Logout = React.lazy(() => import('./containers/Auth/Logout/Logout'));
const LoginGoogle = React.lazy(() => import('./views/Pages/Login/LoginGoogle'));
const Land = React.lazy(() => import('./views/Land/Land'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = notification => {
    return [
        { path: '/land', pathPrincipale: '/land', name: 'Land', component: Land },
        { path: '/', pathPrincipale: '/', name: 'Land', component: Land },
        { path: '/planning', pathPrincipale: '/planning', name: 'Planning', component: Planning },
        { path: '/datistudente', pathPrincipale: '/datistudente', name: 'Dati studente', component: DatiStudente },
        {
            path: '/datistudente/:idUtente',
            pathPrincipale: '/datistudente',
            name: 'Dati studente',
            component: DatiStudente
        },
        {
            path: '/notification',
            pathPrincipale: '/notification',
            name: 'Notifiche',
            component: Notification
        },
        {
            path: '/valutazioniDoc',
            pathPrincipale: '/valutazioniDoc',
            name: 'Valutazioni Docente',
            component: EvaluationAdmin
        },
        {
            path: '/valutazioniAdmin',
            pathPrincipale: '/valutazioniAdmin',
            name: 'Valutazioni Admin',
            component: EvaluationAdmin
        },
        {
            path: '/planningmaster',
            pathPrincipale: '/planningmaster',
            name: 'Planning tipo',
            component: PlanningMaster
        },
        {
            path: '/planningassignment',
            pathPrincipale: '/planningassignment',
            name: 'Planning Assegni',
            component: PlanningAssignment
        },
        {
            path: '/chiusuraistituto',
            pathPrincipale: '/chiusuraistituto',
            name: 'Chiusura istituto',
            component: ChiusuraIstituto
        },
        { path: '/profilo', pathPrincipale: '/profilo', name: 'Profilo', component: Profilo },
        {
            path: '/completaprofilo',
            pathPrincipale: '/completaprofilo',
            name: 'Commpleta Profilo',
            component: CompletaProfilo
        },
        { path: '/pazienti', pathPrincipale: '/pazienti', name: 'Pazienti', component: Pazienti },
        { path: '/sedi', pathPrincipale: '/sedi', name: 'Anagrafica sedi', component: Sedi },
        {
            path: '/planningpsicologo',
            pathPrincipale: '/planningpsicologo',
            name: 'Planning psicologo',
            component: PlanningPsicologo
        },
        {
            path: '/datisede/:idsede',
            pathPrincipale: '/datisede',
            exact: true,
            name: 'Aggiunta/Modifica sede',
            component: DatiSede
        },
        { path: '/materie', pathPrincipale: '/materie', name: 'Anagrafica materie', component: Materie },
        { path: '/corsi', pathPrincipale: '/corsi', name: 'Anagrafica corsi', component: Corsi },
        { path: '/aule', pathPrincipale: '/aule', name: 'Anagrafica aule', component: Aule },
        {
            path: '/anagrAllegati',
            pathPrincipale: '/anagrAllegati',
            name: 'Anagrafica allegati',
            component: AnagrAllegati
        },
        { path: '/allegati', pathPrincipale: '/allegati', name: 'Gestione documenti', component: ElencoAllegati },
        {
            path: '/archivioAllegati',
            pathPrincipale: '/archivioAllegati',
            name: 'Archivio Allegati',
            component: ArchivioAllegati
        },
        {
            path: '/documentiStudenti',
            pathPrincipale: '/documentiStudenti',
            name: 'Archivio file',
            component: DocumentiStudenti
        },
        { path: '/utenti', name: 'Anagrafica utenti', pathPrincipale: '/utenti', component: Utenti },
        {
            path: '/datiutente/:idutente',
            pathPrincipale: '/datiutente',
            exact: true,
            name: 'Aggiunta/Modifica utente',
            component: DatiUtente
        },
        {
            path: '/datipaziente/:idutente',
            pathPrincipale: '/datipaziente',
            exact: true,
            name: 'Aggiunta/Modifica paziente',
            component: DatiPaziente
        },
        {
            path: '/schedastudenti/:idstudente',
            pathPrincipale: '/schedastudenti',
            exact: true,
            name: 'Scheda studente',
            component: SchedaStudenti
        },
        { path: '/datilezione', name: 'Dati lezione', pathPrincipale: '/datilezione', component: DatiLezione },
        { path: '/logout', name: 'Disconnetti', pathPrincipale: '/logout', component: Logout }
    ];
};

export default routes;
