//TODO SE SEI STUDENTE E CI STA USERID TI BUTTO FUORI

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import {
    Label,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Form,
    FormGroup,
    Input,
    FormText,
    Button,
    InputGroup
} from 'reactstrap';
import DateTimePicker from 'react-widgets/DatePicker';
import DropdownList from 'react-widgets/DropdownList';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../../shared/apiconfig';
import {
    updateObject,
    emailValidate,
    codFiscaleValidate,
    calcolaCodiceFiscale,
    convertiDataPerDB
} from '../../shared/utility';
import * as actions from '../../store/actions/'; // Redux
import RichiestaConferma from '../../common/RichiestaConferma';
import Avviso from '../../common/Avviso';
import 'react-widgets/styles.css';
import withRouter from '../../common/witthRouter';
import PartiDatiStudente from './Components/PartiDatiStudente';
import ModalSpinner from '../../common/ModalSpinner';
import moment from 'moment';
import { getAge } from '../../common/services';
//se admin id_studente=
class DatiUtente extends Component {
    constructor(props) {
        super(props);
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.state = {
            utente: {
                scuola: '',
                classe: ''
            },
            avviso: {
                attivo: false,
                titolo: '',
                messaggio: ''
            },
            richiestaConferma: {
                attiva: false,
                titolo: '',
                messaggio: '',
                azione: null // Vedi confermaAzione()
            },
            richiestaConfermaAttivazione: {
                attiva: false,
                titolo: '',
                messaggio: '',
                azione: null // Vedi confermaAzione()
            },
            sceltaComuneAttiva: false,
            sceltaComuneNascitaAttiva: false,
            dataDiNascitaAperto: false,
            errori: {
                erroreDataNascita: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire la data di nascita.'
                },
                erroreCognome: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il cognome.'
                },
                erroreNome: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il nome.'
                },
                scuola: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il nome della scuola'
                },
                classe: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire la classe'
                },
                erroreCodiceFiscaleAssente: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il codice fiscale.'
                },
                erroreCodiceFiscaleNonValido: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Codice fiscale non valido.'
                },
                erroreCodiceFiscaleNonCoerente: {
                    errore: false,
                    bloccante: false,
                    descrizione: 'Codice fiscale non coerente.'
                },
                erroreEmailAssente: {
                    errore: false,
                    bloccante: true,
                    descrizione: "Inserire l'email."
                },
                erroreComune: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il comune'
                },
                erroreComuneNascita: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il comune di nascita'
                },
                erroreSesso: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Inserire il sesso'
                },
                erroreEmailNonValida: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Email non valida.'
                },
                erroreIndirizzo: {
                    errore: false,
                    bloccante: true,
                    descrizione: "Inserire l'Indirizzo."
                },
                erroreCap: {
                    errore: false,
                    bloccante: true,
                    descrizione: 'Cap non valido'
                }
            },
            modifica: false,
            docenti: [],
            nuovaDataNascita: null,
            richiestaConfermaSalvataggio: false // In caso di errori non bloccanti
        };

        this.inserisciCodiceFiscale = this.inserisciCodiceFiscale.bind(this);
        this.verificaCodiceFiscale = this.verificaCodiceFiscale.bind(this);
        this.getDataWizard = this.getDataWizard.bind(this);
        this.controlloErrori = this.controlloErrori.bind(this);
        this.confarmaAttivazioneDisattivazioneUtente = this.confarmaAttivazioneDisattivazioneUtente.bind(this);
        this.setWindowDimensions = this.setWindowDimensions.bind(this);
        this.insertSectionData = this.insertSectionData.bind(this);
        this.userId = this.props.match?.params?.idUtente || 0;
    }
    // cardRef=createRef();
    userId = this.props.match?.params?.idUtente || 0;
    setWindowDimensions() {
        this.setState({ dimensions: { height: window.innerHeight, width: window.innerWidth } });
    }
    confarmaAttivazioneDisattivazioneUtente = user => {
        let newStato = user.abilitato ? 0 : 1;
        axios
            .patch(apiconfig().baseURL + 'studente/abilitaAccesso', { id_studente: user.id, abilitato: newStato })
            .then(res => {
                if (!res.data.errors) {
                    this.setState(
                        {
                            avviso: {
                                attivo: true,
                                titolo: 'Successo',
                                messaggio: 'Utente ' + (newStato ? 'Abilitato' : 'Disabilitato') + ' con successo'
                            }
                        },
                        () => window.location.reload()
                    );
                } else
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'Errore',
                            messaggio: 'Errore imprevisto riprovare piu tardi'
                        }
                    });
            })
            .catch(err => {
                if (err.response.status && err.response.status == 401) {
                    this.props.navigate('/logout');
                } else
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'Errore',
                            messaggio: 'Errore imprevisto riprovare piu tardi'
                        }
                    });
            });
    };
    componentDidMount() {
        this.userId = this.props.match?.params?.idUtente || 0;
        this.props.setTitle('Completa registrazione');
        this.setState({ loading: true });
        this.getDataWizard(5).then(res => {
            if (res.fileUploaded) {
                this.caricaDati();
                this.setState({ section: 4, docUploaded: res.fileUploaded, loading: false });
            } else {
                this.getDataWizard(4).then(res => {
                    if (res.data) {
                        this.caricaDati();
                        this.setState({ section: 3, loading: false });
                    }
                    if (!res.data) {
                        if (!res.data) {
                            this.getDataWizard(2).then(res => {
                                if (!res.data) {
                                    this.caricaDati(true);
                                } else {
                                    this.caricaDati();
                                    this.setState({ section: 1 });
                                }

                                this.setState({ loading: false });
                            });
                        }
                    }
                });
            }
        });
        if (this.userId)
            axios.get(apiconfig().baseURL + 'users/' + this.userId).then(res => {
                this.setState({ selectedUser: res.data });
            });

        addAuthToken();
        if (this.props.canSee?.data?.file) {
            this.setState({ section: 4, docUploaded: this.props.canSee?.data?.file });
        } else {
            this.setState({ section: 0, docUploaded: this.props.canSee?.data?.file });
        }
        // if (this.props.canSee?.data?.file) {
        //     this.setState({ section: 4, docUploaded: this.props.canSee?.data?.file });
        // } else {
        //     addAuthToken();
        //     this.setState({ section: 0, docUploaded: this.props.canSee?.data?.file });
        // }
        // addAuthToken();
        // this.caricaDati();
        window.addEventListener('resize', this.setWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setWindowDimensions);
    }
    getDataWizard(section) {
        addAuthToken();
        return new Promise(resolve =>
            axios
                .get(apiconfig().baseURL + `wizard/getStepData?step=${section}&id_studente=${this.userId}`)
                .then(res => {
                    resolve(res.data);
                })
        );
    }
    user = JSON.parse(localStorage.getItem('user'));
    insertSectionData() {
        switch (this.state.section) {
            case undefined:
            case 0:
                const bodyData = {
                    indirizzo: this.state.utente?.indirizzo,
                    cap: this.state.utente?.cap,
                    data_nascita: this.state.utente?.data_nascita,
                    email: this.state.utente?.email,
                    id_comune: this.state.utente?.id_comune || this.state.utente?.idComune,
                    comune_nascita: this.state.utente.comune_nascita,
                    sesso: this.state.utente?.sesso,
                    codice_fiscale: this.state.utente?.codice_fiscale,
                    scuola: this.state.utente?.scuola,
                    referente_dsa: this.state.utente?.dsa,
                    classe: this.state.utente.classe,
                    sezione: this.state.utente?.sezione,
                    prov_nascita: this.state.utente?.prov_nascita,
                    comune: this.state.utente?.comune
                };
                if (this.userId) bodyData.id_studente = this.userId;
                axios
                    .post(apiconfig().baseURL + 'wizard/step1', bodyData)
                    .then(res => {
                        if (!res.data.errors) {
                            this.setState({
                                avviso: {
                                    attivo: true,
                                    messaggio: 'Informazioni inserite con successo',
                                    titolo: 'Successo'
                                }
                            });
                            this.setState({ section: 1 });
                        } else
                            this.setState({
                                avviso: { attivo: true, messaggio: JSON.stringify(res.data.errors), titolo: 'Errore' }
                            });
                    })
                    .catch(err => {
                        this.setState({
                            avviso: { attivo: true, messaggio: 'Si è verificato un errore', titolo: 'Errore' }
                        });
                    });
                break;
            case 1:
                const bodyData2 = {
                    posizione: this.state.section,
                    tipo_genitore: this.state.sectionData[this.state.section].tipo_genitore1,
                    sesso: this.state.sectionData[this.state.section].sesso_genitore1,
                    nome: this.state.sectionData[this.state.section].nome_genitore1,
                    cognome: this.state.sectionData[this.state.section].cognome_genitore1,
                    email: this.state.sectionData[this.state.section].mail_genitore1,
                    indirizzo: this.state.sectionData[this.state.section].via_genitore1,
                    id_comune:
                        this.state.sectionData[this.state.section].citta_genitore1?.id ||
                        this.state.sectionData[this.state.section].id_comune_genitore1,
                    data_nascita: moment(this.state.sectionData[this.state.section].data_di_nascita_genitore1).format(
                        'YYYY-MM-DD'
                    ),
                    codice_fiscale: this.state.sectionData[this.state.section].codice_fiscale_genitore1,
                    id_comune_nascita:
                        this.state.sectionData[this.state.section].luogo_di_nascita_genitore1?.id ||
                        this.state.sectionData[this.state.section].id_comune_nascita_genitore1,
                    cap: this.state.sectionData[this.state.section].cap_genitore1,
                    telefono: this.state.sectionData[this.state.section].telefono_genitore1
                };
                if (this.userId) bodyData2.id_studente = this.userId;
                axios
                    .post(apiconfig().baseURL + 'wizard/step2_3', bodyData2)
                    .then(res => {
                        if (!res.data.errors) {
                            this.setState({
                                avviso: {
                                    attivo: true,
                                    messaggio: 'Informazioni inserite con successo',
                                    titolo: 'Successo'
                                }
                            });
                            this.setState({ section: this.state.section + 1 });
                        } else
                            this.setState({
                                avviso: { attivo: true, messaggio: JSON.stringify(res.data.errors), titolo: 'Errore' }
                            });
                    })
                    .catch(err => {
                        this.setState({
                            avviso: { attivo: true, messaggio: 'Si è verificato un errore', titolo: 'Errore' }
                        });
                    });

                break;
            case 2:
                const bodyData3 = {
                    posizione: this.state.section,
                    tipo_genitore: this.state.sectionData[this.state.section].tipo_genitore2,
                    sesso: this.state.sectionData[this.state.section].sesso_genitore2,
                    nome: this.state.sectionData[this.state.section].nome_genitore2,
                    cognome: this.state.sectionData[this.state.section].cognome_genitore2,
                    email: this.state.sectionData[this.state.section].mail_genitore2,
                    indirizzo: this.state.sectionData[this.state.section].via_genitore2,
                    id_comune: this.state.sectionData[this.state.section].citta_genitore2?.id,
                    data_nascita: moment(this.state.sectionData[this.state.section].data_di_nascita_genitore2).format(
                        'YYYY-MM-DD'
                    ),
                    codice_fiscale: this.state.sectionData[this.state.section].codice_fiscale_genitore2,
                    id_comune_nascita: this.state.sectionData[this.state.section].luogo_di_nascita_genitore2?.id,
                    cap: this.state.sectionData[this.state.section].cap_genitore2,
                    telefono: this.state.sectionData[this.state.section].telefono_genitore2
                };

                if (this.userId) bodyData3.id_studente = this.userId;
                axios
                    .post(apiconfig().baseURL + 'wizard/step2_3', bodyData3)
                    .then(res => {
                        if (!res.data.errors) {
                            this.setState({
                                avviso: {
                                    attivo: true,
                                    messaggio: 'Informazioni inserite con successo',
                                    titolo: 'Successo'
                                }
                            });
                            this.setState({ section: this.state.section + 1 });
                        } else
                            this.setState({
                                avviso: { attivo: true, messaggio: JSON.stringify(res.data.errors), titolo: 'Errore' }
                            });
                    })
                    .catch(err => {
                        this.setState({
                            avviso: { attivo: true, messaggio: 'Si è verificato un errore', titolo: 'Errore' }
                        });
                    });

                break;
            case 3:
                const bodyData4 = { ...this.state.sectionData[3] };
                bodyData4.autorizzazione_uscita = bodyData4.autorizzazione_uscita ? 1 : 0;
                if (this.userId) bodyData4.id_studente = this.userId;
                if (!bodyData4.n_lezioni_settimanali) bodyData4.n_lezioni_settimanali = 2;
                axios.post(apiconfig().baseURL + 'wizard/step4', bodyData4).then(res => {
                    if (!res.data.errors) {
                        this.setState({
                            avviso: {
                                attivo: true,
                                messaggio: 'Informazioni inserite con successo',
                                titolo: 'Successo'
                            }
                        });
                        this.setState({ section: this.state.section + 1 });
                    } else
                        this.setState({
                            avviso: { attivo: true, messaggio: JSON.stringify(res.data.errors), titolo: 'Errore' }
                        });
                });
                break;
        }
    }
    caricaDati(changeSection) {
        this.setState({ loading: true });
        axios.get(apiconfig().baseURL + 'scuole').then(res => {
            this.setState({ scuole: res.data });
        });
        let params = '';
        if (this.userId) params = '&id_studente=' + this.userId;
        addAuthToken();
        axios.get(apiconfig().baseURL + 'wizard/getStepData?step=1' + params).then(response => {
            const utente = updateObject(response.data.studente, { dsa: response.data.studente.referente_dsa });
            utente.nuovaDataNascita = utente.data_nascita ? new Date(utente.data_nascita) : null;
            utente.idComune = response.data.comune_residenza?.id || '';
            utente.idComuneNascita = response.data.comune_residenza?.id || '';
            utente.idComune_nascita = response.data.comune_nascita?.id || '';
            utente.prov_nascita = response.data.comune_nascita?.prov || '';
            this.setState(
                {
                    utente: utente,
                    nuovaDataNascita: utente.nuovaDataNascita,
                    comuni_nascita: [],
                    comuni: []
                },
                () => this.controlloErrori(changeSection, true)
            );
            if (utente && utente.comune) {
                axios.get(apiconfig().baseURL + 'comuni?testoRicerca=' + utente.comune.slice(0, 3)).then(res => {
                    this.setState({ comuni: res.data });
                    if (utente.comune_nascita === utente.comune)
                        this.setState({
                            comuni_nascita: res.data,
                            loading: false
                        });
                });
            } else this.setState({ loading: false });

            if (utente && utente.comune_nascita && utente.comune_nascita !== utente.comune) {
                axios
                    .get(apiconfig().baseURL + 'comuni?testoRicerca=' + utente.comune_nascita.slice(0, 3))
                    .then(res => {
                        this.setState({
                            comuni_nascita: res.data,
                            loading: false
                        });
                    });
            }
        });
    }

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: ''
        };
        this.setState({
            avviso: modAvviso
        });
    };

    toggleRichiestaConferma = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConferma, {
            attiva: false,
            titolo: '',
            messaggio: '',
            conferma: risp === 1
        });
        this.setState(
            {
                richiestaConferma: modRichiestaConferma
            },
            this.confermaAzione
        );
    };
    toggleRichiestaConfermaAttivazione = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConfermaAttivazione || {}, {
            attiva: false,
            titolo: '',
            messaggio: '',
            conferma: risp
        });
        this.setState(
            {
                richiestaConfermaAttivazione: modRichiestaConferma
            },
            () => {
                if (this.state.richiestaConfermaAttivazione?.conferma === 1) {
                    this.confarmaAttivazioneDisattivazioneUtente(this.state.selectedUser);
                }
            }
        );
    };

    confermaAzione = () => {
        if (this.state.richiestaConferma.conferma) {
            const dati = this.state.richiestaConferma.azione.split('-'); // tipo utente - tipo dato - valore - 1=elimina | 2=sostituisci
            if (dati[3] === '1') {
                this.confermaRemoveDatoProfiloHandler(dati[0], dati[1], dati[2]);
            } else if (dati[3] === '2') {
                this.confermaAddDatoProfiloHandler(dati[0], dati[1]);
            } else if (dati[3] === '11') {
                this.setState(
                    {
                        richiestaConfermaSalvataggio: true
                    },
                    this.saveClickHandler
                );
            } else if (dati[3] === '20') {
                this.confermaResetPw();
            } else if (dati[3] === '25') {
                this.confermaRemoveAllegatoUtenteHandler(dati[0]);
            }
        }
    };

    inserisciCodiceFiscale() {
        let codiceComune =
            this.state.comuni_nascita?.find(e => e.comune === this.state.utente.comune_nascita)?.codice ||
            this.state.utente.codice_comune_nascita;
        if (
            this.state.utente.data_nascita === null ||
            this.state.utente.data_nascita === '' ||
            codiceComune === null ||
            codiceComune === '' ||
            this.state.utente.sesso === null ||
            this.state.utente.sesso === '' ||
            this.state.utente.cognome === null ||
            this.state.utente.cognome === '' ||
            this.state.utente.nome === null ||
            this.state.utente.nome === ''
        ) {
            this.controlloErrori();
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'Operazione non possibile',
                    messaggio:
                        'Per il calcolo del codice fiscale occorre inserire cognome, nome, data di nascita, comune di nascita e sesso'
                }
            });
        } else {
            const codiceFiscaleCalcolato = calcolaCodiceFiscale(
                convertiDataPerDB(this.state.utente.data_nascita),
                codiceComune,
                this.state.utente.sesso,
                this.state.utente.cognome,
                this.state.utente.nome
            );
            const nuovoUtente = updateObject(this.state.utente, {
                codice_fiscale: codiceFiscaleCalcolato
            });
            const errori = updateObject(this.state.errori, {});
            errori.erroreCodiceFiscaleAssente.errore = false;
            errori.erroreCodiceFiscaleNonValido.errore = false;
            errori.erroreCodiceFiscaleNonCoerente.errore = false;
            this.setState({
                utente: nuovoUtente,
                errori: errori
            });
        }
    }

    verificaCodiceFiscale(didMount, isError) {
        if (
            this.state.utente.codice_fiscale === null ||
            this.state.utente.codice_fiscale === '' ||
            this.state.utente.data_nascita === null ||
            this.state.utente.data_nascita === '' ||
            this.state.utente.codice_comune_nascita === null ||
            this.state.utente.codice_comune_nascita === '' ||
            this.state.utente.sesso === null ||
            this.state.utente.sesso === '' ||
            this.state.utente.cognome === null ||
            this.state.utente.cognome === '' ||
            this.state.utente.nome === null ||
            this.state.utente.nome === ''
        ) {
            this.setState({
                erroreCodiceFiscaleNonCoerente: updateObject(this.state.errori.erroreCodiceFiscaleNonCoerente, {
                    errore: false
                })
            });
        } else {
            const verifica =
                this.state.utente.codice_fiscale ===
                calcolaCodiceFiscale(
                    convertiDataPerDB(this.state.utente.data_nascita),
                    this.state.utente.codice_comune_nascita,
                    this.state.utente.sesso,
                    this.state.utente.cognome,
                    this.state.utente.nome
                );
            const errori = updateObject(this.state.errori, {});
            errori.erroreCodiceFiscaleNonCoerente.errore = !verifica;
            this.setState({
                errori: errori
            });
        }
        this.setState({ loading2: true }, () => {
            if (didMount) {
                let error = isError || false;
                Object.keys(this.state.errori).forEach(key => {
                    error = this.state.errori[key].errore || error;
                });
                if (!error) this.setState({ section: 1 });
            }
        });
    }

    handleChangeDataNascita(val) {
        const nuovoUtente = updateObject(this.state.utente, {
            data_nascita: convertiDataPerDB(val)
        });
        this.setState(
            {
                utente: nuovoUtente,
                nuovaDataNascita: val,
                modifica: true
            },
            () => {
                this.verificaCodiceFiscale();
                this.controlloErrori();
            }
        );
    }

    controlloErrori(didMount, noState) {
        const errori = JSON.parse(JSON.stringify({ ...this.state.errori }));
        errori.classe.errore = this.state.utente.classe ? (this.state.utente.classe + '').trim() === '' : true;
        errori.scuola.errore = this.state.utente.scuola ? (this.state.utente.scuola + '').trim() === '' : true;
        errori.erroreCognome.errore = this.state.utente.cognome ? this.state.utente.cognome.trim() === '' : true;
        errori.erroreDataNascita.errore = this.state.utente.data_nascita
            ? (this.state.utente.data_nascita + '').trim() === ''
            : true;
        errori.erroreNome.errore = this.state.utente.nome ? this.state.utente.nome.trim() === '' : true;
        errori.erroreIndirizzo.errore = this.state.utente.indirizzo ? this.state.utente.indirizzo.trim() === '' : true;
        errori.erroreCap.errore = this.state.utente.cap ? this.state.utente.cap.trim() === '' : true;
        errori.erroreComune.errore = !this.state.utente.idComune;
        errori.erroreComuneNascita.errore = !this.state.utente.idComune_nascita;
        errori.erroreEmailAssente.errore = this.state.utente.email ? this.state.utente.email.trim() === '' : true;
        errori.erroreSesso.errore = this.state.utente.sesso ? this.state.utente.sesso.trim() === '' : true;
        errori.erroreEmailNonValida.errore =
            this.state.utente.email && this.state.utente.email !== '' && !emailValidate(this.state.utente.email);
        errori.erroreCodiceFiscaleAssente.errore = this.state.utente.codice_fiscale
            ? this.state.utente.codice_fiscale.trim() === ''
            : true;
        errori.erroreCodiceFiscaleNonValido.errore =
            this.state.utente.codice_fiscale &&
            this.state.utente.codice_fiscale !== '' &&
            !codFiscaleValidate(this.state.utente.codice_fiscale);
        let error = false;
        Object.keys(errori).forEach(key => (error = error || errori[key].errore));
        if (noState) {
            this.verificaCodiceFiscale(didMount, error);
        } else
            this.setState(
                {
                    errori: errori,
                    modifica: true
                },
                () => this.verificaCodiceFiscale(didMount)
            );
        return errori;
    }

    changeInputHandler(field, e) {
        const nuovoUtente = this.state.utente;
        nuovoUtente[field] = e.target.value;
        this.setState(
            {
                utente: nuovoUtente,
                modifica: true
            },
            this.controlloErrori
        );
    }

    verificaErrori() {
        let errori = this.controlloErrori();
        let elencoErrori = [];
        // Errori bloccanti
        Object.keys(errori).forEach(key => {
            if (errori[key].errore && errori[key].bloccante) elencoErrori.push(errori[key].descrizione);
        });
        if (elencoErrori.length > 0) {
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'Errore: salvataggio impossibile',
                    messaggio: 'Rilevati i seguenti errori: ' + elencoErrori.join(' - ')
                }
            });
            return true;
        }
        // Errori non bloccanti
        Object.keys(this.state.errori).forEach(key => {
            if (this.state.errori[key].errore && !this.state.errori[key].bloccante)
                elencoErrori.push(this.state.errori[key].descrizione);
        });
        if (elencoErrori.length > 0 && !this.state.richiestaConfermaSalvataggio) {
            this.setState({
                richiestaConferma: {
                    attiva: true,
                    titolo: 'Attenzione: rilevato un problema',
                    messaggio:
                        'Rilevate le seguenti incongruenze: ' + elencoErrori.join(' - ') + ' Proseguire comunque?',
                    azione: '0-0-0-11'
                }
            });
            return true;
        }
        this.setState({
            richiestaConfermaSalvataggio: false
        });
        return false;
    }

    renderStudentsData() {
        const style = {
            borderColor: 'red'
        };
        return (
            <>
                {' '}
                <h3>Dati studente</h3>
                <Row>
                    <Col xs="12" sm="6" md="6">
                        <FormGroup>
                            <Label htmlFor="cognomeUtente">Cognome</Label>
                            <Input
                                type="text"
                                id="cognomeUtente"
                                name="cognomeUtente"
                                className={this.state.errori.erroreCognome?.errore ? 'border-red-important' : ''}
                                placeholder="Cognome utente"
                                value={this.state.utente.cognome || ''}
                                onChange={this.changeInputHandler.bind(this, 'cognome')}
                            />
                            {this.state.errori.erroreCognome.errore ? (
                                <FormText color="danger">{this.state.errori.erroreCognome.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="6" md="6">
                        <FormGroup>
                            <Label htmlFor="nomeUtente">Nome</Label>
                            <Input
                                type="text"
                                id="nomeUtente"
                                name="nomeUtente"
                                className={this.state.errori.erroreNome?.errore ? 'border-red-important' : ''}
                                placeholder="Nome utente"
                                value={this.state.utente.nome || ''}
                                onChange={this.changeInputHandler.bind(this, 'nome')}
                            />
                            {this.state.errori.erroreNome.errore ? (
                                <FormText color="danger">{this.state.errori.erroreNome.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="6" md="6">
                        <FormGroup>
                            <Label htmlFor="indirizzo">Indirizzo</Label>
                            <Input
                                type="text"
                                id="indirizzo"
                                name="indirizzo"
                                className={this.state.errori.erroreIndirizzo?.errore ? 'border-red-important' : ''}
                                placeholder="Indirizzo"
                                value={this.state.utente.indirizzo || ''}
                                onChange={this.changeInputHandler.bind(this, 'indirizzo')}
                            />
                            {this.state.errori.erroreIndirizzo.errore ? (
                                <FormText color="danger">{this.state.errori.erroreIndirizzo.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="3" sm="3" md="3">
                        <FormGroup>
                            <Label htmlFor="cap">CAP</Label>
                            <Input
                                type="number"
                                id="cap"
                                name="cap"
                                className={this.state.errori.erroreCap?.errore ? 'border-red-important' : ''}
                                placeholder="CAP"
                                value={this.state.utente.cap || ''}
                                onChange={this.changeInputHandler.bind(this, 'cap')}
                            />
                            {this.state.errori.erroreCap.errore ? (
                                <FormText color="danger">{this.state.errori.erroreCap.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="9" sm="3" md="3">
                        <FormGroup>
                            <Label htmlFor="date-input" className="mr-1">
                                Data di nascita
                            </Label>
                            <DateTimePicker
                                id="date-input"
                                time={false}
                                className={this.state.errori.erroreDataNascita?.errore ? 'border-red-important' : ''}
                                value={this.state.nuovaDataNascita}
                                placeholder={'DD/MM/YYYY'}
                                onSelect={ev => this.handleChangeDataNascita(ev)}
                                onToggle={ev => {
                                    this.setState({ dataDiNascitaAperto: ev });
                                }}
                                calendarProps={{}}
                                inputProps={{
                                    onBlur: event => {
                                        if (!this.state.dataDiNascitaAperto)
                                            this.handleChangeDataNascita(
                                                moment(event.target.value, 'DD/MM/YYYY').toDate()
                                            );
                                    }
                                }}
                                format={'dd/MM/yyyy'}
                                // onChange={(val, label, reason) => {
                                //     console.log(label);
                                //     this.handleChangeDataNascita(moment(label, 'DD/MM/YYYY').toDate());
                                // }}
                            />
                            {this.state.errori.erroreDataNascita?.errore ? (
                                <FormText color="danger">{this.state.errori.erroreDataNascita.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>

                    <Col xs="12" sm="6" md="6">
                        <FormGroup>
                            <Label htmlFor="email">Email</Label>
                            <Input
                                className={
                                    this.state.errori.erroreEmailAssente?.errore ||
                                    this.state.errori.erroreEmailNonValida.errore
                                        ? 'border-red-important'
                                        : ''
                                }
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={this.state.utente.email || ''}
                                onChange={this.changeInputHandler.bind(this, 'email')}
                            />
                            {this.state.errori.erroreEmailAssente.errore && !this.props.patient ? (
                                <FormText color="danger">{this.state.errori.erroreEmailAssente.descrizione}</FormText>
                            ) : null}
                            {this.state.errori.erroreEmailNonValida.errore ? (
                                <FormText color="danger">{this.state.errori.erroreEmailNonValida.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="6" md="6">
                        <FormGroup>
                            <Label htmlFor="comune">Comune</Label>
                            <DropdownList
                                filter
                                busy={this.state.ricercaComuneInCorso}
                                className={this.state.errori.erroreComune?.errore ? 'border-red-important' : ''}
                                data={this.state.comuni}
                                valueField="id"
                                textField="comune"
                                messages={{
                                    emptyFilter: 'Nessun risultato trovato',
                                    emptyList: 'Inserisci almeno tre caratteri'
                                }}
                                toggle={console.info}
                                // renderValue={item => {
                                // return option.comune + option.prov;
                                renderListItem={item => {
                                    let option = item.item;
                                    return (
                                        <span key={'comune' + option.id}>
                                            {option.comune} ({option.prov})
                                        </span>
                                    );
                                }}
                                value={this.state.utente.comune !== 0 ? this.state.utente.comune : null}
                                onSearch={val => {
                                    if (val.length === 3) {
                                        this.setState({
                                            ricercaComuneInCorso: true
                                        });
                                        axios.get(apiconfig().baseURL + 'comuni?testoRicerca=' + val).then(res => {
                                            this.setState({ comuni: res.data, ricercaComuneInCorso: false });
                                        });
                                    }
                                }}
                                onToggle={val => console.info(val)}
                                onChange={val => {
                                    let utente2 = { ...this.state.utente };
                                    utente2.comune = val.comune;
                                    utente2.idComune = val.id;
                                    this.setState({ utente: utente2 }, this.controlloErrori);
                                }}
                            />
                        </FormGroup>
                        {this.state.errori.erroreComune.errore ? (
                            <FormText style={{ marginTop: -12 }} color="danger">
                                {this.state.errori.erroreComune.descrizione}
                            </FormText>
                        ) : null}
                    </Col>
                    <Col xs="12" sm="12" md="12">
                        <FormGroup>
                            <Label htmlFor="comune">Comune di nascita</Label>
                            <DropdownList
                                filter
                                className={this.state.errori.erroreComuneNascita?.errore ? 'border-red-important' : ''}
                                busy={this.state.ricercaComune_nascitaInCorso}
                                data={this.state.comuni_nascita}
                                onToggle={val => console.info(val)}
                                messages={{
                                    emptyFilter: 'Nessun risultato trovato',
                                    emptyList: 'Inserisci almeno tre caratteri'
                                }}
                                valueField="id"
                                textField="comune"
                                toggle={console.info}
                                renderListItem={item => {
                                    let option = item.item;
                                    return (
                                        <span key={'nascita' + option.id}>
                                            {option.comune} ({option.prov})
                                        </span>
                                    );
                                }}
                                value={this.state.utente.comune_nascita !== 0 ? this.state.utente.comune_nascita : null}
                                onSearch={val => {
                                    if (val.length === 3) {
                                        this.setState({
                                            ricercaComune_nascitaInCorso: true
                                        });
                                        axios.get(apiconfig().baseURL + 'comuni?testoRicerca=' + val).then(res => {
                                            this.setState({
                                                comuni_nascita: res.data,
                                                ricercaComune_nascitaInCorso: false
                                            });
                                        });
                                    }
                                    /*comuni?testoRicerca=asd*/
                                }}
                                onChange={val => {
                                    let utente2 = { ...this.state.utente };
                                    utente2.comune_nascita = val.comune;
                                    utente2.idComune_nascita = val.id;
                                    utente2.idComuneNascita = val.id;
                                    utente2.prov_nascita = val.prov;
                                    utente2.codice_comune_nascita = val.codice;
                                    this.setState({ utente: utente2 }, this.controlloErrori);
                                }}
                            />
                            {this.state.errori.erroreComuneNascita.errore ? (
                                <FormText style={{ marginTop: -0 }} color="danger">
                                    {this.state.errori.erroreComuneNascita.descrizione}
                                </FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="4" sm="4" md="4">
                        <FormGroup>
                            <Label htmlFor="sesso">Sesso</Label>
                            <Input
                                className={this.state.errori.erroreSesso?.errore ? 'border-red-important' : ''}
                                type="select"
                                name="selectSesso"
                                id="selectSesso"
                                value={this.state.utente.sesso}
                                onChange={this.changeInputHandler.bind(this, 'sesso')}
                            >
                                <option value=""> </option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            </Input>
                            {this.state.errori.erroreSesso.errore ? (
                                <FormText style={{ marginTop: -0 }} color="danger">
                                    {this.state.errori.erroreSesso.descrizione}
                                </FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="8" sm="8" md="8">
                        <FormGroup>
                            <Label htmlFor="codice_fiscale">Codice fiscale</Label>
                            <InputGroup>
                                <Input
                                    disabled
                                    type="text"
                                    name="codice_fiscale"
                                    style={
                                        this.state.errori.erroreCodiceFiscaleNonValido.errore ||
                                        (this.state.errori.erroreCodiceFiscaleAssente.errore && !this.props.patient) ||
                                        this.state.errori.erroreCodiceFiscaleNonCoerente.error
                                            ? style
                                            : null
                                    }
                                    id="codice_fiscale"
                                    value={this.state.utente.codice_fiscale || ''}
                                    onChange={this.changeInputHandler.bind(this, 'codice_fiscale')}
                                />
                                <Button type="button" color="primary" onClick={this.inserisciCodiceFiscale}>
                                    Calcola
                                </Button>
                            </InputGroup>
                            {this.state.errori.erroreCodiceFiscaleAssente.errore && !this.props.patient ? (
                                <FormText color="danger">
                                    {this.state.errori.erroreCodiceFiscaleAssente.descrizione}
                                </FormText>
                            ) : null}
                            {this.state.errori.erroreCodiceFiscaleNonValido.errore ? (
                                <FormText color="danger">
                                    {this.state.errori.erroreCodiceFiscaleNonValido.descrizione}
                                </FormText>
                            ) : null}
                            {!this.state.errori.erroreCodiceFiscaleNonValido.errore &&
                            this.state.errori.erroreCodiceFiscaleNonCoerente.errore ? (
                                <FormText color="danger">
                                    {this.state.errori.erroreCodiceFiscaleNonCoerente.descrizione}
                                </FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="4" md="3">
                        <FormGroup>
                            <Label htmlFor="email">Nome scuola</Label>
                            <Input
                                type="select"
                                style={this.state.errori.scuola.errore ? style : null}
                                id="scuola"
                                name="scuola"
                                placeholder="Nome scuola"
                                value={this.state.utente.scuola || ''}
                                onChange={this.changeInputHandler.bind(this, 'scuola')}
                            >
                                <option value=""></option>
                                {this.state.scuole?.length > 0 &&
                                    this.state.scuole.map(e => <option value={e.id}>{e.nome}</option>)}
                            </Input>
                            {this.state.errori.scuola.errore ? (
                                <FormText color="danger">{this.state.errori.scuola.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="4" md="3">
                        <FormGroup>
                            <Label htmlFor="comune">Referente DSA</Label>
                            <Input
                                type="email"
                                id="dsa"
                                name="dsa"
                                placeholder="Referente DSA"
                                value={this.state.utente.dsa || ''}
                                onChange={this.changeInputHandler.bind(this, 'dsa')}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="4" md="3">
                        <FormGroup>
                            <Label htmlFor="comune">Classe</Label>
                            <Input
                                type="select"
                                style={this.state.errori.classe.errore ? style : null}
                                id="classe"
                                name="classe"
                                placeholder="Classe"
                                value={this.state.utente.classe || ''}
                                onChange={this.changeInputHandler.bind(this, 'classe')}
                            >
                                <option value={''}></option>
                                {new Array(6).fill(0).map((e, i) => (
                                    <option value={i + 1}>{i + 1}</option>
                                ))}
                            </Input>
                            {this.state.errori.classe.errore ? (
                                <FormText color="danger">{this.state.errori.classe.descrizione}</FormText>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="4" md="3">
                        <FormGroup>
                            <Label htmlFor="comune">Sezione</Label>
                            <Input
                                id="sezione"
                                name="sezione"
                                placeholder="Sezione"
                                value={this.state.utente.sezione || ''}
                                onChange={this.changeInputHandler.bind(this, 'sezione')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }
    render() {
        let maggiorenne = false;
        if (this.state?.utente?.data_nascita && moment(this.state.utente.data_nascita).isValid())
            maggiorenne = getAge(this.state.utente.data_nascita) > 17;

        return (
            <div>
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attiva}
                    toggle={this.toggleRichiestaConferma}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConfermaAttivazione.attiva}
                    toggle={this.toggleRichiestaConfermaAttivazione}
                    titolo={this.state.richiestaConfermaAttivazione.titolo}
                    messaggio={this.state.richiestaConfermaAttivazione.messaggio}
                />
                <ModalSpinner attivo={this.state.loading}></ModalSpinner>
                <Row style={{ paddingBottom: 150 }}>
                    <Col xs="12" sm="12" md="12">
                        <Form
                            action=""
                            onSubmit={e => {
                                let formData = new FormData(e.target);
                                let object = {};
                                formData.forEach((value, key) => (object[key] = value));
                                e.preventDefault();
                            }}
                            className="form-horizontal"
                        >
                            <Card>
                                <CardHeader style={{ minHeight: 43 }}>
                                    <div className={'d-flex align-items-center'}>
                                        <h3 style={{ fontSize: window.innerWidth > 550 ? 20 : 16, fontWeight: 'bold' }}>
                                            {window.innerWidth > 550
                                                ? 'Completa la tua registrazione compilando i dati richiesti'
                                                : 'Completa la tua registrazione'}
                                        </h3>
                                        <div
                                            className={'d-flex align-items-center'}
                                            style={{ marginLeft: window.innerWidth < 600 ? 30 : 100 }}
                                        >
                                            <div
                                                className={'step-number active'}
                                                onClick={() => this.setState({ section: 0 })}
                                            >
                                                1
                                            </div>
                                            <div
                                                className={'step-line'}
                                                style={{ width: this.state.section >= 1 && 0 }}
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    if (
                                                        (this.state.section >= 0 || !this.state.section) &&
                                                        !this.verificaErrori()
                                                    ) {
                                                        if (this.state.section === 0 || !this.state.section)
                                                            this.insertSectionData();
                                                        else this.setState({ section: 1 });
                                                    }
                                                }}
                                                className={'step-number ' + (this.state.section >= 1 && ' active')}
                                            >
                                                2
                                            </div>
                                            <div
                                                className={'step-line'}
                                                style={{
                                                    width:
                                                        (!this.state.section ||
                                                            this.state.section < 1 ||
                                                            this.state.section >= 2) &&
                                                        0
                                                }}
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    let maggiorenne = getAge(this.state.utente.data_nascita) > 17;
                                                    if (maggiorenne) {
                                                        if (
                                                            this.state.section >= 1 &&
                                                            !this.state.verificaErroriNoState()
                                                        ) {
                                                            this.insertSectionData();
                                                        } else this.setState({ section: 2 });
                                                    } else {
                                                        if (this.state.section >= 1 && !this.state.verificaErrori())
                                                            if (this.state.section === 1) this.insertSectionData();
                                                            else if (!this.state.verificaErrori())
                                                                this.setState({ section: 2 });
                                                    }
                                                }}
                                                className={'step-number ' + (this.state.section >= 2 && ' active')}
                                            >
                                                3
                                            </div>
                                            <div
                                                className={'step-line'}
                                                style={{
                                                    width:
                                                        (!this.state.section ||
                                                            this.state.section < 2 ||
                                                            this.state.section >= 3) &&
                                                        0
                                                }}
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    if (
                                                        this.state.section >= 2 &&
                                                        !this.state.verificaErroriNoState()
                                                    ) {
                                                        this.insertSectionData();
                                                    }
                                                    if (this.state.section >= 2) this.setState({ section: 3 });
                                                }}
                                                className={'step-number ' + (this.state.section >= 3 && ' active')}
                                            >
                                                4
                                            </div>
                                            <div
                                                className={'step-line'}
                                                style={{
                                                    width:
                                                        (!this.state.section ||
                                                            this.state.section < 3 ||
                                                            this.state.section >= 4) &&
                                                        0
                                                }}
                                            ></div>
                                            <div
                                                onClick={() => {
                                                    if (this.state.section === 3) this.insertSectionData();
                                                }}
                                                className={'step-number ' + (this.state.section >= 4 && ' active')}
                                            >
                                                5
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody
                                    id={'cardBodyRef'}
                                    style={{
                                        maxHeight: window.innerHeight - 130,
                                        overflowY:
                                            this.state.section === 3 && window.innerWidth > 600 ? 'visible' : 'auto'
                                    }}
                                >
                                    {!this.state.section && this.renderStudentsData()}
                                    <PartiDatiStudente
                                        user={this.state.utente}
                                        selectedUser={this.state.selectedUser}
                                        userId={this.userId}
                                        file={this.state.docUploaded}
                                        section={this.state.section || 0}
                                        setLoading={value => this.setState({ loading: value })}
                                        setData={data => {
                                            this.setState({
                                                sectionData: {
                                                    ...this.state.sectionData,
                                                    [this.state.section || 0]: data
                                                }
                                            });
                                        }}
                                        setVerifica={(data, stateless) =>
                                            this.setState({ verificaErrori: data, verificaErroriNoState: stateless })
                                        }
                                        maggiorenne={maggiorenne}
                                        setAvviso={({ attivo, titolo, messaggio }) =>
                                            this.setState({ avviso: { attivo, titolo, messaggio } })
                                        }
                                    />
                                    <div className={'d-flex justify-content-end'}>
                                        {this.state.section === 4 && (
                                            <Button
                                                type={'button'}
                                                onClick={() => {
                                                    this.setState({ section: 0 });
                                                }}
                                                className={'mt-3 mr-3 error-dlg-button'}
                                                style={{ float: 'left' }}
                                            >
                                                Modifica i dati
                                            </Button>
                                        )}
                                        {(this.state.section === 4 && this.user.is_admin && (
                                            <Button
                                                type={'button'}
                                                onClick={() => {
                                                    this.setState({
                                                        richiestaConfermaAttivazione: {
                                                            attiva: true,
                                                            titolo: 'Attenzione',
                                                            messaggio:
                                                                'Stai per ' +
                                                                (this.state.selectedUser?.abilitato
                                                                    ? 'disattivare'
                                                                    : 'attivare') +
                                                                " l'utente " +
                                                                this.state.selectedUser?.cognome +
                                                                ' ' +
                                                                this.state.selectedUser?.nome
                                                        }
                                                    });
                                                }}
                                                className={
                                                    'mt-3 mr-3' +
                                                    (this.state.selectedUser?.abilitato + '' === '0'
                                                        ? ' success-dlg-button'
                                                        : ' warning-dlg-button')
                                                }
                                                style={{ float: 'left' }}
                                            >
                                                {this.state.selectedUser?.abilitato
                                                    ? 'Annulla registrazione'
                                                    : 'Conferma registrazione'}
                                            </Button>
                                        )) ||
                                            ''}
                                        {this.state.section < 4 && (
                                            <>
                                                {this.state.section > 0 && (
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            if (this.state.section === 0) return;
                                                            this.setState({ section: this.state.section - 1 });
                                                        }}
                                                        className={'mt-3 mr-3 warning-dlg-button'}
                                                        style={{ float: 'right' }}
                                                    >
                                                        Indietro
                                                    </Button>
                                                )}
                                                {(this.state.section === 2 ||
                                                    (this.state.section > 0 &&
                                                        maggiorenne &&
                                                        this.state.section < 3)) && (
                                                    <Button
                                                        onClick={() => {
                                                            this.setState({ section: this.state.section + 1 });
                                                        }}
                                                        className={'mt-3 success-dlg-button mr-3'}
                                                    >
                                                        Salta
                                                    </Button>
                                                )}
                                                {this.user.is_admin ? (
                                                    <Button
                                                        type={'button'}
                                                        onClick={() => {
                                                            this.setState({
                                                                richiestaConfermaAttivazione: {
                                                                    attiva: true,
                                                                    titolo: 'Attenzione',
                                                                    messaggio:
                                                                        'Stai per ' +
                                                                        (this.state.selectedUser?.abilitato
                                                                            ? 'disattivare'
                                                                            : 'attivare') +
                                                                        " l'utente " +
                                                                        this.state.selectedUser?.cognome +
                                                                        ' ' +
                                                                        this.state.selectedUser?.nome
                                                                }
                                                            });
                                                        }}
                                                        className={'mt-3 mr-3 success-dlg-button'}
                                                        style={{ float: 'right' }}
                                                    >
                                                        Forza abilitazione
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                                <Button
                                                    type={'button'}
                                                    onClick={() => {
                                                        if (!this.verificaErrori()) {
                                                            if (!this.state.section) this.insertSectionData();
                                                            let error = false;
                                                            if (this.state.section === 1 || this.state.section === 2) {
                                                                error = this.state.verificaErrori();
                                                            }
                                                            if (!error && this.state.section > 0)
                                                                this.insertSectionData();
                                                            // this.setState({ section: (this.state.section || 0) + 1 });
                                                        }
                                                    }}
                                                    className={'mt-3 success-dlg-button'}
                                                    style={{ float: 'right' }}
                                                >
                                                    Avanti
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                    <div style={{ height: window.innerWidth < 600 ? 60 : 0 }} />
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenAttivo: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: titolo => dispatch(actions.setPageTitle(titolo)),
        onInvalidToken: (errore, path, state) => dispatch(actions.gestisciErrore(errore, path, state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatiUtente));
