import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import AdminCalendarDropDown from './AdminCalendarDropDown';
import CalendarZoom from './CalendarZoom';
import MultiSelect from 'react-widgets/Multiselect';
import DateTimePicker from 'react-widgets/DatePicker';
import moment from 'moment';

export default function CalendarHeader({
    isAdmin,
    state,
    user,
    handleChangeModalitaVista,
    handleChangefiltroRicercaSedi,
    handleChangefiltroRicercaDocenti,
    caricaDatiMaster,
    handleChangeDataRicerca,
    setFilter,
    caricaDatiSettimaneSuccessive,
    handleChangefiltroRicercaStudenti,
    handleChangeDataInizio,
    handleChangeDataFine,
    handleChangefiltroRicercaOnLine,
    handleChangefiltroRicercaPresenza,
    handleChangefiltroRicercaMaterie,
    setReload,
    isAssignment,
    filterHoursStart,
    master,
    psicologo,
    filterHoursEnd,
    filterTable
}) {
    // region variables
    const [calendarWidthState, setCalendarWidthState] = useState({ calendarWidth: 0, zoom: 100 });
    // endregion

    // region lifeCycle
    // onStart
    useLayoutEffect(() => {
        const element = document.getElementById('calendar-dlg');
        const nodes = element.getElementsByTagName('table');
        if (!calendarWidthState.calendarWidth)
            setCalendarWidthState({ calendarWidth: nodes[0].style.width || nodes[0].getBoundingClientRect().width });
    }, []);
    // endregion

    // region functions
    let openHeight = 170;
    if (window.innerWidth < 1500) openHeight = 200;
    if (state.modalitaVista === '3' || state.modalitaVista === '2') {
        openHeight = 215;
        if (window.innerWidth < 1500) openHeight += 20;
    }
    if (window.innerWidth < 577) {
        openHeight = 400;
    }
    // endregion
    const docFilter = user?.is_docente && !user.superadmin && window.innerWidth < 577;
    const isJustDoc = user?.is_docente && !user.superadmin && !docFilter;
    if (docFilter && !user.is_admin == 1) openHeight = 300;
    const isDropdownfilter = (docFilter || isAdmin || user.is_admin) && !psicologo;
    return (
        <CardHeader
            className={
                'calendar-header ' +
                (state.openFilter ? 'open-filter' : 'close-filter') +
                (user?.is_docente && !user.superadmin ? ' docente' : '')
            }
            style={{
                minHeight: (isDropdownfilter && (state.openFilter ? openHeight : 60)) || (isJustDoc ? 150 : 80),
                height: isDropdownfilter && (state.openFilter ? openHeight : 60),
                overflowY: state.openFilter || user.is_tutore || user.is_genitore ? 'visible' : 'hidden',
                overflow: state.openFilter || user.is_tutore || user.is_genitore ? 'visible' : 'hidden',
                transition: '0.5s'
            }}
        >
            <div
                style={{ display: 'flex', alignItems: 'baseline', cursor: isAdmin && 'pointer' }}
                onClick={() => {
                    if (isDropdownfilter) setFilter(!state.openFilter);
                }}
            >
                <h3>
                    {user?.is_studente == 0 && user?.is_tutore == 0 && user.is_genitore == 0 && !psicologo
                        ? 'Filtri di ricerca'
                        : 'Calendario'}
                </h3>
                {isDropdownfilter ? <AdminCalendarDropDown state={state} /> : null}
                <CalendarZoom
                    handleChange={(ev, force) => {
                        if (ev) ev?.stopPropagation();
                        const element = document.getElementById('calendar-dlg');
                        const nodes = element.getElementsByTagName('table');
                        let currWidth = parseFloat(
                            calendarWidthState.calendarWidth || nodes[0].getBoundingClientRect().width
                        );
                        if (force === 100)
                            setCalendarWidthState({
                                calendarWidth: nodes[0].style.width || nodes[0].getBoundingClientRect().width
                            });
                        nodes[0].style.width =
                            (force || ev?.target?.value) === 100
                                ? 'fit-content'
                                : (currWidth * (force || ev?.target?.value)) / 100 + 'px';
                        setCalendarWidthState({ ...calendarWidthState, zoom: force || ev?.target?.value });
                        setReload();
                    }}
                    state={calendarWidthState}
                    // setState={setCalendarWidthState}
                />
            </div>

            {!isAssignment && (
                <Form
                    id={'filter-container-dlg'}
                    action=""
                    method="post"
                    encType="multipart/form-data"
                    className="form-horizontal"
                >
                    <Row
                        style={
                            window.innerWidth > 1500
                                ? {
                                      position: 'absolute',
                                      top: 16,
                                      left: '50%',
                                      transform: 'translateX(-50%)',
                                      minWidth: 'max-content'
                                  }
                                : {}
                        }
                    >
                        {isAdmin && !master ? (
                            <Col
                                xs="6"
                                sm="3"
                                md="3"
                                style={{ minWidth: 'max-content' }}
                                onMouseUp={() => {
                                    filterTable();
                                }}
                            >
                                <FormGroup check className={'margin-b-0 radio'}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id="radioTabella"
                                        name="radioVista"
                                        value="3"
                                        checked={state.modalitaVista === '3'}
                                        onChange={handleChangeModalitaVista}
                                    />
                                    <Label check className="form-check-label" htmlFor="radioTabella">
                                        Mostra tabella
                                    </Label>
                                </FormGroup>
                            </Col>
                        ) : null}
                        {/*<Col xs="6" sm="3" md="3"></Col>*/}
                        <Col xs="6" sm={master ? '4' : '3'} md={master ? '4' : '3'} style={{ minWidth: 'max-content' }}>
                            {user?.is_studente == 0 &&
                            user?.is_tutore == 0 &&
                            user.is_genitore == 0 &&
                            user.superadmin == 1 ? (
                                <FormGroup check className={'margin-b-0 radio'}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id="radioCalendario"
                                        name="radioVista"
                                        value="1"
                                        checked={state.modalitaVista === '1'}
                                        onChange={handleChangeModalitaVista}
                                    />
                                    <Label check className="form-check-label" htmlFor="radioCalendario">
                                        Mostra calendario
                                    </Label>
                                </FormGroup>
                            ) : null}
                        </Col>
                        <Col xs="6" sm={master ? '4' : '3'} md={master ? '4' : '3'} style={{ minWidth: 'max-content' }}>
                            {user?.is_studente == 0 &&
                            user?.is_tutore == 0 &&
                            user.is_genitore == 0 &&
                            (user.is_docente == 0 || user.superadmin == 1) ? (
                                <FormGroup check className={'margin-b-0 radio'}>
                                    <Input
                                        className="form-check-input"
                                        type="radio"
                                        id="radioLista"
                                        name="radioVista"
                                        checked={state.modalitaVista === '2'}
                                        onChange={handleChangeModalitaVista}
                                        value="2"
                                    />
                                    <Label check className="form-check-label" htmlFor="radioLista">
                                        Mostra lista
                                    </Label>
                                </FormGroup>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        {[10, 100].includes(parseInt(localStorage.profilo)) ? (
                            <Col xs="12" sm="3" md="3">
                                <FormGroup className={'margin-b-0'}>
                                    <Label htmlFor="filtroRicercaSede">Sede</Label>
                                    <MultiSelect
                                        id="filtroRicercaSede"
                                        data={state.sediConAule}
                                        value={state.filtriRicerca ? state.filtriRicerca.sedi : []}
                                        textField="nome"
                                        minLength={3}
                                        onChange={handleChangefiltroRicercaSedi}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                        {[10, 100].includes(parseInt(localStorage.profilo)) ? (
                            <Col xs="12" sm="3" md="3">
                                <FormGroup className={'margin-b-0'}>
                                    <Label htmlFor="filtroRicercaDocente">Docenti</Label>
                                    <MultiSelect
                                        id="filtroRicercaDocente"
                                        data={state.docenti?.map(el => {
                                            return { id: el.id, nome: el.cognome + ' ' + el.nome };
                                        })}
                                        value={state.filtriRicerca ? state.filtriRicerca.docenti : []}
                                        textField="nome"
                                        minLength={3}
                                        onChange={handleChangefiltroRicercaDocenti}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                        {[1, 3, 10, 100].includes(parseInt(localStorage.profilo)) ? (
                            <Col xs="12" sm="3" md="3">
                                <FormGroup className={'margin-b-0'}>
                                    <Label htmlFor="filtroRicercaStudente">Studenti</Label>
                                    <MultiSelect
                                        id="filtroRicercaStudente"
                                        data={state.studenti?.map(el => {
                                            return { id: el.id, nome: el.cognome + ' ' + el.nome };
                                        })}
                                        value={state.filtriRicerca ? state.filtriRicerca?.studenti : []}
                                        textField="nome"
                                        minLength={3}
                                        onChange={handleChangefiltroRicercaStudenti}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                        {[1, 10, 100].includes(parseInt(localStorage.profilo)) ? (
                            <Col xs="12" sm="3" md="3">
                                <FormGroup className={'margin-b-0'}>
                                    <Label htmlFor="filtroRicercaMaterie">Materie</Label>
                                    <MultiSelect
                                        id="filtroRicercaMaterie"
                                        data={state.materie}
                                        value={state.filtriRicerca ? state.filtriRicerca?.materie : []}
                                        textField="materia"
                                        minLength={3}
                                        onChange={handleChangefiltroRicercaMaterie}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                    </Row>
                    <Row
                        style={{
                            justifyContent: state.modalitaVista === '3' ? 'space-between' : 'flex-start',
                            alignItems: 'last baseline'
                        }}
                    >
                        {state.modalitaVista === '2' ? (
                            <>
                                <Col xs="6" sm="3" md="3">
                                    <FormGroup className={'margin-b-0'}>
                                        <Label htmlFor="date-da" className="mr-1">
                                            Da
                                        </Label>
                                        <DateTimePicker
                                            id="date-da"
                                            time={false}
                                            value={state.filtriRicerca?.dataInizio || new Date()}
                                            format={{ raw: 'dd/MM/yyyy' }}
                                            onChange={handleChangeDataInizio.bind(this)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="6" sm="3" md="3">
                                    <FormGroup className={'margin-b-0'}>
                                        <Label htmlFor="date-a" className="mr-1">
                                            a
                                        </Label>
                                        <DateTimePicker
                                            id="date-a"
                                            time={false}
                                            value={
                                                state.filtriRicerca?.dataFine ||
                                                moment(new Date()).add(7, 'days').toDate()
                                            }
                                            format={{ raw: 'dd/MM/yyyy' }}
                                            onChange={handleChangeDataFine.bind(this)}
                                        />
                                    </FormGroup>
                                </Col>{' '}
                                <Col xs="3" sm="1" md="1">
                                    <FormGroup>
                                        <InputGroup style={{ marginBottom: '-0.73rem' }}>
                                            <Label htmlFor="date-a" className="mr-1">
                                                Ora di inizio
                                            </Label>
                                            <Input
                                                type="select"
                                                name="oraInizialeEvento"
                                                id="oraInizialeEvento"
                                                style={{ height: 38 }}
                                                // value={startOraNuovoEvento}
                                                onChange={ev => {
                                                    filterHoursStart(ev);
                                                    let end =
                                                        parseInt(ev.target.value) + 1 < 10
                                                            ? '0' + (parseInt(ev.target.value) + 1)
                                                            : parseInt(ev.target.value) + 1;
                                                    if (ev.target.value == 0) end = '100';
                                                    document.getElementById('oraFinaleEvento').value = end;
                                                    filterHoursEnd({ target: { value: end } });
                                                }}
                                            >
                                                <option value="00"></option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                            </Input>
                                            {/*<InputGroupAddon addonType='append'>*/}
                                            <InputGroupText>{': 00'}</InputGroupText>
                                            {/*</InputGroupAddon>*/}
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <div style={{ width: 50 }}></div>
                                <Col xs="3" sm="1" md="1">
                                    <FormGroup>
                                        <InputGroup style={{ marginBottom: '-0.73rem' }}>
                                            <Label htmlFor="date-a" className="mr-1">
                                                Ora di fine
                                            </Label>
                                            <Input
                                                type="select"
                                                name="oraFinaleEvento"
                                                id="oraFinaleEvento"
                                                style={{ height: 38 }}
                                                // value={startOraNuovoEvento}
                                                onChange={filterHoursEnd}
                                            >
                                                <option value="100"></option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                            </Input>
                                            {/*<InputGroupAddon addonType='append'>*/}
                                            <InputGroupText>{': 00'}</InputGroupText>
                                            {/*</InputGroupAddon>*/}
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </>
                        ) : null}

                        {state.modalitaVista === '3' ? (
                            <>
                                <Col xs="6" sm="3" md="3">
                                    <FormGroup className={'margin-b-0'}>
                                        <Label htmlFor="settimana" className="mr-1">
                                            Settimana del:{' '}
                                        </Label>
                                        <DateTimePicker
                                            id="date-settimana"
                                            time={false}
                                            value={
                                                state.filtriRicerca?.dataSett ||
                                                moment(new Date()).add(7, 'days').toDate()
                                            }
                                            format={{ raw: 'dd/MM/yyyy' }}
                                            onChange={handleChangeDataRicerca.bind(this)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="3" sm="1" md="1">
                                    <Button color="danger" id={'btnImportaMaster'} onClick={() => caricaDatiMaster()}>
                                        Replica master
                                    </Button>
                                </Col>
                                <Col xs="3" sm="1" md="1">
                                    <Button
                                        color="danger"
                                        id={'btnReplica'}
                                        onClick={() => caricaDatiSettimaneSuccessive()}
                                    >
                                        Replica eventi
                                    </Button>
                                </Col>
                            </>
                        ) : null}
                        {!isAssignment && (
                            <>
                                <Col xs="6" sm="6" md="3" lg="3" xxl={'1'}>
                                    <FormGroup
                                        className={'margin-b-0 checkbox student-planning-check'}
                                        style={{ marginTop: state.modalitaVista === '1' && 6 }}
                                        check
                                    >
                                        <Input
                                            className="form-check-input "
                                            type="checkbox"
                                            id="checkFiltroPresenza"
                                            name="checkFiltroPresenza"
                                            onChange={handleChangefiltroRicercaPresenza.bind(this)}
                                            checked={state.filtriRicerca?.presenza}
                                        />
                                        <Label check className="form-check-label" htmlFor="checkFiltroPresenza">
                                            In presenza
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col xs="6" sm="6" md="3" lg="3" xxl={'1'}>
                                    <FormGroup className={'margin-b-0 checkbox  student-planning-check'} check>
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="checkFiltroOnLine"
                                            name="checkFiltroOnLine"
                                            onChange={handleChangefiltroRicercaOnLine.bind(this)}
                                            checked={state.filtriRicerca?.online}
                                        />
                                        <Label check className="form-check-label" htmlFor="checkFiltroOnLine">
                                            On line
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            )}
        </CardHeader>
    );
}
