import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import DateTimePicker from 'react-widgets/DatePicker';

export default function ModalsDateComponents({
    startOraNuovoEvento,
    handleChangeOraInizioNuovoEvento,
    durataNuovoEvento,
    handleChangeDurataNuovoEvento,
    endOraNuovoEvento,
    date,
    onlineNuovoEvento,
    handleChangeDataNuovoEvento,
    handleChangeOnLineNuovoEvento
}) {
    // region variables

    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion
    // region functions

    // endregion
    return (
        <>
            <Form inline>
                <FormGroup className="mb-3">
                    <Label htmlFor="date-input" className="mr-1">
                        Data lezione
                    </Label>
                    <DateTimePicker
                        id="date-input"
                        time={false}
                        value={date}
                        format={{ raw: 'dd/MM/yyyy' }}
                        onChange={handleChangeDataNuovoEvento}
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Label className="mr-2">Orario inizio</Label>
                    <InputGroup>
                        <Input
                            type="select"
                            name="oraInizialeEvento"
                            id="oraInizialeEvento"
                            value={startOraNuovoEvento}
                            onChange={handleChangeOraInizioNuovoEvento}
                        >
                            <option value="08">8</option>
                            <option value="09">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </Input>
                        {/*<InputGroupAddon addonType='append'>*/}
                        <InputGroupText>{': 00'}</InputGroupText>
                        {/*</InputGroupAddon>*/}
                    </InputGroup>
                </FormGroup>
            </Form>
            <Form inline>
                <FormGroup className="mb-3">
                    <Label className="mr-2">Durata ore</Label>
                    <Input
                        type="select"
                        name="durataEvento"
                        id="durataEvento"
                        value={durataNuovoEvento}
                        onChange={handleChangeDurataNuovoEvento}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Input>
                </FormGroup>
            </Form>
            <Form inline>
                <FormGroup className="mb-3">
                    <Label className="mr-2" for="txtOrarioFine">
                        Orario fine
                    </Label>
                    <Input type="text" id="txtOrarioFine" disabled size="5" value={endOraNuovoEvento + ' : 00'} />
                </FormGroup>
            </Form>
            {/*<Form inline>*/}
            {/*    <FormGroup className="mt-2 mb-4" style={{ marginLeft: 12 }}>*/}
            {/*        <Input*/}
            {/*            className="form-check-input"*/}
            {/*            type="checkbox"*/}
            {/*            id="checkOnLine"*/}
            {/*            name="checkOnLine"*/}
            {/*            checked={onlineNuovoEvento == '1'}*/}
            {/*            onChange={handleChangeOnLineNuovoEvento}*/}
            {/*        />*/}
            {/*        <Label check className="form-check-label" htmlFor="checkOnLine">*/}
            {/*            Lezione on line*/}
            {/*        </Label>*/}
            {/*    </FormGroup>*/}
            {/*</Form>*/}
        </>
    );
}
