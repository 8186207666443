import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class RichiestaConferma extends Component {
    render() {
        return (
            <Modal isOpen={this.props.attivo}>
                <ModalHeader>{this.props.titolo}</ModalHeader>
                <ModalBody>
                    {typeof this.props.messaggio === 'function' ? this.props.messaggio() : this.props.messaggio}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => this.props.toggle(1)}>
                        OK
                    </Button>{' '}
                    <Button color="warning" onClick={() => this.props.toggle(2)}>
                        {this.props.annullaModifiche ? 'Annulla Modifiche' : 'Annulla'}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
