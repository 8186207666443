import React, { useEffect } from 'react';
import { Badge, Button, Table } from 'reactstrap';
import TooltipItem from '../../../common/TooltipItem';

export default function MateriaGeneralTable({
    title,
    data,
    row,
    btnTitle,
    nuovaMateria,
    disattivaMateria,
    rinominaMateria,
    eliminaMateria,
    isCorsi
}) {
    // region variables

    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {}, []);
    // endregion

    // region functions

    // endregion
    return (
        <div style={{ flex: '1' }}>
            <div className="mb-2">
                <Button color="primary" onClick={() => nuovaMateria(isCorsi)}>
                    {btnTitle}
                </Button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>{title}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(materia => {
                        return (
                            <tr key={materia.id}>
                                <td>
                                    {materia[row]}
                                    {materia.disattivata ? (
                                        <Badge className="ml-2" color="warning">
                                            Disattivata
                                        </Badge>
                                    ) : null}
                                </td>
                                <td>
                                    <Button
                                        color={!materia.disattivata ? 'warning' : 'success'}
                                        size="sm"
                                        id={'btnDisattiva' + materia.id}
                                        onClick={() => disattivaMateria(materia.id, isCorsi)}
                                    >
                                        <i className="fa fa-lightbulb-o"></i>
                                    </Button>
                                    <TooltipItem
                                        placement="top"
                                        objectId={'btnDisattiva' + materia.id}
                                        content={!materia.disattivata ? 'Disattiva' : 'Riattiva'}
                                    />{' '}
                                    <Button
                                        color="warning"
                                        size="sm"
                                        id={'btnRinomina' + materia.id}
                                        onClick={() => rinominaMateria(materia.id, isCorsi)}
                                    >
                                        <i className="fa fa-edit"></i>
                                    </Button>
                                    <TooltipItem
                                        placement="top"
                                        objectId={'btnRinomina' + materia.id}
                                        content="Rinomina"
                                    />{' '}
                                    <Button
                                        color="danger"
                                        size="sm"
                                        id={'btnElimina' + materia.id}
                                        onClick={() => eliminaMateria(materia.id, isCorsi)}
                                    >
                                        <i className="fa fa-trash"></i>
                                    </Button>
                                    <TooltipItem
                                        placement="top"
                                        objectId={'btnElimina' + materia.id}
                                        content="Elimina"
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
