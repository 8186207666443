import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { apiconfig } from '../shared/apiconfig';
import axios from 'axios';
import { viewdData } from './services';
export default function ModaleViewFileToUpload(props) {
    // region variables
    const [imgData, setImgData] = useState();
    const [loading, setLoading] = useState(false);
    // endregion

    // region lifeCycle
    // onStart
    useEffect(() => {
        if (!props.data) return;
        setLoading(true);
        axios.get(apiconfig().baseURL + 'allegatoutentetemp/' + props.data, { responseType: 'blob' }).then(res => {
            if (res.data) {
                setLoading(false);
                setImgData(viewdData(res.data));
            }
        });
    }, [props.data]);
    // endregion

    // region functions

    // endregion
    return (
        <Modal isOpen={props.attivo} size={'xl'}>
            <ModalHeader>Anteprima della mappa caricata</ModalHeader>
            <ModalBody style={{ textAlign: 'center' }}>
                <p style={{ color: 'red', fontSize: 16, fontWeight: 'bold' }}>
                    Controlla che il logo del Gemello di Einstein non copra parte dell'immagine
                </p>
                {loading ? (
                    <div>
                        <p style={{ color: '#078abc', fontSize: 16, fontWeight: 'bold' }}>Caricamento in corso...</p>
                        <Spinner style={{ textAlign: 'center' }} color="primary" />
                    </div>
                ) : (
                    <img
                        alt={'mappa-caricata'}
                        style={{
                            maxWidth: 1000,
                            margin: 'auto',
                            width: window.innerWidth > 1000 ? 1000 : window.innerWidth - 36
                        }}
                        src={imgData}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    style={{ backgroundColor: '#42cbff' }}
                    onClick={() => {
                        props.toggle();
                        setImgData(null);
                        document.getElementById('change-file-dlg-button').click();
                    }}
                >
                    Cambia file
                </Button>

                <Button color="primary" onClick={() => props.toggle()}>
                    Continua
                </Button>
            </ModalFooter>
        </Modal>
    );
}
