import { convertiDataPerDB, updateObject } from '../../../shared/utility';
import axios from 'axios';
import { apiconfig } from '../../../shared/apiconfig';
import moment from 'moment';
import React, { useEffect } from 'react';

export const coloriEventi = [
    '#57a9e8',
    '#b2afbb',
    '#50dbdb',
    '#2daab0',
    '#43dc41',
    '#cbb8ff',
    '#8cffc1',
    '#6cb462',
    '#b59484',
    '#6a8b7a',
    '#76d48c',
    '#379865',
    '#da7bff',
    '#0ae2de',
    '#9c83eb',
    '#64ffd6',
    '#a077a9',
    '#f18659',
    '#7adef5',
    '#db9563',
    '#f86855',
    '#37eb12',
    '#afedf8',
    '#c9d36a',
    '#c5ffab',
    '#d06161',
    '#31a493',
    '#c67950',
    '#c7676c',
    '#50d976',
    '#07cb34',
    '#69bbf5',
    '#3deda2',
    '#f3a9e9',
    '#2c848e',
    '#b1f5a8',
    '#63d48d',
    '#e4b5b7',
    '#db7b23',
    '#c4a5f3',
    '#ef8c8d',
    '#9e7265',
    '#e596a7',
    '#b8d3f6',
    '#f78b2d',
    '#e2a3b8',
    '#a7df8c',
    '#d6acfb',
    '#4a8d8b',
    '#e3c4fc',
    '#fb98c9',
    '#f3de8a',
    '#c8e3f5',
    '#f5b3b1',
    '#d9f8c4',
    '#dfb3f6',
    '#f4a2d4',
    '#debcf0',
    '#fc92e3',
    '#9bdeac',
    '#f7a8b8',
    '#a2f2d0',
    '#7ebedf',
    '#f6a4c9',
    '#d7c489',
    '#b9e9f7',
    '#f3c5e9',
    '#edf4b6',
    '#c2f3a9',
    '#a6d8f4',
    '#e8d2f4',
    '#fac8d2',
    '#b9f2d6',
    '#f4a1d8',
    '#de96f5',
    '#c5edfa',
    '#b5e4f4',
    '#f2d9a7',
    '#e7a9f1',
    '#d4f3c5',
    '#fcb5e6',
    '#a6dcef',
    '#e8fcb9',
    '#d2f8c4',
    '#f9a1d1',
    '#cdf5a2',
    '#f7d4a8',
    '#baf2eb',
    '#f2a4d9',
    '#a5fced',
    '#d3a5fc',
    '#f4b8e9',
    '#f2d3a4',
    '#b4e5fc',
    '#e9f1a6',
    '#fac8e4',
    '#a7f4d9',
    '#f3b8d4',
    '#a4f2e9',
    '#d5a6fb',
    '#e9a4f2',
    '#b4d9f7',
    '#f4b5a7',
    '#d9f4a4',
    '#fcb8e7',
    '#a6edfc',
    '#f2a7d4',
    '#d4fcb5',
    '#e9f2a6',
    '#fac4e8',
    '#a7d5f4',
    '#f3a9b8',
    '#a4f2d3',
    '#d6a4fb',
    '#e9c8f2',
    '#b5fcda',
    '#f4a7c9',
    '#d9fca4',
    '#fcb8d3',
    '#a6fcf2'
];
export const assegnaDesinenza = user => {
    let desinenza = '';
    if (!user) return;
    if (user.is_admin == 1) desinenza = 'adm';
    else if (user.is_docente == 1) desinenza = 'doc';
    else if (user.is_studente == 1) desinenza = 'stud';
    else if (user.is_tutore == 1) desinenza = 'tut';
    else if (user.is_genitore == 1) desinenza = 'tut';
    else if (user.superadmin == 0) desinenza = 'ERRORE';
    return desinenza;
};

export const orari = [
    '08-09',
    '09-10',
    '10-11',
    '11-12',
    '12-13',
    '14-15',
    '15-16',
    '16-17',
    '17-18',
    '18-19',
    '19-20',
    '20-21'
];

export const STAB_NOTES = [
    {
        date: moment().add(2, 'days').format('DD/MM/YYYY'),
        data: [
            'Oggi non devo inserire marco che non ci sta',
            'Creare fattura per Giuseppe Rossi',
            'Inserire il nuovo docente se mi da la conferma in loco',
            'Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,'
        ]
    },
    {
        date: moment().add(-2, 'days').format('DD/MM/YYYY'),
        data: [
            'Inserire il nuovo docente se mi da la conferma in loco',
            'Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,Sono una nota molto lunga, la utilizzo per provare come si comporta il container in questi casi,'
        ]
    },
    {
        date: moment().add(1, 'days').format('DD/MM/YYYY'),
        data: ['Creare fattura per Giuseppe Rossi']
    },
    {
        date: moment().add(0, 'days').format('DD/MM/YYYY'),
        data: ['Oggi non devo inserire marco che non ci sta']
    }
];
export const codiciColori = ['0', '3', '6', '9', 'C', 'F'];
const year = new Date().getFullYear();
const month = new Date().getMonth();
const day = new Date().getFullYear();
export function aggiornaRicercaS(state, user, setState, desinenza, caricaEventoSelezionato, props, callback, studFix) {
    if (
        !state ||
        !state.caricamentoSedi ||
        !state.caricamentoMaterie ||
        !state.caricamentoAule ||
        !state.caricamentoDocenti ||
        !state.caricamentoStudenti ||
        !state.aggiornamentoFiltri
    ) {
        return;
    }
    let is_studente = user?.is_studente;
    let filtri = {
        sedi: JSON.stringify(state.filtriRicerca.sedi.map(el => el.id)),
        materie: JSON.stringify(state.filtriRicerca.materie.map(el => el.id)),
        docenti: JSON.stringify(state.filtriRicerca.docenti.map(el => el.id)),
        studenti: JSON.stringify(state.filtriRicerca.studenti.map(el => el.id)),
        data: convertiDataPerDB(
            state.modalitaVista === '1'
                ? state.filtriRicerca.data
                : state.modalitaVista === '2'
                ? state.filtriRicerca.dataInizio
                : state.filtriRicerca.dataSett
        ),
        dataFine:
            state.modalitaVista === '2' && state.filtriRicerca.dataFine
                ? moment(state.filtriRicerca.dataFine).format('YYYY-MM-DD')
                : null,
        online: state.filtriRicerca.online ? 1 : 0,
        presenza: state.filtriRicerca.presenza ? 1 : 0
    };
    let params = { params: filtri };
    const newModaleSpinner = updateObject(state.modaleSpinner, { attivo: true });
    setState(
        {
            modaleSpinner: newModaleSpinner
        },
        () => {
            axios
                .get(apiconfig().baseURL + 'planning' + desinenza, params)
                .then(response => {
                    const newModaleSpinner = updateObject(state.modaleSpinner, { attivo: false });
                    let eventi;
                    eventi = response.data.map((el, i) => {
                        const elencoDocenti = el.docenti
                            .map(x => x.cognome.substr(0, 1) + x.nome.substr(0, 1))
                            .join(' - ');
                        const elencoStudenti = el.studenti
                            .filter(x => state.studenti?.findIndex(y => y.id == x.id) != -1)
                            .map(x => x.cognome + ' ' + x.nome)
                            .join(' - ');
                        const elencoMaterie = el.materie.map(x => x.materia).join(' - ');
                        let lastColorEvento;
                        let coloreDocente = el.docenti?.find(e => {
                            return e.colore;
                        })?.colore;
                        console.log(user.is_studente == 0, '&&', user.is_tutore == 0, '&&', user.is_genitore == 0);
                        let coloreEvento =
                            !(user.is_studente == 0 && user.is_tutore == 0 && user.is_genitore == 0) || !coloreDocente
                                ? coloriEventi[
                                      (el.materie[0]?.materia?.charCodeAt(1) + el.materie[0]?.materia?.charCodeAt(0) ||
                                          0) % coloriEventi.length
                                  ] + 'dd'
                                : coloreDocente + 55;

                        const evento = updateObject(el, {
                            title:
                                (el.online == 1 ? 'A DISTANZA - ' : '') +
                                (user.is_studente == 0 && user.is_tutore == 0 && user.is_genitore == 0
                                    ? 'Docenti: ' +
                                      elencoDocenti +
                                      '. Studenti: ' +
                                      elencoStudenti +
                                      '. Materie: ' +
                                      (elencoMaterie || ' - ') +
                                      '. Aula: ' +
                                      (el.aula_planning ? el.aula_planning?.aula || ' - ' : ' - ').replace('Aula', '')
                                    : (elencoStudenti.length > 0 ? elencoStudenti + ' - ' : '') +
                                      (is_studente
                                          ? ''
                                          : 'Docente: ' +
                                            el.docenti.map(x => x.cognome + ' ' + x.nome).join(', ') +
                                            ' - ') +
                                      elencoMaterie +
                                      (el.aula_planning ? ' - ' + el.aula_planning.aula : '')),
                            date: el.data_planning,
                            color: coloreEvento,
                            start: el.data_planning + 'T' + el.start + ':00',
                            end: el.data_planning + 'T' + el.end + ':00',
                            inizio: el.start,
                            textColor: el.abilitato,
                            fine: el.end,
                            online: el.online,
                            indicazioni_centro: el.indicazioni_centro,
                            docenti: el.docenti.map(x => {
                                return {
                                    id: x.id,
                                    nome: x.cognome + ' ' + x.nome,
                                    nomeBreve: x.cognome.substr(0, 1) + '.' + x.nome.substr(0, 1) + '.',
                                    presenza: x.pivot.presenza,
                                    attivita_svolte: x.pivot.attivita_svolte,
                                    materie: x.materie
                                };
                            }),
                            studenti: el.studenti.map(x => {
                                return {
                                    id: x.id,
                                    nome: x.cognome + ' ' + x.nome,
                                    nomeBreve: x.cognome.substr(0, 1) + '.' + x.nome.substr(0, 1) + '.',
                                    presenza: x.pivot.presenza,
                                    annotazioni: x.pivot.annotazioni,
                                    classe: x.classe,
                                    scuola: x.scuola
                                };
                            }),
                            materie: el.materie.map(x => {
                                return { id: x.id, materia: x.materia };
                            }),
                            sede: el.sede_planning,
                            allegati: el.allegati?.map(a => updateObject(a, { esistente: 1 })) || [],
                            allegati_studenti: el.allegati_studenti
                        });
                        return evento;
                    });
                    let listaEventiPerReplica = [];
                    if (state.modalitaVista === '3') {
                        let dataCorrente = state.filtriRicerca.dataSett;
                        dataCorrente.setDate(
                            dataCorrente.getDate() - (dataCorrente.getDay() != 0 ? dataCorrente.getDay() : 7) + 1
                        ); //LUN
                        let dataFinale = new Date(dataCorrente);
                        dataFinale.setDate(dataFinale.getDate() + 6);
                        dataCorrente = convertiDataPerDB(dataCorrente);
                        dataFinale = convertiDataPerDB(dataFinale);
                        eventi.map(el => {
                            if (el.data_planning >= dataCorrente && el.data_planning <= dataFinale) {
                                listaEventiPerReplica.push(el.id);
                            }
                        });
                    }
                    let sorted = eventi
                        // .filter(element => element.title.indexOf('non assegnata') === -1)
                        .sort((a, b) => (a.start < b.start ? 1 : a.start > b.start ? -1 : 0));
                    let toShow = join(state.eventi, sorted);
                    setTimeout(() => {
                        if (document.getElementById('slot07'))
                            document.getElementById('slot07')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }, 100);
                    setState(
                        {
                            eventi: sorted,
                            modaleSpinner: newModaleSpinner,
                            idEventiVisualizzatiPerReplica: listaEventiPerReplica
                        },
                        () => {
                            if (state.eventoSelezionato.id) caricaEventoSelezionato(state);
                            if (state.idEventoSelezionatoStud && !studFix) {
                                const currentEvent = state.eventi.find(e => e.id == state.idEventoSelezionatoStud);
                                setState({
                                    eventoSelezionatoStud: currentEvent
                                });
                            }
                        }
                    );
                    if (callback) callback(sorted);
                })
                .catch(error => {
                    const newModaleSpinner = updateObject(state.modaleSpinner, { attivo: false });
                    setState(
                        {
                            modaleSpinner: newModaleSpinner
                        },
                        () => {
                            console.error(error);
                            if (
                                error?.response?.status &&
                                (error.response.status == 401 || error.response.status == 404)
                            ) {
                                props.onInvalidToken(error, props.location.pathname, state);
                                props.navigate('/logout');
                            } else {
                                setState({
                                    avviso: {
                                        attivo: true,
                                        titolo: 'ATTENZIONE - Rilevato un errore',
                                        messaggio: error.errore
                                    }
                                });
                            }
                        }
                    );
                });
        }
    );
}

export function caricaElencoSediS(setState, desinenza) {
    let params = { params: { disattivata: 0 } };
    axios
        .get(apiconfig().baseURL + 'sedi' + desinenza, params)
        .then(response => {
            let sediConAule = [];
            response.data.map(sede => {
                sediConAule.push({ id: sede.id + '', nome: sede.nome });
                sede.aule.map(aula => {
                    sediConAule.push({ id: sede.id + '-' + aula.id, nome: sede.nome + ' - Aula: ' + aula.aula });
                });
            });
            setState({
                sedi: response.data,
                sediConAule: sediConAule,
                caricamentoSedi: true
            });
        })
        .catch(error => {
            setState({
                caricamentoSedi: true
            });
        });
}

export function caricaElencoAuleeS(setState) {
    axios
        .get(apiconfig().baseURL + 'aule', {})
        .then(response => {
            setState({
                aule: response.data,
                caricamentoAule: true
            });
        })
        .catch(error => {
            setState({
                caricamentoAule: true
            });
        });
}

export function caricaElencoMaterieS(setState, desinenza, user) {
    const params = { params: { disattivata: 0 } };
    let path;
    if (user.is_admin == 1 || user.superadmin == 1) {
        path = 'materie';
    } else if (user.is_docente == 1) {
        path = 'materiedoc';
    } else if (user.is_studente == 1) {
        path = 'materiestud';
    } else if (user.is_tutore == 1 || user.is_genitore == 1) {
        path = 'materietut';
        //} else if (user.is_admin == 1) {
        //  path='materieadm';
        //} else if (user.superadmin == 1) {
        //  path='materie';
    } else return;
    axios
        .get(apiconfig().baseURL + path, params)
        .then(response => {
            setState({
                materie: response.data,
                caricamentoMaterie: true
            });
        })
        .catch(error => {
            setState({
                caricamentoMaterie: true
            });
        });
}

export function caricaDocentiRefS(setState, desinenza, user) {
    if (user.is_admin == 0) {
        return;
    }
    axios
        .get(apiconfig().baseURL + 'docentiref', {})
        .then(response => {
            const docentiref = response.data.map(el => {
                return { id: el.id_docente };
            });
            setState({
                docentiRefUtente: docentiref
            });
        })
        .catch(error => {});
}

export function caricaElencoDocentiS(setState, desinenza, user) {
    let params;
    let path;
    if (user.is_studente == 1) {
        path = 'docentistud';
        params = { params: {} };
    } else if (user.is_tutore == 1 || user.is_genitore == 1) {
        path = 'docentitut';
        params = { params: {} };
    } else if (user.is_admin) {
        path = 'docentiadm';
        params = { params: {} };
    } else if (user.superadmin == 1) {
        path = 'users';
        params = { params: { is_docente: 1 } };
    } else {
        setState({
            caricamentoDocenti: true
        });
        return;
    }
    axios
        .get(apiconfig().baseURL + path, params)
        .then(response => {
            const nuoviDocenti = response.data.map(doc => {
                let coloreCorrente, coloreCarattere;
                let coloreImpostato = false;
                if (user.is_admin == 1 || user.superadmin == 1) {
                    if (doc.colore != null && doc.colore != '') {
                        coloreImpostato = true;
                        coloreCorrente = doc.colore;
                        coloreCarattere = doc.colore_testo == '1' ? 'black' : 'white';
                    }
                }
                if (!coloreImpostato) {
                    coloreCorrente =
                        '#' +
                        codiciColori[doc.id % codiciColori.length] +
                        codiciColori[
                            (doc.cognome.substr(0, 1).charCodeAt(0) - 'A'.charCodeAt(0)) % codiciColori.length
                        ] +
                        codiciColori[
                            parseInt(doc.nome.substr(0, 1).charCodeAt(0) - 'A'.charCodeAt(0)) % codiciColori.length
                        ];
                    if (coloreCorrente === '#FFF') {
                        coloreCorrente = '#CFF';
                    }
                    if (coloreCorrente === '#000') {
                        coloreCorrente = '#300';
                    }
                    coloreCarattere = coloreCorrente.substr(2, 1) > '6' ? 'black' : 'white';
                }
                coloreCorrente =
                    coloriEventi[(doc?.cognome?.charCodeAt(doc.cognome?.length - 1) || 0) % coloriEventi.length];

                return updateObject(doc, { colore: coloreCorrente, coloreCarattere: coloreCarattere + 33 });
            });
            setState({
                docenti: nuoviDocenti,
                caricamentoDocenti: true
            });
        })
        .catch(error => {
            setState({
                caricamentoDocenti: true
            });
        });
}

export function caricaElencoStudentiS(setState, desinenza, user) {
    let params;
    let path;
    if (user.is_admin == 1 || user.superadmin == 1) {
        path = 'users';
        params = { params: { is_studente: 1 } };
    } else if (user.is_docente == 1) {
        path = 'studentidoc';
        params = { params: {} };
    } else if (user.is_tutore == 1 || user.is_genitore == 1) {
        path = 'studentitut';
        params = { params: {} };
        //} else if (user.is_admin) {
        //  path='studentiadm';
        //  params = { params: {} };
        //} else if (user.superadmin==1) {
        //  path='users';
        //  params = { params: { is_studente: 1 } };
    } else return;
    axios
        .get(apiconfig().baseURL + path, params)
        .then(response => {
            setState({
                studenti: response.data,
                caricamentoStudenti: true
            });
        })
        .catch(error => {
            setState({
                caricamentoStudenti: true
            });
        });
}
export function join(a1, a2) {
    var hash = new Map();
    a1.concat(a2).forEach(function (obj) {
        hash.set(obj.id, Object.assign(hash.get(obj.id) || {}, obj));
    });
    var a3 = Array.from(hash.values());
    return a3;
}
export function getLastSaturday2(date) {
    // Copy date so don't modify original
    let d = new Date(date);
    // Adjust to previous Saturday
    d.setDate(d.getDate() - (d.getDay() - 7));
    return d;
}
export const IfActive = ({ active, callBack, onClose }) => {
    useEffect(() => {
        if (active) {
            callBack();
        } else if (onClose) {
            onClose();
        }
    }, [active]);
    return <></>;
};
export function insertAssignmentF(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(apiconfig().baseURL + 'planningVerifiche', data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
export function modifyAssignmentF(data, id) {
    return new Promise((resolve, reject) => {
        axios
            .patch(apiconfig().baseURL + 'planningVerifiche/' + id, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
}
export function deleteAssignmentF(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(apiconfig().baseURL + 'planningVerifiche/' + id)
            .then(res => {
                resolve(res.data);
            })
            .catch((err, data) => {
                reject(err.response.data);
            });
    });
}
export function getAssignmentF(data) {
    return new Promise((resolve, reject) => {
        axios
            .get(apiconfig().baseURL + 'planningVerifiche?data=' + data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                // reject(err.response.data);
                reject(err);
            });
    });
}
export function difBDate(date1, date2) {
    let diffTime = Math.abs(new Date(date1).valueOf() - new Date(date2).valueOf());
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
    return { days, hours, minutes };
}
