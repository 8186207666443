import React, { Component } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';

export default class ModalSpinner extends Component {
    render() {
        return (
            <Modal
                isOpen={this.props.attivo}
                keyboard={false}
                style={{ backgroundColor: 'gray', border: '0px' }}
                size="sm"
                fade={false}
            >
                <ModalBody>
                    <div style={{ textAlign: 'center', fontStyle: 'italic' }}>Caricamento in corso...</div>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner style={{ textAlign: 'center' }} color="primary" />
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
