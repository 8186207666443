import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import FiltroAllegati from '../../../common/FiltroAllegati';
import { updateObject } from '../../../shared/utility';

export default function AllegatiHeader({ state, setState }) {
    const handleChangefiltroRicercaTipiAllegati = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            tipiAllegati: arg
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };

    const handleChangefiltroRicercaScuole = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            scuole: arg
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };

    const handleChangefiltroRicercaMaterie = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            materie: arg
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };

    const handleChangefiltroRicercaClassi = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            classi: arg
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };

    const handleChangefiltroRicercaDescrizione = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            descrizione: arg.target.value
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };

    const handleChangefiltroRicercaSemplificati = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            semplificati: arg.target.checked
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };
    const handleChangefiltroRicercaSostegno = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            sostegno: arg.target.checked
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };
    const handleChangefiltroRicercaNonSemplificati = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            nonSemplificati: arg.target.checked
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };
    const handleChangefiltroRicercaDocenti = arg => {
        const newfiltriRicerca = updateObject(state.filtriRicerca, {
            docenti: arg
        });
        setState({
            filtriRicerca: newfiltriRicerca,
            paginaRicerca: 1
        });
    };
    return (
        <Row>
            <Col xs="12" sm="12" md="12">
                <FiltroAllegati
                    filtriRicerca={state.filtriRicerca}
                    handleChangefiltroRicercaTipiAllegati={handleChangefiltroRicercaTipiAllegati}
                    handleChangefiltroRicercaScuole={handleChangefiltroRicercaScuole}
                    handleChangefiltroRicercaDocenti={handleChangefiltroRicercaDocenti}
                    handleChangefiltroRicercaClassi={handleChangefiltroRicercaClassi}
                    handleChangefiltroRicercaMaterie={handleChangefiltroRicercaMaterie}
                    handleChangefiltroRicercaDescrizione={handleChangefiltroRicercaDescrizione}
                    handleChangefiltroRicercaSemplificati={handleChangefiltroRicercaSemplificati}
                    handleChangefiltroRicercaSostegno={handleChangefiltroRicercaSostegno}
                    handleChangefiltroRicercaNonSemplificati={handleChangefiltroRicercaNonSemplificati}
                />
            </Col>
        </Row>
    );
}
