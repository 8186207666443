import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CFormTextarea } from '@coreui/react';
import { modifyEvaluation } from './EvaluationAdminUtils';

export default function EvaluationAdminModal(props) {
    const [text, setText] = useState('');

    useEffect(() => {
        if (props.data) setText(props.data.response);
    }, [props.data]);

    useEffect(() => {
        if (props.attivo && !props.data) setText('');
    }, [props.attivo]);

    return (
        <Modal isOpen={props.attivo}>
            <ModalHeader>{props.data ? 'Modifica Risposta' : 'Rispondi'}</ModalHeader>
            <ModalBody>
                Inserisci una risposta
                <CFormTextarea
                    rows={3}
                    value={text}
                    onChange={event => setText(event.target.value)}
                    placeholder={'Risposta'}
                />
                <div className={'d-flex align-items-end'}>
                    <Button color={'primary'} onClick={() => props.close()} className={'mt-3 ml-auto'}>
                        Chiudi
                    </Button>

                    <Button
                        style={{ background: 'limegreen', color: 'white' }}
                        onClick={() => {
                            let temp = { ...props.data };
                            temp.messaggio = text;
                            temp.attiva = 1;
                            modifyEvaluation(temp)
                                .then(res => {
                                    window.location.reload();
                                    props.close();
                                })
                                .catch(err => {
                                    props.setAvviso({ attivo: true, titolo: 'Errore', messaggio: err });
                                });
                            // modifyRequest({ status: 'accepted', risposta: text }, props.notification.id)
                            //
                        }}
                        className={'mt-3 ml-3'}
                    >
                        Salva
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}
