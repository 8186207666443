import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, Col, ListGroup, ListGroupItem, ListGroupItemText, Row } from 'reactstrap';
import Paginazione from '../../../common/Paginazione';
import TooltipItem from '../../../common/TooltipItem';
import { convertiDataDaDB, pathDownload } from '../../../shared/utility';
import axios from 'axios';
import { apiconfig } from '../../../shared/apiconfig';
import moment from 'moment';
import { downloadAllegato, downloadAllegatoMaster, scaricaSME, URL_ALLEGATI } from '../../../services/commons';
import ModaleVisualizzaAllegato from './ModaleVisualizzaAllegato';

export default function AllegatiContent({ state, setState, setStateWRicerca }) {
    const [user, setUser] = useState({});
    useEffect(() => {
        try {
            setUser(JSON.parse(localStorage.user));
        } catch (e) {
            console.log('');
        }
    }, []);
    const scaricaAllegato = (id, nome) => {
        setState({ modaleSpinner: { attivo: true } });
        downloadAllegatoMaster(id, nome, URL_ALLEGATI.BASE)
            .then(data => {
                if (data)
                    setState({
                        modaleVisualizzaAllegato: data,
                        modaleVisualizzaAllegatoIsPdf: nome.includes('.pdf'),
                        modaleVisualizzaAllegatoName: nome
                    });
            })
            .finally(() => {
                setState({ modaleSpinner: { attivo: false } });
            });
    };

    function removeAllegato(arg) {
        setState({
            richiestaConferma: {
                attiva: true,
                titolo: 'Richiesta conferma',
                messaggio: 'Eliminare questo documento?',
                azione: 2,
                idAllegato: arg
            }
        });
    }
    const cambiaPagina = nuova => {
        setStateWRicerca({
            paginaRicerca: nuova
        });
    };
    const modificaAllegato = id => {
        const allegatoSel = state.listaAllegati.find(el => el.id == id);
        setState({
            modaleAllegatiAttiva: true,
            idStudenteModaleAllegati: 0,
            idAllegatoModificato: id,
            idTipoAllegatoNuovo: allegatoSel.id_tipo,
            idMateriaAllegatoNuovo: allegatoSel.id_materia,
            idClasseAllegatoNuovo: allegatoSel.classe,
            idScuolaAllegatoNuovo: allegatoSel.id_scuola,
            nomeFileAllegatoNuovo: allegatoSel.nome_file,
            descrAllegatoNuovo: allegatoSel.descrizione,
            idAllegatoInAttesa: null
        });
    };

    const nuovoAllegato = () => {
        setState({
            modaleAllegatiAttiva: true,
            idStudenteModaleAllegati: 0,
            idAllegatoModificato: 0,
            idTipoAllegatoNuovo: '',
            idMateriaAllegatoNuovo: '',
            idClasseAllegatoNuovo: '',
            idScuolaAllegatoNuovo: '',
            descrAllegatoNuovo: '',
            idAllegatoInAttesa: null
        });
    };

    return (
        <Row>
            <Col xs="12" sm="12" md="12">
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="12" md="12" sm="12" className="mb-5">
                                <Button color="primary" onClick={() => nuovoAllegato()}>
                                    Nuovo documento
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="12" sm="12">
                                <Paginazione
                                    suCambiaPagina={cambiaPagina}
                                    paginaRicerca={state.paginaRicerca}
                                    totaleItems={state.totaleItemsRicerca}
                                    itemsPerPagina={state.itemsPerPagina}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="12" sm="12">
                                <ListGroup>
                                    {state.listaAllegati.map(el => {
                                        return (
                                            <ListGroupItem key={el.id} style={{ position: 'relative' }}>
                                                <Button
                                                    color="link"
                                                    onClick={() => scaricaAllegato(el.id, el.nome_file)}
                                                >
                                                    {el.nome_file}
                                                </Button>

                                                {el.sostegno ? <Badge color={'warning'}>Sostegno</Badge> : ''}
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    className="float-right"
                                                    onClick={() => removeAllegato(el.id)}
                                                    id={'EliminaAllegato_' + el.id}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                                <TooltipItem
                                                    placement="top"
                                                    objectId={'EliminaAllegato_' + el.id}
                                                    content="Rimuovi"
                                                />
                                                {el.sme && (
                                                    <>
                                                        {' '}
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className="float-right sme-button"
                                                            onClick={() =>
                                                                scaricaSME(el.sme?.id, el.sme?.nome_file, data => {
                                                                    setState({
                                                                        ...data
                                                                    });
                                                                })
                                                            }
                                                            id={'sme_' + el.id}
                                                        >
                                                            <span style={{ fontSize: 10 }}>SME</span>
                                                        </Button>
                                                        <TooltipItem
                                                            placement="top"
                                                            objectId={'sme_' + el.id}
                                                            content="Scarica file SME"
                                                        />
                                                    </>
                                                )}
                                                <Button
                                                    size="sm"
                                                    color="warning"
                                                    className="float-right"
                                                    onClick={() => modificaAllegato(el.id)}
                                                    id={'ModificaAllegato_' + el.id}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </Button>
                                                <TooltipItem
                                                    placement="top"
                                                    objectId={'ModificaAllegato_' + el.id}
                                                    content="Modifica dati collegati"
                                                />
                                                <ListGroupItemText>
                                                    <span style={{ color: 'maroon' }} className="mr-2">
                                                        {el.tipo && el.tipo.nome ? el.tipo.nome : ''}
                                                    </span>
                                                    <span className="mr-2">
                                                        {el.classe ? 'Classe: ' + el.classe : ''}
                                                    </span>
                                                    <span className="mr-2">
                                                        {el.scuola && el.scuola.nome ? 'Scuola: ' + el.scuola.nome : ''}
                                                    </span>
                                                    <span className="mr-2">
                                                        {el.materia && el.materia.materia
                                                            ? 'Materia: ' + el.materia.materia
                                                            : ''}
                                                    </span>
                                                    <span className="mr-2">
                                                        {el.semplificato == 1 ? (
                                                            <Badge color="warning">Semplificato</Badge>
                                                        ) : null}
                                                    </span>
                                                    <br />
                                                    {el.descrizione ? el.descrizione : ''}
                                                    <br />
                                                    {user && user?.is_studente != 1 && (
                                                        <span style={{ fontStyle: 'italic' }}>
                                                            Caricato da {el.autore?.cognome + ' ' + el.autore?.nome}{' '}
                                                            {moment(el.updated_at).format('DD/MM/YYYY HH:mm')}
                                                        </span>
                                                    )}
                                                </ListGroupItemText>
                                            </ListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <ModaleVisualizzaAllegato
                data={state.modaleVisualizzaAllegato}
                isPdf={state.modaleVisualizzaAllegatoIsPdf}
                name={state.modaleVisualizzaAllegatoName}
                close={() => {
                    setState({ modaleVisualizzaAllegato: null });
                }}
            />
        </Row>
    );
}
