import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormText, 
        FormGroup, Row, Col, Input, Label } from 'reactstrap';

export default class ModaleModificaPassword extends Component {

  render() {
    const style = {
      borderColor: 'red'
    };
    return (
      <Modal isOpen={this.props.attivo} >
        <ModalHeader>MODIFICA PASSWORD</ModalHeader>
        <ModalBody>
          <Form 
            action="" 
            onSubmit={e => e.preventDefault()} 
            className="form-horizontal"
          >
            <Row>
              <Col xs="12" sm="12" md="12">
                <FormGroup>
                  <Label htmlFor='pwCorrente'>Password attuale</Label>
                  <Input
                    type="password"
                    id="pwCorrente"
                    style={!this.props.verificaPwAttuale ? style : null}
                    name="pwCorrente"
                    value={this.props.pwAttuale}
                    onChange={this.props.handlePwAttualeChange}
                  />
                  {
                    !this.props.verificaPwAttuale ?
                      <FormText color="danger">Inserire l'attuale password</FormText>
                      : null
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" md="12">
                <FormGroup>
                  <Label htmlFor='nuovaPw'>Nuova password (almeno 8 caratteri)</Label>
                  <Input
                    type="password"
                    id="nuovaPw"
                    style={!this.props.verificaPw1 ? style : null}
                    name="nuovaPw"
                    value={this.props.nuovaPw}
                    onChange={this.props.handleNuovaPwChange}
                  />
                  {
                    !this.props.verificaPw1 ?
                      <FormText color="danger">Inserire la nuova password di almeno 8 caratteri</FormText>
                      : null
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" md="12">
                <FormGroup>
                  <Label htmlFor='nuovaPw2'>Ripetere nuova password</Label>
                  <Input
                    type="password"
                    id="nuovaPw2"
                    style={!this.props.verificaPw2 ? style : null}
                    name="nuovaPw2"
                    value={this.props.nuovaPw2}
                    onChange={this.props.handleNuovaPw2Change}
                  />
                  {
                    !this.props.verificaPw2 ?
                      <FormText color="danger">Le due password nuove devono coincidere</FormText>
                      : null
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={() => this.props.conferma()}
          >
            OK
          </Button>
          {' '}
          <Button 
            color="warning" 
            onClick={() => this.props.toggle()}
          >
            Annulla
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

}