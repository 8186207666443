import React, { Component } from 'react';
import { HashRouter, Route, Routes as Switch } from 'react-router-dom';
import Calendar from './common/Calendar';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import './App.scss';
import layoutReducer from './store/reducers/layout';
import authReducer from './store/reducers/auth';
import thunk from 'redux-thunk';
import routes from './routes';
import { addAuthToken, apiconfig } from './shared/apiconfig';
import axios from 'axios';
import DetectChange from './services/detectChange';
import user from './views/Users/User';
import ModalSpinner from './common/ModalSpinner';
import * as pdfjs from 'pdfjs-dist';
const data = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';
const rootReducer = combineReducers({
    layout: layoutReducer,
    auth: authReducer
});
const store = createStore(rootReducer, applyMiddleware(thunk));
const loading = () => <div className="animated fadeIn pt-3 text-center">Caricamento in corso...</div>;
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: null,
            loadingData: true,
            canSee: { value: true, data: {} },
            notification: []
        };
        this.routerRef = React.createRef();
        this.getData = this.getData.bind(this);
    }
    getData() {
        let user = null;
        if (localStorage.user) user = JSON.parse(localStorage.user);
        if (user) {
            if (!user?.is_docente && (user.is_studente || user.is_admin || user.is_tutore)) {
                addAuthToken();
                axios
                    .get(apiconfig().baseURL + 'contaRichiesteStudente')
                    .then(res => {
                        this.setState({ notification: res?.data.totaleRichieste || 0 });
                        sessionStorage.setItem('notification', JSON.stringify(res?.data.totaleRichieste));
                    })
                    .catch(err => {
                        // if (err.response && err.response.status && err.response.status == 401) {
                        //     window.location.href = '#/logout';
                        // }
                        console.info('non puoi vederle, si trova');
                    });
            }
            if (user?.is_admin) {
                addAuthToken();
                axios
                    .get(apiconfig().baseURL + 'contaUtentiDaValidare')
                    .then(res => {
                        this.setState({ usersToValidate: res?.data.studentiDaAbilitare || 0 });
                        sessionStorage.setItem('userToValidate', JSON.stringify(res?.data));
                    })
                    .catch(err => {
                        if (err.response && err.response.status && err.response.status == 401) {
                            window.location.href = '#/logout';
                        }
                        console.info('non puoi vedere le valutazioni, si trova');
                    });
            }
            if (!user?.is_studente && (user.is_docente || user.is_admin)) {
                addAuthToken();
                axios
                    .get(apiconfig().baseURL + 'contaValutazioni')
                    .then(res => {
                        this.setState({
                            evaluationCount: res?.data.totaleMessaggi || 0,
                            messageAdmin: res?.data.totaleMessaggiValutazioni
                        });
                        sessionStorage.setItem('evaluationCount', JSON.stringify(res?.data));
                    })
                    .catch(err => {
                        if (err.response && err.response.status && err.response.status == 401) {
                            window.location.href = '#/logout';
                        }
                        console.info('non puoi vedere le valutazioni, si trova');
                    });
            }
        }
    }
    componentDidMount() {
        this.checkUserRegistered();
    }
    checkUserRegistered() {
        let user = null;
        if (localStorage.user) user = JSON.parse(localStorage.user);
        else this.setState({ loadingData: false });
        this.setState({ loadingData: true });
        if (!user?.is_admin) {
            addAuthToken();
            axios
                .get(apiconfig().baseURL + 'user')
                .then(response => {
                    if (!response.data?.abilitato) {
                        if (response.data.is_studente) {
                            addAuthToken();
                            axios.get(apiconfig().baseURL + 'wizard/getStepData?step=5').then(res => {
                                this.setState({
                                    loadingData: false,
                                    canSee: {
                                        value: false,
                                        data: {
                                            path: res.data.fileUploaded ? '/datistudente' : '/datistudente',
                                            file: res.data.fileUploaded
                                        }
                                    }
                                });
                            });
                        } else {
                            this.setState({
                                loadingData: false,
                                canSee: {
                                    value: false,
                                    data: {
                                        path: '/completaprofilo'
                                    }
                                }
                            });
                        }
                    } else {
                        this.setState({ loadingData: false });
                        localStorage.setItem('userProfile', JSON.stringify(response.data));
                        this.setState({ canSee: { value: true, data: { file: false } } });
                    }
                })
                .catch(error => {
                    if (error?.response?.status && error.response.status == 401) {
                        this.setState({ canSee: { value: true, data: {} }, loadingData: false });
                        window.location.href = '#/logout';
                    } else this.setState({ canSee: { value: false, data: { file: false } } });
                });
        } else {
            setTimeout(() => {
                this.setState({ loadingData: false });
            }, 0);
        }
    }
    render() {
        return (
            <Provider store={store}>
                <ModalSpinner attivo={this.state.loadingData} />
                <HashRouter>
                    <DetectChange
                        authCallBack={location => {
                            console.info('mi trovo in una opagina di auth');
                            this.setState({
                                loadingData: false,
                                canSee: { value: true, data: {} }
                            });
                        }}
                        logoutCallback={location => {
                            this.setState({
                                logout: location?.pathname === '/logout',
                                loadingData: location?.pathname !== '/logout',
                                canSee: { value: location?.pathname === '/logout', data: {} }
                            });
                        }}
                        callBack={location => {
                            this.getData();
                            this.setState({ logout: location?.pathname === '/logout' });
                            this.checkUserRegistered();
                        }}
                        isDocente
                    />
                    <React.Suspense fallback={loading()}>
                        <div style={{ display: 'none' }}>
                            <Calendar />
                        </div>
                        <Switch
                            location={
                                !this.state.canSee.value && !this.state.logout
                                    ? this.state.canSee?.data?.path || ''
                                    : ''
                            }
                        >
                            <Route
                                exact
                                path="/"
                                name="Login Page"
                                element={<Login setAdmin={data => this.setState({ admin: data })} />}
                            />
                            <Route
                                exact
                                path="/Login"
                                name="Login Page"
                                element={
                                    <Login setCanSee={() => {}} setAdmin={data => this.setState({ admin: data })} />
                                }
                            />
                            <Route exact path="/register" name="Register Page" element={<Register />} />
                            <Route exact path="/404" name="Page 404" element={<Page404 />} />
                            <Route exact path="/500" name="Page 500" element={<Page500 />} />
                            {!this.state.loadingData && (
                                <Route
                                    path="/"
                                    name="Home"
                                    element={
                                        <DefaultLayout
                                            getNotification={this.getData}
                                            canSee={this.state.canSee}
                                            notificationsData={this.state.notification}
                                            evaluationData={this.state.evaluation}
                                            notifications={this.state.notification || 0}
                                            evaluations={this.state.evaluationCount}
                                            messaggiAdmin={this.state.messageAdmin}
                                            usersToValidate={this.state.usersToValidate}
                                            superadmin={
                                                this.state.admin
                                                    ? 1
                                                    : this.state.admin !== null
                                                    ? 0
                                                    : localStorage.getItem('user') !== null
                                                    ? JSON.parse(localStorage.getItem('user')).superadmin
                                                    : 0
                                            }
                                            titolo="Calendario2"
                                        />
                                    }
                                >
                                    {routes(this.state.notification).map((route, idx) => {
                                        return route.component ? (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                element={<route.component />}
                                            />
                                        ) : null;
                                    })}
                                </Route>
                            )}
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </Provider>
        );
    }
}

export default App;
