import React from 'react';
import { connect } from 'react-redux';
import {
    Label,
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Button,
    Collapse,
    FormGroup,
    Input,
    InputGroupText,
    InputGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import RichiestaConferma from '../../common/RichiestaConferma';
import ModaleRinominaUtente from '../../common/ModaleRinominaUtente';
import Avviso from '../../common/Avviso';
import TooltipItem from '../../common/TooltipItem';
import axios from 'axios';
import { apiconfig, addAuthToken } from '../../shared/apiconfig';
import * as actions from '../../store/actions/'; // Redux
import { updateObject, manageNulls, tipiUtente, convertiDataDaDB } from '../../shared/utility';
import withRouter from '../../common/witthRouter';
import CIcon from '@coreui/icons-react';
import { cilArrowCircleBottom, cilArrowCircleTop, cilWarning } from '@coreui/icons';
import moment from 'moment/moment';
import { downloadPDF } from '../../common/services';
import ModalSpinner from '../../common/ModalSpinner';

class Utenti extends React.Component {
    constructor(props) {
        super(props);
        this.toggleAvviso = this.toggleAvviso.bind(this);
        this.toggleRichiestaConferma = this.toggleRichiestaConferma.bind(this);
        this.toggleModaleRinomina = this.toggleModaleRinomina.bind(this);
        this.state = {
            utenti: [],
            sedi: [],
            caricamentoSedi: false,
            materie: [],
            caricamentoMaterie: false,
            filtriRicerca: {
                disattivati: false,
                nome: '',
                tipo: null,
                sede: null,
                materia: null,
                sostegno: false
            },
            richiestaConferma: {
                attivo: false,
                titolo: '',
                messaggio: '',
                azione: null, // 1=disattiva, 2=elimina
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            richiestaConfermaTmp: {},
            modaleRinomina: {
                attivo: false,
                titolo: '',
                messaggio: '',
                cognome: '',
                nome: '',
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            spinner: false,
            avviso: {
                attivo: false,
                titolo: '',
                messaggio: '',
                codiceMessaggio: null // codifica i tipi di messaggio in caso di redirect automatico da login
            },
            idUtenteModificato: null,
            visDettagli: false
        };
        this.filtriRicercaHandler = this.filtriRicercaHandler.bind(this);
        this.changeNomeHandler = this.changeNomeHandler.bind(this);
        this.sortHandler = this.sortHandler.bind(this);
        this.changeCognomeHandler = this.changeCognomeHandler.bind(this);
        this.attivazioneDisattivazioneUtente = this.attivazioneDisattivazioneUtente.bind(this);
        this.handleChangeFiltroRicercaAnnoScolastico = this.handleChangeFiltroRicercaAnnoScolastico.bind(this);
    }

    componentDidMount() {
        this.props.setTitle('Lista utenti');
        addAuthToken();
        if (
            localStorage.getItem('redirPath') === this.props.location.pathname &&
            localStorage.getItem('redirState') !== ''
        ) {
            const statoSalvato = JSON.parse(localStorage.getItem('redirState'));
            this.setState(
                {
                    utenti: statoSalvato.utenti,
                    sedi: statoSalvato.sedi,
                    caricamentoSedi: statoSalvato.caricamentoSedi,
                    materie: statoSalvato.materie,
                    caricamentoMaterie: statoSalvato.caricamentoMaterie,
                    filtriRicerca: statoSalvato.filtriRicerca,
                    visDettagli: statoSalvato.visDettagli
                },
                () => {
                    this.aggiornaRicerca(statoSalvato);
                    addAuthToken();
                    this.caricaElencoMaterie();
                    this.caricaElencoSedi();
                }
            );
            localStorage.removeItem('redirPath');
            localStorage.removeItem('redirState');
        } else {
            addAuthToken();
            this.aggiornaRicerca();
            this.caricaElencoMaterie();
            this.caricaElencoSedi();
        }
    }
    toggleRichiestaConferma = risp => {
        const modRichiestaConferma = updateObject(this.state.richiestaConferma, {
            attivo: false,
            titolo: '',
            messaggio: '',
            conferma: risp === 1,
            codiceMessaggio: null
        });
        const modRichiestaConfermaTmp = updateObject(this.state.richiestaConferma, {});
        this.setState(
            {
                richiestaConferma: modRichiestaConferma,
                richiestaConfermaTmp: modRichiestaConfermaTmp
            },
            this.confermaAzione
        );
    };

    aggiornaRicerca(statoSalvato = null) {
        if (
            this.state.filtriRicerca.nome.length < 3 &&
            this.state.filtriRicerca.materia === null &&
            this.state.filtriRicerca.sede === null &&
            this.state.filtriRicerca.tipo === null &&
            !this.state.filtriRicerca.sostegno
        ) {
            this.setState({
                utenti: []
            });
            return;
        }
        let params = { params: manageNulls(this.state.filtriRicerca) };
        if (this.props.pazienti) params.tipo = '5';
        if (!this.state.filtriRicerca.disattivati) {
            params.params.disattivati = 0;
        } else {
            params.params.disattivati = 1;
        }
        if (!this.state.filtriRicerca.sostegno) {
            params.params.sostegno = 0;
        } else {
            params.params.sostegno = 1;
        }
        if (`${this.state.filtriRicerca.tipo}` === `11`) params.tipo = 10;
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'users', params)
            .then(response => {
                this.setState(
                    {
                        utenti: response.data,
                        superAdmin: `${this.state.filtriRicerca.tipo}` === `11`
                    },
                    () => {
                        if (statoSalvato !== null) {
                            const nuovoRichiestaConferma = updateObject(statoSalvato.richiestaConferma, {
                                messaggio:
                                    statoSalvato.richiestaConferma.codiceMessaggio === null
                                        ? statoSalvato.richiestaConferma.messaggio
                                        : this.elencoMessaggi(
                                              statoSalvato.richiestaConferma.codiceMessaggio,
                                              statoSalvato.idUtenteModificato
                                          )
                            });
                            const nuovoModaleRinomina = updateObject(statoSalvato.modaleRinomina, {
                                messaggio:
                                    statoSalvato.modaleRinomina.codiceMessaggio === null
                                        ? statoSalvato.modaleRinomina.messaggio
                                        : this.elencoMessaggi(
                                              statoSalvato.modaleRinomina.codiceMessaggio,
                                              statoSalvato.idUtenteModificato
                                          )
                            });
                            const nuovoAvviso = updateObject(statoSalvato.avviso, {
                                messaggio:
                                    statoSalvato.avviso.codiceMessaggio === null
                                        ? statoSalvato.avviso.messaggio
                                        : this.elencoMessaggi(
                                              statoSalvato.avviso.codiceMessaggio,
                                              statoSalvato.idUtenteModificato
                                          )
                            });
                            this.setState({
                                richiestaConferma: nuovoRichiestaConferma,
                                modaleRinomina: nuovoModaleRinomina,
                                avviso: nuovoAvviso,
                                idUtenteModificato: statoSalvato.idUtenteModificato
                            });
                        }
                    }
                );
            })
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(err, this.props.location.pathname, this.state);
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore,
                            codiceMessaggio: null
                        }
                    });
                }
            });
    }

    caricaElencoSedi() {
        let params = { params: { disattivata: 0 } };
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'sedi', params)
            .then(response => {
                this.setState({
                    sedi: response.data
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    caricaElencoMaterie() {
        let params = { params: { disattivata: 0 } };
        addAuthToken();
        axios
            .get(apiconfig().baseURL + 'materie', params)
            .then(response => {
                this.setState({
                    materie: response.data
                });
            })
            .catch(error => {
                console.error(error);
                if (error?.response?.status && error.response.status == 401) {
                    window.location.href = '#/logout';
                }
            });
    }
    elencoMessaggi(idMessaggio, idUtenteCorrente = null, utente) {
        console.error(idUtenteCorrente);
        const utenteCorrente =
            idUtenteCorrente !== null ? this.state.utenti.find(x => x.id === idUtenteCorrente) : null;
        switch (idMessaggio) {
            case 2:
                return (
                    <div>
                        Eliminare <span style={{ fontWeight: 'bold' }}>definitivamente</span> l'utente{' '}
                        {utenteCorrente.cognome} {utenteCorrente.nome}?
                    </div>
                );
            case 3:
                return (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Attenzione: la modifica riguarderà anche lo storico</span>
                    </div>
                );
            case 4:
                return (
                    <div>
                        {utente.abilitato ? 'Annullare' : 'Confermare'} la registrazione di {utente?.cognome}{' '}
                        {utente?.nome}?
                        <br />
                        {!utente.attivato ? (
                            <b>
                                {' '}
                                (Attenzione, l'utente potrà effettuare l'accesso solo per compilare il modulo d'
                                iscrizione)
                            </b>
                        ) : null}
                    </div>
                );
            default:
                return '';
        }
    }

    attivazioneDisattivazioneUtente = user => {
        const richiesta = {
            attivo: true,
            titolo: 'Richiesta conferma',
            messaggio: this.elencoMessaggi(4, null, user),
            codiceMessaggio: 4,
            conferma: false,
            azione: 4
        };
        this.setState({
            richiestaConferma: richiesta,
            selectedUser: user
        });
    };
    confarmaAttivazioneDisattivazioneUtente = (type, user) => {
        let newStato = user.abilitato ? 0 : 1;
        axios
            .patch(apiconfig().baseURL + 'studente/abilitaAccesso', { id_studente: user.id, abilitato: newStato })
            .then(res => {
                /*
            *   <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />*/
                if (!res.data.errors) {
                    this.setState(
                        {
                            avviso: {
                                attivo: true,
                                titolo: 'Successo',
                                messaggio: 'Utente ' + (newStato ? 'Abilitato' : 'Disabilitato') + ' con successo'
                            }
                        },
                        this.aggiornaRicerca
                    );
                } else
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'Errore',
                            messaggio: 'Errore imprevisto riprovare piu tardi'
                        }
                    });
            })
            .catch(err => {
                if (err.response.status && err.response.status == 401) {
                    this.props.navigate('/logout');
                } else
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'Errore',
                            messaggio: 'Errore imprevisto riprovare piu tardi'
                        }
                    });
            });
    };
    confermaAzione = () => {
        if (this.state.richiestaConferma.conferma) {
            switch (this.state.richiestaConferma.azione) {
                case 1:
                    this.confermaDisattivaUtente();
                    break;
                case 2:
                    this.confermaEliminaUtente();
                    break;
                case 4:
                    this.confarmaAttivazioneDisattivazioneUtente(this.state.disableType, this.state.selectedUser);
                    break;
                default:
                    return;
            }
        }
    };

    toggleAvviso = () => {
        const modAvviso = {
            attivo: false,
            titolo: '',
            messaggio: '',
            codiceMessaggio: null
        };
        this.setState({
            avviso: modAvviso
        });
    };

    toggleModaleRinomina = () => {
        const modModaleRinomina = {
            attivo: false,
            titolo: '',
            messaggio: '',
            codiceMessaggio: null
        };
        this.setState({
            modaleRinomina: modModaleRinomina
        });
    };

    changeNomeHandler(arg) {
        const modModaleRinomina = updateObject(this.state.modaleRinomina, {
            nome: arg.target.value
        });
        this.setState({
            modaleRinomina: modModaleRinomina
        });
    }

    changeCognomeHandler(arg) {
        const modModaleRinomina = updateObject(this.state.modaleRinomina, {
            cognome: arg.target.value
        });
        this.setState({
            modaleRinomina: modModaleRinomina
        });
    }
    sortHandler(utenti) {
        let users = JSON.parse(JSON.stringify(utenti));
        if (this.state.sortActive) {
            return users
                .sort((a, b) =>
                    (this.state.sortDesc ? 1 : -1) * parseInt(a.anno_scolastico || '0') >
                    parseInt(b.anno_scolastico || '0')
                        ? -1
                        : 1
                )
                .sort(a => (a.anno_scolastico ? -1 : 1));
        } else return utenti;
    }

    confermaRinomina = () => {
        const nuovoNome = this.state.modaleRinomina.nome;
        const nuovoCognome = this.state.modaleRinomina.cognome;
        if (nuovoNome === null || nuovoNome === '' || nuovoCognome === null || nuovoCognome === '') {
            this.setState({
                avviso: {
                    attivo: true,
                    titolo: 'ATTENZIONE - Operazione non effettuata',
                    messaggio: 'Inserire nome e cognome',
                    codiceMessaggio: null
                }
            });
        } else {
            axios
                .patch(apiconfig().baseURL + 'users/' + this.state.idUtenteModificato, {
                    nome: nuovoNome,
                    cognome: nuovoCognome
                })
                .then(response => {
                    this.aggiornaRicerca();
                    this.toggleModaleRinomina();
                })
                .catch(err => {
                    console.error(err);
                    if (err.response.status && err.response.status == 401) {
                        this.props.onInvalidToken(err, this.props.location.pathname, this.state);
                        this.props.navigate('/logout');
                    } else {
                        this.setState({
                            avviso: {
                                attivo: true,
                                titolo: 'ATTENZIONE - Rilevato un errore',
                                messaggio: err.errore,
                                codiceMessaggio: null
                            }
                        });
                    }
                });
        }
    };

    eliminaUtente(id) {
        const richiesta = {
            attivo: true,
            titolo: 'Richiesta conferma',
            messaggio: this.elencoMessaggi(2, id),
            codiceMessaggio: 2,
            conferma: false,
            azione: 2
        };
        this.setState({
            richiestaConferma: richiesta,
            idUtenteModificato: id
        });
    }

    confermaEliminaUtente() {
        axios
            .delete(apiconfig().baseURL + 'users/' + this.state.idUtenteModificato)
            .then(response => {
                this.aggiornaRicerca();
            })
            .catch(err => {
                console.error(err);
                if (err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(
                        err,
                        this.props.location.pathname,
                        updateObject(this.state, { richiestaConferma: this.state.richiestaConfermaTmp })
                    );
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore,
                            codiceMessaggio: null
                        }
                    });
                }
            });
    }

    disattivaUtente(id) {
        const utenteCorrente = this.state.utenti.find(x => x.id === id);
        if (!utenteCorrente.disattivato) {
            const richiesta = {
                attivo: true,
                titolo: 'Richiesta conferma',
                messaggio: (
                    <div>
                        {"Disattivare l'utente " + utenteCorrente.cognome + ' ' + utenteCorrente.nome + '?'}
                        <br />
                        <b>
                            (Attenzione, l'utente non potra effettuare l'accesso fino a riattivazione manuale, non
                            verranno eliminati i dati precedentemente inseriti)
                        </b>
                    </div>
                ),
                codiceMessaggio: null,
                conferma: false,
                azione: 1
            };
            this.setState({
                richiestaConferma: richiesta,
                idUtenteModificato: id
            });
        } else {
            this.setState(
                {
                    idUtenteModificato: id
                },
                this.confermaDisattivaUtente
            );
        }
    }

    confermaDisattivaUtente() {
        const utenteCorrente = this.state.utenti.find(x => x.id === this.state.idUtenteModificato);
        axios
            .patch(apiconfig().baseURL + 'users/' + this.state.idUtenteModificato, {
                disattivato: utenteCorrente.disattivato ? 0 : 1
            })
            .then(response => {
                this.aggiornaRicerca();
            })
            .catch(err => {
                console.error(err);
                if (err.response.status && err.response.status == 401) {
                    this.props.onInvalidToken(
                        err,
                        this.props.location.pathname,
                        updateObject(this.state, { richiestaConferma: this.state.richiestaConfermaTmp })
                    );
                    this.props.navigate('/logout');
                } else {
                    this.setState({
                        avviso: {
                            attivo: true,
                            titolo: 'ATTENZIONE - Rilevato un errore',
                            messaggio: err.errore,
                            codiceMessaggio: null
                        }
                    });
                }
            });
    }

    toggleUtente(id) {
        const newState = this.state.utenti;
        const currentVal = newState.find(e => e.id === id).collapse;
        newState.find(e => e.id === id).collapse = !currentVal;
        this.setState({
            utenti: newState
        });
    }

    filtriRicercaHandler(nuovoFiltro) {
        this.setState(
            {
                filtriRicerca: nuovoFiltro
            },
            this.aggiornaRicerca
        );
    }

    handleChangeFiltroRicercaDisattivati(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            disattivati: arg.target.checked
        });
        this.filtriRicercaHandler(filtri);
    }

    handleChangeFiltroRicercaSostegno(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            sostegno: arg.target.checked
        });
        this.filtriRicercaHandler(filtri);
    }

    handleChangeFiltroRicercaNome(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            nome: arg.target.value
        });
        this.filtriRicercaHandler(filtri);
    }
    handleChangeFiltroRicercaAnnoScolastico(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            anno_scolastico: arg.target.value
        });
        this.filtriRicercaHandler(filtri);
    }
    handleChangeFiltroRicercaSede(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            sede: arg.target.value
        });
        this.filtriRicercaHandler(filtri);
    }

    handleChangeFiltroRicercaMateria(arg) {
        const filtri = updateObject(this.state.filtriRicerca, {
            materia: arg.target.value
        });
        this.filtriRicercaHandler(filtri);
    }

    handleChangeFiltroRicercaTipo(arg) {
        if (arg.target.value !== '2') {
            this.setState({ daAbilitare: false });
        }
        const filtri = updateObject(this.state.filtriRicerca, {
            tipo: arg.target.value
        });
        this.filtriRicercaHandler(filtri);
    }

    handleChangeMostraDettagli(arg) {
        this.setState({
            visDettagli: arg.target.checked
        });
    }

    render() {
        if (this.props.pazienti && !this.state.filtriRicerca.tipo) {
            this.setState({ filtriRicerca: { ...this.state.filtriRicerca, tipo: 5 } });
        }
        if (localStorage.user && !JSON.parse(localStorage.user).is_admin) this.props.navigate('/logout');
        return (
            <div>
                <Avviso
                    attivo={this.state.avviso.attivo}
                    toggle={this.toggleAvviso}
                    titolo={this.state.avviso.titolo}
                    messaggio={this.state.avviso.messaggio}
                />
                <RichiestaConferma
                    attivo={this.state.richiestaConferma.attivo}
                    toggle={this.toggleRichiestaConferma}
                    titolo={this.state.richiestaConferma.titolo}
                    messaggio={this.state.richiestaConferma.messaggio}
                />
                <ModaleRinominaUtente
                    attivo={this.state.modaleRinomina.attivo}
                    toggle={this.toggleModaleRinomina}
                    titolo={this.state.modaleRinomina.titolo}
                    messaggio={this.state.modaleRinomina.messaggio}
                    conferma={this.confermaRinomina}
                    nome={this.state.modaleRinomina.nome}
                    cognome={this.state.modaleRinomina.cognome}
                    changeNomeHandler={this.changeNomeHandler}
                    changeCognomeHandler={this.changeCognomeHandler}
                />
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        className={'width--38'}
                        style={{
                            maxHeight: window.innerHeight - 90,
                            overflow: 'scroll',
                            borderRadius: 16,
                            padding: 0,
                            margin: ' 0 14px'
                        }}
                    >
                        <Card style={{ marginBottom: 12 }}>
                            <CardHeader>
                                <h3>Filtri di ricerca utenti</h3>
                                <Row>
                                    {!this.props.pazienti && (
                                        <Col xs="12" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="ricercaTipo">Tipo</Label>
                                                <InputGroup className="mb-3">
                                                    <Input
                                                        type="select"
                                                        name="selectTipo"
                                                        id="selectTipo"
                                                        value={
                                                            this.state.filtriRicerca.tipo !== null &&
                                                            !this.state.filtriRicerca.sostegno
                                                                ? this.state.filtriRicerca.tipo
                                                                : ''
                                                        }
                                                        onChange={this.handleChangeFiltroRicercaTipo.bind(this)}
                                                        disabled={this.state.filtriRicerca.sostegno}
                                                    >
                                                        <option value="0">(Tutti)</option>
                                                        {tipiUtente.map((el, index) => {
                                                            return el !== '' ? (
                                                                <option key={index} value={index}>
                                                                    {el}
                                                                </option>
                                                            ) : null;
                                                        })}
                                                        <option key={'super admin'} onClick={() => {}} value={11}>
                                                            Amministratore
                                                        </option>
                                                        ;
                                                    </Input>
                                                    {this.state.filtriRicerca.tipo + '' === '2' && (
                                                        <InputGroupText
                                                            onClick={() =>
                                                                this.setState({
                                                                    sortActive: true,
                                                                    sortDesc: !this.state.sortDesc
                                                                })
                                                            }
                                                            className={
                                                                this.state.sortActive
                                                                    ? 'input-text-button-active'
                                                                    : 'input-text-button'
                                                            }
                                                        >
                                                            Ordina per Anno scolastico
                                                            <div
                                                                style={{
                                                                    visibility: !this.state.sortActive && 'hidden'
                                                                }}
                                                            >
                                                                &nbsp;
                                                                {this.state.sortDesc ? (
                                                                    <CIcon icon={cilArrowCircleBottom}></CIcon>
                                                                ) : (
                                                                    <CIcon icon={cilArrowCircleTop}></CIcon>
                                                                )}
                                                            </div>
                                                        </InputGroupText>
                                                    )}
                                                    {this.state.filtriRicerca.tipo + '' === '2' && (
                                                        <InputGroupText
                                                            onClick={() =>
                                                                this.setState({
                                                                    sortActive: false,
                                                                    sortDesc: false
                                                                })
                                                            }
                                                            className={
                                                                this.state.sortActive
                                                                    ? 'input-cross-button-active'
                                                                    : 'input-cross-button'
                                                            }
                                                        >
                                                            X
                                                        </InputGroupText>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup check className="checkbox mb-3">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="checkSostegno"
                                                    name="checkSostegno"
                                                    checked={this.state.filtriRicerca.sostegno}
                                                    onChange={this.handleChangeFiltroRicercaSostegno.bind(this)}
                                                />
                                                <Label check className="form-check-label" htmlFor="checkSostegno">
                                                    Mostra solo studenti con sostegno
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col xs="12" sm="6" md="6">
                                        <FormGroup>
                                            <Label htmlFor="ricercaSede">Sede</Label>
                                            <Input
                                                type="select"
                                                name="selectSede"
                                                id="selectSede"
                                                value={
                                                    this.state.filtriRicerca.sede !== null
                                                        ? this.state.filtriRicerca.sede
                                                        : ''
                                                }
                                                onChange={this.handleChangeFiltroRicercaSede.bind(this)}
                                            >
                                                <option value="0">(Tutte)</option>
                                                {this.state.sedi.map(el => {
                                                    return (
                                                        <option key={el.id} value={el.id}>
                                                            {el.nome}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {!this.props.pazienti && (
                                        <Col xs="12" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="ricercaMateria">Materia</Label>
                                                <Input
                                                    type="select"
                                                    name="selectMateria"
                                                    id="selectMateria"
                                                    value={
                                                        this.state.filtriRicerca.materia !== null
                                                            ? this.state.filtriRicerca.materia
                                                            : ''
                                                    }
                                                    onChange={this.handleChangeFiltroRicercaMateria.bind(this)}
                                                >
                                                    <option value="0">(Tutte)</option>
                                                    {this.state.materie.map(el => {
                                                        return (
                                                            <option key={el.id} value={el.id}>
                                                                {el.materia}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    )}
                                    <Col xs="12" sm="6" md="6">
                                        <FormGroup>
                                            <Label htmlFor="ricercaNome">Nome</Label>
                                            <Input
                                                type="text"
                                                name="ricercaNome"
                                                id="ricercaNome"
                                                value={this.state.filtriRicerca.nome}
                                                onChange={this.handleChangeFiltroRicercaNome.bind(this)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6" md="6">
                                        <FormGroup check className="checkbox">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkDisattivati"
                                                name="checkDisattivati"
                                                checked={this.state.filtriRicerca.disattivati}
                                                onChange={this.handleChangeFiltroRicercaDisattivati.bind(this)}
                                            />
                                            <Label check className="form-check-label" htmlFor="checkDisattivati">
                                                Mostra disattivati
                                            </Label>
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" sm="6" md="6">
                                        <FormGroup check className="checkbox">
                                            <Input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkMostraDettagli"
                                                name="checkMostraDettagli"
                                                checked={this.state.visDettagli}
                                                onChange={this.handleChangeMostraDettagli.bind(this)}
                                            />
                                            <Label check className="form-check-label" htmlFor="checkMostraDettagli">
                                                Mostra dettagli
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    {this.state.filtriRicerca.tipo + '' === '2' && (
                                        <Col xs="12" sm="12" md="12">
                                            <FormGroup>
                                                <Label htmlFor="ricercaAnno">Anno Scolastico</Label>
                                                <Input
                                                    type="select"
                                                    name="ricercaAnno"
                                                    id="ricercaAnno"
                                                    value={this.state.filtriRicerca.anno_scolastico || '0'}
                                                    onChange={this.handleChangeFiltroRicercaAnnoScolastico}
                                                >
                                                    <option value={'0'}> </option>
                                                    {new Array(7).fill(0).map((e, i) => (
                                                        <option
                                                            value={
                                                                moment()
                                                                    .add(-(i - 1) - 1, 'years')
                                                                    .format('YYYY') +
                                                                '/' +
                                                                moment()
                                                                    .add(-(i - 1), 'years')
                                                                    .format('YYYY')
                                                            }
                                                        >
                                                            {moment()
                                                                .add(-(i - 1) - 1, 'years')
                                                                .format('YYYY') +
                                                                '/' +
                                                                moment()
                                                                    .add(-(i - 1), 'years')
                                                                    .format('YYYY')}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="mb-2">
                                    <Link to={this.props.pazienti ? '/datipaziente/0' : '/datiutente/0'}>
                                        <Button color="primary">
                                            {this.props.pazienti ? 'Nuovo paziente' : 'Nuovo utente'}
                                        </Button>
                                    </Link>
                                    {!this.props.pazienti && (
                                        <Button
                                            className={'ml-2'}
                                            onClick={() => {
                                                if (!this.state.daAbilitare) {
                                                    this.setState({ daAbilitare: true });
                                                    this.handleChangeFiltroRicercaTipo({ target: { value: '2' } });
                                                } else {
                                                    this.setState({ daAbilitare: false });
                                                    this.handleChangeFiltroRicercaTipo({ target: { value: '0' } });
                                                }
                                            }}
                                            color="primary"
                                        >
                                            {this.state.daAbilitare
                                                ? 'Visualizza tutto'
                                                : 'Visualizza studenti da abilitare'}
                                        </Button>
                                    )}
                                </div>
                                <div id="listaUtenti" data-children=".item">
                                    {this.sortHandler(
                                        this.state.utenti
                                            .filter(
                                                el =>
                                                    this.state.filtriRicerca.tipo + '' !== '2' ||
                                                    (this.state.daAbilitare ? el.abilitato === 0 : true)
                                            )
                                            .filter(e => (this.state.superAdmin ? 1 : 0) === e.superadmin)
                                    )
                                        // .sort((a, b) => (a.anno_scolastico ? -1 : 1))
                                        .map((utente, i) => {
                                            return (
                                                <Card
                                                    className="mb-2"
                                                    style={{ borderWidth: 0, overflow: 'hidden' }}
                                                    key={utente.id}
                                                >
                                                    <CardHeader id={'heading' + utente.id} style={{ borderWidth: 0 }}>
                                                        <div className={'d-flex'}>
                                                            <Link
                                                                target={'_blank'}
                                                                rel="nofollow"
                                                                to={
                                                                    (this.props.pazienti
                                                                        ? '/datipaziente/'
                                                                        : '/datiutente/') + utente.id
                                                                }
                                                            >
                                                                <Button
                                                                    block
                                                                    color="link"
                                                                    className="text-left m-0 p-0"
                                                                    aria-expanded={this.state.visDettagli}
                                                                    aria-controls={'utente' + utente.id}
                                                                >
                                                                    <h6 className="m-0 p-0">
                                                                        {utente.cognome} {utente.nome}
                                                                        {utente.is_docente ? (
                                                                            <Badge color="warning" className={'ml-2'}>
                                                                                DOCENTE
                                                                            </Badge>
                                                                        ) : null}
                                                                        {utente.is_psicologo ? (
                                                                            <Badge color="warning" className={'ml-2'}>
                                                                                PSICOLOGO
                                                                            </Badge>
                                                                        ) : null}
                                                                        {utente.is_studente ? (
                                                                            <Badge color="warning" className={'ml-2'}>
                                                                                STUDENTE
                                                                            </Badge>
                                                                        ) : null}
                                                                        {utente.is_paziente ? (
                                                                            <Badge color="secondary" className={'ml-2'}>
                                                                                PAZIENTE
                                                                            </Badge>
                                                                        ) : null}
                                                                        {utente.is_admin + '' === '1' ? (
                                                                            utente.superadmin ? (
                                                                                <Badge
                                                                                    color="success"
                                                                                    className={'ml-2'}
                                                                                >
                                                                                    SUPER AMMINISTRATORE
                                                                                </Badge>
                                                                            ) : (
                                                                                <Badge
                                                                                    className={
                                                                                        'ml-2 success-dlg-button'
                                                                                    }
                                                                                    style={{
                                                                                        backgroundColor: '#29ab29'
                                                                                    }}
                                                                                    color={'#29ab29'}
                                                                                >
                                                                                    RESPONSABILE
                                                                                </Badge>
                                                                            )
                                                                        ) : null}
                                                                        {utente.anno_scolastico ? (
                                                                            <Badge
                                                                                color="primary"
                                                                                style={{ marginLeft: 12 }}
                                                                            >
                                                                                {utente.anno_scolastico}
                                                                            </Badge>
                                                                        ) : null}
                                                                    </h6>
                                                                </Button>
                                                            </Link>
                                                            {utente.is_studente &&
                                                            utente.moduloIscrizione &&
                                                            !utente.abilitato ? (
                                                                <>
                                                                    <Badge
                                                                        color={'warning'}
                                                                        id={'btnModulo' + utente.id}
                                                                        onClick={ev => {
                                                                            ev.stopPropagation();
                                                                            window.open(
                                                                                './#/datistudente/' + utente.id,
                                                                                '_blank'
                                                                            );
                                                                        }}
                                                                        style={{ marginTop: -2, cursor: 'pointer' }}
                                                                        className={'ml-2 mb-1'}
                                                                    >
                                                                        <CIcon icon={cilWarning} />
                                                                    </Badge>
                                                                    <TooltipItem
                                                                        placement="top"
                                                                        objectId={'btnModulo' + utente.id}
                                                                        content={
                                                                            'Bisogna controllare il modulo di iscrizione'
                                                                        }
                                                                    />
                                                                </>
                                                            ) : null}
                                                            {utente.studente_sostegno ? (
                                                                <Badge color="warning" className={'ml-3'}>
                                                                    Sostegno
                                                                </Badge>
                                                            ) : null}
                                                        </div>
                                                        {utente.disattivato ? (
                                                            <Badge color="warning">Disattivato</Badge>
                                                        ) : null}
                                                        {utente.email} - Tel. {utente.telefono} {utente.cellulare}
                                                        <span className="ml-5 float-right">
                                                            {utente.is_studente ? (
                                                                <>
                                                                    {utente.moduloIscrizione ? (
                                                                        <Button
                                                                            size="sm"
                                                                            id={'btnEnable' + utente.id}
                                                                            style={{ marginRight: 5, color: 'white' }}
                                                                            onClick={() => {
                                                                                this.setState({ spinner: true });
                                                                                axios
                                                                                    .get(
                                                                                        apiconfig().baseURL +
                                                                                            'studente/scaricaModuloIscrizione?id_studente=' +
                                                                                            utente.id,
                                                                                        {
                                                                                            responseType: 'blob'
                                                                                        }
                                                                                    )
                                                                                    .then(res => {
                                                                                        if (
                                                                                            res.data.type ===
                                                                                            'application/pdf'
                                                                                        )
                                                                                            downloadPDF(
                                                                                                res.data,
                                                                                                'Modulo_Compilato.pdf'
                                                                                            );
                                                                                        else
                                                                                            alert(
                                                                                                'Non ha il modulo di iscrizione'
                                                                                            );
                                                                                    })
                                                                                    .finally(() => {
                                                                                        this.setState({
                                                                                            spinner: false
                                                                                        });
                                                                                    });
                                                                            }}
                                                                        >
                                                                            Modulo
                                                                        </Button>
                                                                    ) : null}
                                                                    <Button
                                                                        className={
                                                                            utente.abilitato
                                                                                ? 'error-dlg-button'
                                                                                : 'success-dlg-button'
                                                                        }
                                                                        size="sm"
                                                                        id={'btnEnable' + utente.id}
                                                                        onClick={() => {
                                                                            if (utente.abilitato)
                                                                                this.attivazioneDisattivazioneUtente(
                                                                                    utente
                                                                                );
                                                                            else
                                                                                window.open(
                                                                                    './#/datistudente/' + utente.id,
                                                                                    '_blank'
                                                                                );
                                                                        }}
                                                                    >
                                                                        {utente.abilitato ? 'Disabilita' : 'Abilita'}
                                                                    </Button>
                                                                    <TooltipItem
                                                                        placement="top"
                                                                        objectId={'btnEnable' + utente.id}
                                                                        content={
                                                                            utente.abilitato
                                                                                ? 'Annulla la registrazione di ' +
                                                                                  utente.cognome +
                                                                                  ' ' +
                                                                                  utente.nome
                                                                                : 'Visualizza il modulo di registrazione di ' +
                                                                                  utente.cognome +
                                                                                  ' ' +
                                                                                  utente.nome
                                                                        }
                                                                    />{' '}
                                                                </>
                                                            ) : null}
                                                            <Button
                                                                color={!utente.disattivato ? 'warning' : 'success'}
                                                                size="sm"
                                                                id={'btnDisattiva' + utente.id}
                                                                onClick={() => this.disattivaUtente(utente.id)}
                                                            >
                                                                <i className="fa fa-lightbulb-o"></i>
                                                            </Button>
                                                            <TooltipItem
                                                                placement="top"
                                                                objectId={'btnDisattiva' + utente.id}
                                                                content={
                                                                    !utente.disattivato
                                                                        ? 'Disattiva'
                                                                        : 'Riattiva' +
                                                                          utente.cognome +
                                                                          ' ' +
                                                                          utente.nome
                                                                }
                                                            />{' '}
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                id={'btnElimina' + utente.id}
                                                                onClick={() => this.eliminaUtente(utente.id)}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                            <TooltipItem
                                                                placement="top"
                                                                objectId={'btnElimina' + utente.id}
                                                                content={
                                                                    'Elimina ' + utente.cognome + ' ' + utente.nome
                                                                }
                                                            />{' '}
                                                        </span>
                                                    </CardHeader>
                                                    <Collapse
                                                        isOpen={this.state.visDettagli}
                                                        data-parent="#accordion"
                                                        id="collapseOne"
                                                        aria-labelledby={'heading' + utente.id}
                                                    >
                                                        <CardBody>
                                                            <Row>
                                                                <Col xs="12" sm="6" md="6">
                                                                    {utente.is_admin ? (
                                                                        <div>
                                                                            <Label style={{ fontWeight: 'bold' }}>
                                                                                AMMINISTRATORE
                                                                            </Label>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.sedi.filter(el => {
                                                                        return el.pivot.tipo == 10;
                                                                    }).length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Sedi abilitate:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.sedi.map(x => {
                                                                                        return x.pivot.tipo == 10 ? (
                                                                                            <li key={x.id}>
                                                                                                {x.nome}
                                                                                                {x.orario
                                                                                                    ? ' - Orario: ' +
                                                                                                      x.orario
                                                                                                    : ''}
                                                                                            </li>
                                                                                        ) : null;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.is_docente ? (
                                                                        <div>
                                                                            <Label style={{ fontWeight: 'bold' }}>
                                                                                DOCENTE
                                                                            </Label>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.sedi.filter(el => {
                                                                        return el.pivot.tipo == 1;
                                                                    }).length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Sedi abilitate:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.sedi.map(x => {
                                                                                        return x.pivot.tipo == 1 ? (
                                                                                            <li key={x.id}>
                                                                                                {x.nome}
                                                                                                {x.orario
                                                                                                    ? ' - Orario: ' +
                                                                                                      x.orario
                                                                                                    : ''}
                                                                                            </li>
                                                                                        ) : null;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.materie.filter(el => {
                                                                        return el.pivot.tipo == 1;
                                                                    }).length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Materie abilitate:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.materie.map(x => {
                                                                                        return x.pivot.tipo == 1 ? (
                                                                                            <li key={x.id}>
                                                                                                {x.materia}
                                                                                            </li>
                                                                                        ) : null;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.is_studente ? (
                                                                        <div>
                                                                            <Label style={{ fontWeight: 'bold' }}>
                                                                                STUDENTE
                                                                            </Label>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.sedi.filter(el => {
                                                                        return el.pivot.tipo == 2;
                                                                    }).length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Sedi abilitate:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.sedi.map(x => {
                                                                                        return x.pivot.tipo == 2 ? (
                                                                                            <li key={x.id}>
                                                                                                {x.nome}
                                                                                                {x.orario
                                                                                                    ? ' - Orario: ' +
                                                                                                      x.orario
                                                                                                    : ''}
                                                                                            </li>
                                                                                        ) : null;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.materie.filter(el => {
                                                                        return el.pivot.tipo == 2;
                                                                    }).length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Materie abilitate:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.materie.map(x => {
                                                                                        return x.pivot.tipo == 2 ? (
                                                                                            <li key={x.id}>
                                                                                                {x.materia}
                                                                                            </li>
                                                                                        ) : null;
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.tutori.length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Genitori:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.tutori.map(x => {
                                                                                        return (
                                                                                            <li key={x.id}>
                                                                                                {x.cognome +
                                                                                                    ' ' +
                                                                                                    x.nome}
                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.is_tutore ? (
                                                                        <div>
                                                                            <Label style={{ fontWeight: 'bold' }}>
                                                                                Insegnante di sostegno
                                                                            </Label>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.is_genitore ? (
                                                                        <div>
                                                                            <Label style={{ fontWeight: 'bold' }}>
                                                                                GENITORE
                                                                            </Label>
                                                                        </div>
                                                                    ) : null}
                                                                    {utente.figli.length > 0 ? (
                                                                        <div>
                                                                            <div>
                                                                                <Label style={{ fontWeight: 'bold' }}>
                                                                                    - Figli:
                                                                                </Label>
                                                                            </div>
                                                                            <div>
                                                                                <ul>
                                                                                    {utente.figli.map(x => {
                                                                                        return (
                                                                                            <li key={x.id}>
                                                                                                {x.cognome +
                                                                                                    ' ' +
                                                                                                    x.nome}
                                                                                            </li>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </Col>
                                                                <Col xs="12" sm="6" md="6">
                                                                    <div>
                                                                        <Label style={{ fontWeight: 'bold' }}>
                                                                            Dati anagrafici
                                                                        </Label>
                                                                    </div>
                                                                    <div>
                                                                        <Label>- Data e luogo di nascita: </Label>{' '}
                                                                        {convertiDataDaDB(utente.data_nascita)},{' '}
                                                                        {utente.comune_nascita}
                                                                        <br />
                                                                        <Label>- Residenza: </Label> {utente.indirizzo}{' '}
                                                                        {utente.comune} ({utente.prov}) <br />
                                                                        <Label>- Codice fiscale: </Label>{' '}
                                                                        {utente.codice_fiscale}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div></div>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                            );
                                        })}
                                </div>
                                {this.state.utenti.length === 0
                                    ? 'La ricerca non ha prodotto nessun risultato o non sono stati impostati filtri di ricerca.'
                                    : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ModalSpinner attivo={this.state.spinner} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tokenAttivo: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: titolo => dispatch(actions.setPageTitle(titolo)),
        onInvalidToken: (errore, path, state) => dispatch(actions.gestisciErrore(errore, path, state))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Utenti));
