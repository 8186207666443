import React, { Component } from 'react';
import {Pagination} from 'reactstrap';
import {PaginationItem} from 'reactstrap';
import {PaginationLink} from 'reactstrap';

class Paginazione extends Component {
  constructor(props) {
    super(props);
    this.state={
      indiciPagine: []
    };
  }


  cambiaPagina = (e, nuova) => {
    e.preventDefault();
    const paginaCorrente = this.props.paginaRicerca;
    const totPagine = Math.ceil(this.props.totaleItems / this.props.itemsPerPagina);
    if (nuova == -1) {
      nuova = totPagine;
    }
    if (nuova < 1) {
      nuova = 1;
    }
    if (nuova > totPagine) {
      nuova = totPagine;
    }
    if (nuova != paginaCorrente) {
      this.props.suCambiaPagina(nuova);
    }
  }

  render() {
    return (
      <Pagination>
        <PaginationItem>
          <PaginationLink first onClick={e => this.cambiaPagina(e, 1)} href='#' />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink previous onClick={e => this.cambiaPagina(e, this.props.paginaRicerca - 1)} href='#' />
        </PaginationItem>
        {[1].reduce((ris, el) => {
          const totPagine = Math.ceil(this.props.totaleItems / this.props.itemsPerPagina);
          let primo, ultimo;
          if (this.props.paginaRicerca < 5) {
            primo = 1;
            ultimo = (totPagine > 5 ? 5 : totPagine);
          } else {
            primo = this.props.paginaRicerca - 2 -
              (totPagine >= this.props.paginaRicerca + 2 ? 0 :
                2 - (totPagine - this.props.paginaRicerca));
            ultimo = primo + 4;
          }
          for (let j = primo; j <= ultimo; j++) {
            ris.push(j);
          }
          return ris;
        }, []).map(el => {
          return (
            <PaginationItem key={el} active={this.props.paginaRicerca == el}>
              <PaginationLink onClick={e => this.cambiaPagina(e, el)} href='#' >
                {el}
              </PaginationLink>
            </PaginationItem>
          )
        })}
        <PaginationItem>
          <PaginationLink next onClick={e => this.cambiaPagina(e, this.props.paginaRicerca + 1)} href='#' />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink last onClick={e => this.cambiaPagina(e, -1)} href='#' />
        </PaginationItem>
      </Pagination>);
  }
}

export default Paginazione;
